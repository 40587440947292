import React from "react"

import { Column } from "../../layout/FlexBox"
import { TextInput, TextInputProps } from "./TextInput"

type TextAreaInputProps = TextInputProps & { height?: number }

export function TextAreaInput(props: TextAreaInputProps) {
  return (
    <Column elevation={6}>
      <TextInput
        {...props}
        multiline
        style={[{ height: props.height ?? 300 }, props.style]}
        containerStyle={[{ padding: 12 }, props.containerStyle]}
      />
    </Column>
  )
}
