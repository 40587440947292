import { KeyLabels } from "../container/Menu"

export function useDisplayValue({
  values,
  category,
  displayKeyValuePairs,
}: {
  category: string
  values: (keyof KeyLabels)[]
  displayKeyValuePairs: KeyLabels
}) {
  let value

  if (values.length > 1) value = `${values.length} ${category}`
  else if (values.length == 1) value = displayKeyValuePairs[values[0]] ?? ""
  else value = ""

  return value
}
