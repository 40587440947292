import React, { ReactNode } from "react"

import { AlternativePressableOpacity } from "../../button/AlternativePressableOpacity"
import { Icon } from "../../display/Icon"
import { ToolTipIcon } from "../../display/ToolTipIcon"
import { Box } from "../../layout/Box"
import { Row } from "../../layout/FlexBox"
import { Label } from "../../typography/Label"
import { IconConfig } from "../field/TextInput"

type ToolTipIconConfig = IconConfig & { tooltip: string }

type InputRowProps = {
  text?: string
  disabled?: boolean
  children?: ReactNode
  icon?: IconConfig | ToolTipIconConfig
  onPress?: () => void
}

export function InputRow(props: InputRowProps) {
  const { icon, text, disabled, children, onPress } = props

  const content = (
    <Row
      alignCenter
      spaceBetween
      gap="XXXS"
      padLeft="XS"
      padVertical="XXXS"
      disabled={disabled}
    >
      {text && <Label bold text={text} />}
      {children}
      {icon && (
        <Box pad="XXXXS">
          {"tooltip" in icon && icon.tooltip ? (
            <ToolTipIcon
              icon={icon.name}
              text={icon.tooltip}
              color={icon.color ?? "default"}
            />
          ) : (
            <Icon name={icon.name} color={icon.color} />
          )}
        </Box>
      )}
    </Row>
  )

  return onPress ? (
    <AlternativePressableOpacity disabled={disabled} onPress={onPress}>
      {content}
    </AlternativePressableOpacity>
  ) : (
    content
  )
}
