import { ReceiptOutput } from "~shared/api/graphql"
import { PrinterId } from "~shared/types"

// Evaluates the output type of the receipt through the used printerId.
export function determineOutputType(printerId: PrinterId): ReceiptOutput {
  let outputType: ReceiptOutput

  if (printerId == "email") outputType = "Email"
  else if (printerId.length != 0) outputType = "Paper"
  else outputType = "NoOutput"

  return outputType
}
