import React, { ReactNode, useCallback } from "react"
import { StyleProp, StyleSheet, ViewStyle } from "react-native"

import { useSelection } from "~shared/components/providers/SelectionProvider"

import { Button } from "../../button/Button"
import { Overlay } from "../../container/Overlay"
import { Icon } from "../../display/Icon"
import { MCIcon } from "../../display/MCIcon"
import { Box } from "../../layout/Box"
import { Row } from "../../layout/FlexBox"
import { Gap } from "../../layout/Gap"
import { Spacer } from "../../layout/Spacer"
import { useTheme } from "../../theme"
import {
  ResponsiveSpacing,
  SharedDialogAction,
  SharedDialogConfig,
  ShowComponentDialog,
  ShowConfirmCancelDialog,
  ShowConfirmDialog,
  UseSharedDialog,
} from "../../types"
import { Label } from "../../typography/Label"
import { useKassenAppUI } from "../KassenAppUIProvider"
import {
  InternalDialogContextProvider,
  useInternalDialogContext,
} from "./InternalDialogContextProvider"

type DialogAction = SharedDialogAction & { icon?: MCIcon; disabled?: boolean }

type UseDialog = UseSharedDialog & {
  // Shows a dialog with a custom component implementation.
  showDialog: ShowComponentDialog
}

// A Dialog can have a title, content and one or two actions.
// The content can be a text message or any ReactNode.
type DialogProps = Pick<SharedDialogConfig, "title" | "subTitle"> & {
  // There always must be a primary action,
  // otherwise the dialog would not make much sense.
  primaryAction: DialogAction

  // The message / content of the dialog.
  children: string | ReactNode

  // Custom style applied to the container of the dialog.
  style?: StyleProp<ViewStyle>

  // Gap applied before / above the action button row.
  actionGap?: ResponsiveSpacing

  // An optional secondary action of the dialog.
  secondaryAction?: DialogAction

  // Handler that is executed,
  // when the close icon of the dialog is pressed.
  onCloseIconPress?: SharedDialogAction["onPress"]
}

export { DialogProvider, Dialog, useDialog, DIALOG_ELEVATION, dialogStyles }

function DialogProvider({ children }: { children: ReactNode }) {
  return (
    <InternalDialogContextProvider>
      <DialogOverlay />
      {children}
    </InternalDialogContextProvider>
  )
}

function useDialog(): UseDialog {
  const { translations } = useKassenAppUI()
  const { showDialog, closeTopDialog } = useInternalDialogContext()

  const showConfirmDialog: ShowConfirmDialog = useCallback(
    ({ title, subTitle, message, label }) =>
      new Promise<boolean>(resolve => {
        const onPress = () => resolve(true)

        const onCloseIconPress = () => {
          onPress()
          closeTopDialog()
        }

        showDialog(
          <Dialog
            subTitle={subTitle}
            title={title ?? translations.note}
            primaryAction={{ label: label ?? translations.understood, onPress }}
            onCloseIconPress={onCloseIconPress}
          >
            {message}
          </Dialog>,
        )
      }),
    [closeTopDialog, showDialog, translations.note, translations.understood],
  )

  const showConfirmCancelDialog: ShowConfirmCancelDialog = useCallback(
    ({ title, subTitle, message, cancelLabel, confirmLabel }) =>
      new Promise<boolean>(resolve => {
        const onPressPrimary = () => resolve(true)
        const onPressSecondary = () => resolve(false)

        const onCloseIconPress = () => {
          onPressSecondary()
          closeTopDialog()
        }

        showDialog(
          <Dialog
            subTitle={subTitle}
            title={title ?? translations.note}
            secondaryAction={{
              label: cancelLabel ?? translations.cancel,
              onPress: onPressSecondary,
            }}
            primaryAction={{
              label: confirmLabel ?? translations.confirm,
              onPress: onPressPrimary,
            }}
            onCloseIconPress={onCloseIconPress}
          >
            {message}
          </Dialog>,
        )
      }),
    [
      closeTopDialog,
      showDialog,
      translations.cancel,
      translations.confirm,
      translations.note,
    ],
  )

  return {
    showDialog,
    showConfirmDialog,
    showConfirmCancelDialog,
    closeTopDialog,
  }
}

function DialogOverlay() {
  const { selectionInProgress } = useSelection()
  const { activeDialogs } = useInternalDialogContext()

  const activeDialogsLength = activeDialogs.length

  if (activeDialogsLength <= 0) return null

  const dialogOverlays = activeDialogs.map((dialog, index) => {
    const isNotLastElement = index != activeDialogsLength - 1
    const display = isNotLastElement || selectionInProgress ? "none" : undefined

    return (
      <Overlay
        centerContent
        zIndex={OVERLAY_Z_INDEX}
        noBackdrop={isNotLastElement}
        key={`activeDialog_${index}`}
      >
        <Box pad="XS" style={[dialogStyles.overlayContainer, { display }]}>
          {dialog}
        </Box>
      </Overlay>
    )
  })

  return <>{dialogOverlays}</>
}

function Dialog({
  style,
  children,
  actionGap,
  primaryAction,
  secondaryAction,
  ...props
}: DialogProps) {
  const { color } = useTheme()
  const { closeTopDialog } = useInternalDialogContext()

  const onActionPress = async (action: DialogAction) => {
    const result = await action.onPress?.()
    if (result != false) closeTopDialog()
  }

  const onCloseIconPress = async () => {
    if (!props.onCloseIconPress) closeTopDialog()
    else await props.onCloseIconPress()
  }

  const closeIcon = (
    <Row style={dialogStyles.closeIconContainer}>
      <Icon name="close" color="base3" onPress={onCloseIconPress} />
    </Row>
  )

  const title = props.title ? <Label h4 text={props.title} /> : null

  const subTitle =
    typeof props.subTitle == "string" ? (
      <Label small padTop="S" text={props.subTitle} />
    ) : props.subTitle ? (
      <>
        <Gap vertical="S" />
        {props.subTitle}
      </>
    ) : null

  const gapAboveMessage = <Gap vertical="S" />

  const message =
    typeof children == "string" ? <Label text={children} /> : children || null

  const gapAboveButtons = <Gap vertical={actionGap ?? "XL"} />

  const primaryButton = (
    <Button
      icon={primaryAction.icon}
      text={primaryAction.label}
      disabled={primaryAction.disabled}
      onPress={() => onActionPress(primaryAction)}
    />
  )

  const secondaryButton = secondaryAction && (
    <Button
      outlined
      icon={secondaryAction.icon}
      text={secondaryAction.label}
      disabled={secondaryAction.disabled}
      onPress={() => onActionPress(secondaryAction)}
    />
  )

  const buttons = (
    <Row gap="XS">
      <Spacer />
      {secondaryButton}
      {primaryButton}
    </Row>
  )

  return (
    <Box
      pad="S"
      padTop="SM"
      borderRadius="L"
      borderColor={color.primary}
      elevation={DIALOG_ELEVATION}
      backgroundColor={color.background}
      style={[dialogStyles.mainContainer, style]}
    >
      {closeIcon}
      {title}
      {subTitle}
      {gapAboveMessage}
      {message}
      {gapAboveButtons}
      {buttons}
    </Box>
  )
}

const OVERLAY_Z_INDEX = 100
const DIALOG_MAX_WIDTH = 800

const DIALOG_ELEVATION = 20

const dialogStyles = StyleSheet.create({
  mainContainer: { width: "100%", maxWidth: DIALOG_MAX_WIDTH },
  closeIconContainer: { position: "absolute", right: 16, top: 16 },
  overlayContainer: { alignItems: "center", justifyContent: "center" },
})
