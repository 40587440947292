import { isEqual } from "lodash"
import { useState } from "react"

import { useTransitionEffect } from "@axtesys/hooks"
import { createCustomContext } from "@axtesys/react-tools"

import { KassenAppUITranslations } from "../types"

export const [KassenAppUIProvider, useKassenAppUI] = createCustomContext(
  ({
    translations: inputTranslations,
  }: {
    translations: KassenAppUITranslations
  }) => {
    const [translations, setTranslations] = useState(inputTranslations)

    useTransitionEffect(
      inputTranslations,
      (nextTranslations, lastTranslations) => {
        if (isEqual(nextTranslations, lastTranslations)) return
        setTranslations(nextTranslations)
      },
    )

    return { translations }
  },
)
