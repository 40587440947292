import { useRecoilValue } from "recoil"

import { backendState, BackendType, baseUrls } from "./backend"
import { frontendState, FrontendType } from "./frontend"

export function useFrontend(): FrontendType {
  return useRecoilValue(frontendState) as FrontendType
}

export function useIsAppFrontend(): boolean {
  return useFrontend() == "app"
}

export function useBackend(): BackendType {
  return useRecoilValue(backendState) as BackendType
}

// Hook to get the full backend url
// from an installation-independent path.
export function useBackendUrl(path?: string, subdomain?: string): string {
  const [protocol, hostname] = baseUrls[useBackend()].split("://")

  return subdomain
    ? `${protocol}://${subdomain}.${hostname}${path ?? ""}`
    : `${protocol}://${hostname}${path ?? ""}`
}

export function useRksvBaseUrl(): string {
  return useBackendUrl("", "rksv")
}
