import React from "react"

import { Icon } from "../display/Icon"
import { Row } from "../layout/FlexBox"
import { Label } from "../typography/Label"
import { ButtonProps } from "./Button"

type FlatButtonProps = Pick<ButtonProps, "icon" | "text" | "fluid" | "onPress">

export function FlatButton({ fluid, onPress, ...props }: FlatButtonProps) {
  const icon = props.icon && <Icon name={props.icon} />
  const text = props.text && <Label text={props.text} />

  return (
    <Row
      alignCenter
      gap="XXXS"
      padRight="XXS"
      padLeft={!icon ? "XXS" : undefined}
      style={{
        minHeight: 48,
        justifyContent: "center",
        width: fluid ? "100%" : undefined,
      }}
      onPress={onPress}
    >
      {icon}
      {text}
    </Row>
  )
}
