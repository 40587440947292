import { isEqual } from "lodash"
import React, { useState } from "react"
import { LayoutChangeEvent } from "react-native"

import { useOnOff } from "@axtesys/hooks"

import { Box } from "../layout/Box"
import { useTheme } from "../theme"
import { Label } from "../typography/Label"
import { Button, ButtonProps } from "./Button"

const MAX_WIDTH = 175
const INIT_SPACING = { height: 0, width: 0 }

type ToolTipButtonProps = ButtonProps & {
  tooltip: string
  hideToolTip?: boolean
}

export function TooltipButton(props: ToolTipButtonProps) {
  const { color } = useTheme()
  const [isHovered, onHoverIn, onHoverOut] = useOnOff(false)
  const [hoverSpacing, setHoverSpacing] = useState(INIT_SPACING)

  const showToolTip = !(props.hideToolTip ?? false)
  const onLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    // 1.35 is totally a magic number.
    // However, it is best suited for this component.
    setHoverSpacing({
      width: nativeEvent.layout.width + 5,
      height: nativeEvent.layout.height / 1.35,
    })
  }

  const hoverText = showToolTip && (
    <Box
      pad="XXXS"
      borderColor={color.border}
      backgroundColor={color.background}
      onLayout={onLayout}
    >
      <Label small text={props.tooltip} />
    </Box>
  )

  return (
    <Box>
      <Button {...props} onHoverIn={onHoverIn} onHoverOut={onHoverOut} />

      {isHovered && (
        <Box
          style={{
            width: MAX_WIDTH,
            position: "absolute",
            opacity: !isEqual(hoverSpacing, INIT_SPACING) ? 1 : 0,
          }}
        >
          <Box
            style={{
              maxWidth: MAX_WIDTH,
              position: "absolute",
              left: -hoverSpacing.width,
              bottom: -hoverSpacing.height,
            }}
          >
            {hoverText}
          </Box>
        </Box>
      )}
    </Box>
  )
}
