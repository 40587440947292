import { DependencyList, useCallback, useEffect, useRef, useState } from "react"
import { AppState, AppStateStatus, Platform } from "react-native"

import { useSubsequentEffect } from "./useSubsequentEffect"
import { useTransitionEffect } from "./useTransitionEffect"

// Executes an effect every couple of milliseconds.
export function useIntervalEffect(
  // Time value in milliseconds [ms] stating how often
  // the interval effect should be called.
  duration: number,

  // The effect that should be executed,
  // each time the duration has elapsed.
  effect: () => void,

  // Restart the interval effect procedure
  // each time the reInitDependencyList changes after the first mount.
  reInitDependencyList?: DependencyList,

  // Restart the interval effect procedure AND execute the effect
  // each time the reInitEffectDependencyList changes after the first mount.
  reInitEffectDependencyList?: DependencyList,
) {
  const intervalRef = useRef<NodeJS.Timeout | undefined>()
  const [appState, setAppState] = useState<AppStateStatus>("active")

  // Re-inits the interval effect,
  // that will be executed after X passed milliseconds elapsed.
  const initIntervalEffect = useCallback(
    (executeEffect?: boolean) => {
      if (executeEffect) effect()

      // Clear the previous/ongoing interval effect, if there is any.
      if (intervalRef.current) clearInterval(intervalRef.current)

      // Restart the interval.
      intervalRef.current = setInterval(effect, duration)
    },
    [effect, duration],
  )

  useEffect(() => {
    initIntervalEffect(true)

    // Required for the AppState transition logic mounted bellow
    // (but only for mobile/native devices).
    const subscription =
      Platform.OS != "web"
        ? AppState.addEventListener("change", setAppState)
        : undefined

    // Clean-up the interval and AppState subscription when the hook unmounts.
    return () => {
      clearInterval(intervalRef.current)
      if (!subscription) return
      subscription.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Restart the interval effect procedure
  // each time the app gets active again.
  useTransitionEffect(appState, (currentState, lastState) => {
    if (!(currentState == "active" && lastState != "active")) return

    initIntervalEffect(true)
  })

  // Restart the interval effect procedure
  // each time the dependencyList changes after the first mount.
  useSubsequentEffect(() => initIntervalEffect(), reInitDependencyList ?? [])

  // Restart the interval effect procedure and execute the effect
  // each time the effectDependencyList changes after the first mount.
  useSubsequentEffect(
    () => initIntervalEffect(true),
    reInitEffectDependencyList ?? [],
  )
}
