import Big from "big.js"
import * as Device from "expo-device"
import { countBy } from "lodash"
import {
  ComponentProps,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { KeyboardTypeOptions, Platform, TextInput } from "react-native"

import { isEmptyOrBlank } from "@axtesys/react-tools"

import { areBigEquals, clamp } from "~shared/lib/Big"

import { useKeyboardEffect } from "../effect/useKeyboardEffect"

// This hook should be used in connection with TextInput components.
// It sanitizes arbitrary text input to make sure that it conforms to
// some configurable number encoding.
// While editing, the checks are less strict, so the user can type freely,
// but once editing ends, the full constraints are applied to the text.
export type UseNumberInputProps<T extends Big | number> = {
  // Number value to be displayed
  // in the TextInput component (controlled component).
  // While the user is actively editing the text,
  // this value is ignored.
  value?: T

  // The minimum allowed value the actual value prop can take.
  minValue?: T

  // The maximum allowed value the actual value prop can take.
  maxValue?: T

  // Shortcut for setting
  // minNumberOfDecimalPlaces and maxNumberOfDecimalPlaces to 0.
  isInteger?: boolean

  // Ensures that the value entered is always positive.
  // The user cannot add a '-' sign.
  forcePositive?: boolean

  // Ensures that the value entered is always negative.
  // The user cannot remove the leading '-' sign.
  forceNegative?: boolean

  // Number of decimal places that should be presented at minimum.
  // Defaults to zero (overwritten by isInteger).
  minNumberOfDecimalPlaces?: number

  // Number of decimal places that should be presented at maximum.
  // Defaults to three (overwritten by isInteger).
  maxNumberOfDecimalPlaces?: number

  // Possibility to overwrite standard keyboard type
  // if exceptional cases need to be handled.
  keyboardType?: KeyboardTypeOptions

  // Defines what happens when the user cancels the editing
  // (e.g. pressing somewhere outside the input element,
  //       or tapping the back button).
  // - ignore: onSubmit will not be called,
  //           but the last value entered into the InputField persists (default).
  // - revert: The value that was set before editing started is restored.
  // - submit: The last value entered is passed to onSubmit,
  //           as if the user pressed the submit button.
  onCancelBehavior?: "ignore" | "revert" | "submit"

  // Callback with the current number value,
  // executed every time the user types.
  onChange?(value?: T): void

  // Callback executed once the user finishes editing
  // and the TextInput loses focus.
  onSubmit?(value?: T): void
} & ({ value?: number } | { value?: Big; isBig: true } | { value: Big })

export type UseNumberInputResult = Pick<
  ComponentProps<typeof TextInput>,
  "value"
> & {
  textInputRef: Ref<TextInput>
  keyboardType: KeyboardTypeOptions

  onBlur(): void
  onFocus(): void
  onSubmitEditing(): void
  onChange: (text: string) => void
}

export function useNumberInput<T extends Big | number>(
  props: UseNumberInputProps<T>,
): UseNumberInputResult {
  const {
    value,
    isInteger,
    onChange,
    onSubmit,
    forceNegative,
    forcePositive,
    onCancelBehavior,
  } = props

  const minValue = props.minValue != undefined ? Big(props.minValue) : undefined
  const maxValue = props.maxValue != undefined ? Big(props.maxValue) : undefined

  const minNumberOfDecimalPlaces = isInteger
    ? 0
    : props.minNumberOfDecimalPlaces ?? 0
  const maxNumberOfDecimalPlaces = isInteger
    ? 0
    : props.maxNumberOfDecimalPlaces ?? 3

  const isKeyboardInteger =
    isInteger ||
    (minNumberOfDecimalPlaces <= 0 && maxNumberOfDecimalPlaces <= 0)
  const isKeyboardPositive =
    (forcePositive == true || minValue?.gte(0) == true) && forceNegative != true
  const keyboardType = useNumericKeyboardLayout(
    isKeyboardInteger,
    isKeyboardPositive,
  )
  const textInputRef = useRef<TextInput | null>(null)

  // We might be using this hook with 'number' or 'Big' as type.
  // Internally, we will be using Big,
  // so we might have to convert inputs and outputs.
  const usingBigType = useMemo(() => {
    if ("isBig" in props) return true
    return typeof value == "object"
  }, [props, value])

  const convertInput = useCallback((input: T | undefined): Big | undefined => {
    if (input == null) return undefined
    return Big(input)
  }, [])

  const convertOutput = useCallback(
    (output: Big | undefined): T | undefined =>
      usingBigType ? (output as T) : (output?.toNumber() as T),
    [usingBigType],
  )

  // Makes sure that numbers conform
  // to the rules declared in sanitizeTextAsDecimal.
  const formatNumber = useCallback(
    (number?: T, isEditMode?: boolean): string => {
      const { sanitizedText } = sanitizeTextAsDecimal({
        minValue,
        maxValue,
        forceNegative,
        forcePositive,
        minNumberOfDecimalPlaces,
        maxNumberOfDecimalPlaces,
        isEditMode: isEditMode == true,
        text:
          number != undefined
            ? Big(number).round(maxNumberOfDecimalPlaces).toString()
            : "",
      })
      return sanitizedText
    },
    [
      minValue,
      maxValue,
      forceNegative,
      forcePositive,
      minNumberOfDecimalPlaces,
      maxNumberOfDecimalPlaces,
    ],
  )

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [textValue, setTextValue] = useState<string>(() => {
    setEditMode(false)
    return formatNumber(value)
  })

  // Required in case of 'revert' functionality
  // This value will be initially set and
  // later changed by onStartEditing
  // (own initial value for each editing iteration).
  const [initialNumberValue, setInitialNumberValue] = useState<Big | undefined>(
    convertInput(value),
  )

  // Required for last passed value tracking.
  const internalNumberValueRef = useRef<Big | undefined>(initialNumberValue)

  // I am not 100% why we need this here,
  // because updating the value of the TextInput
  // should be captured by onEdit (originally onChangeText).
  // But in a strange kind of way,
  // onChangeText is not being triggered when being used
  // (e.g. in AmountInput).
  useEffect(() => {
    // If we are in editMode (or the previous is the same as the new value),
    // we do not want to execute following logic
    if (isEditMode || areBigEquals(internalNumberValueRef.current, value))
      return

    setTextValue(formatNumber(value))
    internalNumberValueRef.current = convertInput(value)
  }, [convertInput, formatNumber, isEditMode, value])

  // Called each time an InputField received focus.
  const onStartEditing = () => {
    setEditMode(true)
    setInitialNumberValue(convertInput(value))
  }

  // This callback is executed on every keypress, be more permissive here.
  const onEdit = useCallback(
    (text: string) => {
      setEditMode(true)

      const { sanitizedText, parsedNumber } = sanitizeTextAsDecimal({
        text,
        minValue,
        maxValue,
        forceNegative,
        forcePositive,
        isEditMode: true,
        maxNumberOfDecimalPlaces,
        minNumberOfDecimalPlaces: 0,
      })

      internalNumberValueRef.current = parsedNumber
      setTextValue(sanitizedText)
      onChange?.(convertOutput(parsedNumber))
    },
    [
      minValue,
      maxValue,
      forceNegative,
      forcePositive,
      maxNumberOfDecimalPlaces,
      onChange,
      convertOutput,
    ],
  )

  // This callback is called when editing finished, be more restrictive here.
  const onEditFinished = useCallback(
    (submit: boolean) => {
      setEditMode(false)

      const { sanitizedText, parsedNumber } = sanitizeTextAsDecimal({
        minValue,
        maxValue,
        forceNegative,
        forcePositive,
        isEditMode: false,
        text: textValue ?? "",
        minNumberOfDecimalPlaces,
        maxNumberOfDecimalPlaces,
      })

      internalNumberValueRef.current = parsedNumber
      const convertedOutput = convertOutput(parsedNumber)
      setTextValue(sanitizedText)
      onChange?.(convertedOutput)

      if (submit) onSubmit?.(convertedOutput)
    },
    [
      minValue,
      maxValue,
      textValue,
      forceNegative,
      forcePositive,
      minNumberOfDecimalPlaces,
      maxNumberOfDecimalPlaces,
      onChange,
      onSubmit,
      convertOutput,
    ],
  )

  // This callback is called when editing is canceled,
  // by pressing outside the input component
  // or tapping the back button.
  const onEditCanceled = useCallback(() => {
    // If we are not in editMode do not execute the following logic.
    if (!isEditMode) return

    setEditMode(false)
    switch (onCancelBehavior) {
      case "revert":
        // Reset values to their initial state
        // (before last onStartEditing call).
        setTextValue(formatNumber(initialNumberValue as T))
        onChange?.(convertOutput(initialNumberValue))
        break
      case "submit":
        onEditFinished(true)
        break
      default:
        // In case of "ignore", we only ignore submission
        // The rest of onEditFinished will be applied
        // (number formatting according to passed configuration).
        onEditFinished(false)
    }
  }, [
    isEditMode,
    onCancelBehavior,
    formatNumber,
    onChange,
    convertOutput,
    initialNumberValue,
    onEditFinished,
  ])

  // Automatically cancel editing when the software keyboard hides.
  useKeyboardEffect("keyboardDidHide", onEditCanceled)

  return {
    textInputRef,
    keyboardType,
    value: textValue,
    onChange: onEdit,
    onBlur: onEditCanceled,
    onFocus: onStartEditing,
    onSubmitEditing: () => onEditFinished(true),
  }
}

// Performance note:
// Measurements in debug mode: always between 0ms and 1ms.
// So basically, nothing to worry about regarding this matter.
//
// Given a text string, this ensures that the text can be understood as
// a decimal number. Invalid characters or additional decimal places get
// stripped from the output.
// The text should be between the minimum and maximum number of decimal places,
// but tries to remove trailing zeros to keep it as short as possible.
// Returns a sanitized text and parsed number.
function sanitizeTextAsDecimal({
  text,
  minValue,
  maxValue,
  isEditMode,
  forceNegative,
  forcePositive,
  minNumberOfDecimalPlaces,
  maxNumberOfDecimalPlaces,
}: {
  text: string
  isEditMode: boolean
  minNumberOfDecimalPlaces: number
  maxNumberOfDecimalPlaces: number

  minValue?: Big
  maxValue?: Big
  forceNegative?: boolean
  forcePositive?: boolean
}): {
  sanitizedText: string
  parsedNumber?: Big
} {
  let sanitizedText = text

  // In case that we are in editMode, and we receive an empty value,
  // do not sanitize and return an empty string with an undefined parsedNumber.
  if (isEmptyOrBlank(sanitizedText))
    return { sanitizedText, parsedNumber: undefined }

  // Start of sanitizing process

  // Before removing all non-numeric characters
  // (check if the value should be negative).
  const isShouldBeNegative =
    forceNegative || (!forcePositive && sanitizedText.startsWith("-"))

  // Remove all non-numeric characters (except ',' and '.').
  sanitizedText = sanitizedText.replaceAll(/[^\d.,]/g, "")

  // Minus handling:
  // 1. In case of a negativity classification: Add a minus sign.
  // 2. Otherwise, we will force the value to be
  //    positive and no minus sign will be re-added.
  if (isShouldBeNegative) sanitizedText = `-${sanitizedText}`

  // As the application is mainly used
  // in German-speaking countries, we will use ',' as our delimiter.
  if (sanitizedText.includes("."))
    sanitizedText = sanitizedText.replaceAll(".", ",")

  // If there is already a comma entered do not add a second one.
  const numberOfCommas = countBy(sanitizedText)[","] || 0
  if (numberOfCommas > 1) {
    const index = sanitizedText.indexOf(",")
    sanitizedText = sanitizedText.replaceAll(",", "")
    sanitizedText = `${sanitizedText.slice(0, index)},${sanitizedText.slice(
      index,
      sanitizedText.length,
    )}`
  }

  // Split the integer and decimal sections in two parts.
  let numberParts = sanitizedText.split(",")
  let integerPart = numberParts[0] ?? ""
  let decimalPart = numberParts[1] ?? ""
  const includesComma = numberOfCommas != 0

  // If the integer site is empty,
  // but a comma was entered,
  // fill the integer site with '0' for convenience reasons.
  if (includesComma && isEmptyOrBlank(integerPart)) {
    integerPart = "0"
    sanitizedText = `${integerPart},`
    // Or if the integer site consist out of a minus
    // and a comma was added, transform the sanitizedText to "-0,".
  } else if (includesComma && integerPart == "-") {
    integerPart = "-0"
    sanitizedText = `${integerPart},`
  }

  // Prevent the decimalPart from overflowing the maxNumberOfDecimalPlaces.
  if (decimalPart.length > maxNumberOfDecimalPlaces)
    decimalPart = decimalPart.substring(0, maxNumberOfDecimalPlaces)

  // If we receive a floating point value, format it accordingly.
  if (!isEmptyOrBlank(decimalPart))
    sanitizedText = `${integerPart},${decimalPart}`

  // As we have already set the display string,
  // we can pad zeros to the minNumberOfDecimalPlaces
  // to create a parseable value.
  decimalPart = decimalPart.padEnd(minNumberOfDecimalPlaces, "0")

  // It could come to the situation that only a minus sign is entered OR
  // the input is still invalid and therefore empty.
  // This would lead to an error while parsing this value.
  // Therefore, check and if necessary fix the integer site.
  const parsableNumericValue = `${
    integerPart == "-" || isEmptyOrBlank(integerPart) ? "0" : integerPart
  }.${decimalPart}`
  const tempParsedNumber = Big(parsableNumericValue)

  // Make sure that we are always within the set min- and maxValue range.
  const parsedNumber = clamp(tempParsedNumber, minValue, maxValue)

  // If the clamped number is not the same as the entered value,
  // we parse / display the clamped value.
  if (!parsedNumber.eq(tempParsedNumber)) {
    numberParts = parsedNumber.toString().split(".")
    integerPart = numberParts[0] ?? ""
    decimalPart = (numberParts[1] ?? "")
      .padEnd(minNumberOfDecimalPlaces, "0")
      .substring(0, maxNumberOfDecimalPlaces)

    // Do not show a comma in the preview sanitizedText,
    // if the decimalPart is empty.
    const optionalDecimal = decimalPart.length > 0 ? "," : ""

    sanitizedText = `${integerPart}${optionalDecimal}${decimalPart}`
  }

  // We are more permissive in edit mode,
  // so only make sure that we got a valid number.
  if (isEditMode) return { sanitizedText, parsedNumber }

  // If only a '-' sign was entered,
  // replace the integer part with a zero value
  // (necessary so that we can parse it in the next step).
  if (integerPart == "-") integerPart = "-0"

  // Two possibilities:
  // 1. If the value of the integerPart is zero,
  //    we allow a single zero digit to be present.
  // 2. If the integerPart value is not equal to zero,
  //    we remove all leading zeros.
  if (Big(integerPart).eq(0)) {
    integerPart = isShouldBeNegative ? "-0" : "0"
  } else {
    integerPart =
      (isShouldBeNegative ? "-" : "") +
      integerPart.replace("-", "").replace(/^0+/, "")
  }

  // It is required to set the text here once again,
  // because we allow empty decimalParts while in editMode.
  // Display only the integer part if:
  // 1. The decimal section is empty OR
  // 2. If the value can be an integer and the decimal value is equal to zero.
  sanitizedText =
    isEmptyOrBlank(decimalPart) ||
    (minNumberOfDecimalPlaces == 0 && Big(decimalPart).eq(0))
      ? integerPart
      : `${integerPart},${decimalPart}`

  // If the sanitizedText only consists out of a negative zero,
  // transform it do a positive zero value.
  if (sanitizedText == "-0") sanitizedText = "0"

  return { sanitizedText, parsedNumber }
}

// Add (Android) manufacturers which got problems
// with the following keyboard layouts:
// 'decimal-pad', 'number-pad' or 'numeric' (not showing/working minus sign)
const DEVICE_MANUFACTURERS_WITH_DEFAULT_KEYBOARD = ["Samsung"]

function useNumericKeyboardLayout(
  isInteger: boolean,
  isPositive: boolean,
): KeyboardTypeOptions {
  return useMemo(() => {
    if (Platform.OS == "ios") {
      // If the device is an iPad use the standard 'keyboardType' -> "numbers-and-punctuation",
      // otherwise use 'number-pad' or 'decimal-pad' as the keyboard layout.
      // NOTE: Custom 'keyboardType' handling for iPads is implemented,
      //       as no 'number-pad' or 'decimal-pad' exists for them.
      //       Handling could be simplified to just use 'number-pad' or 'decimal-pad' in general,
      //       as iPads default to the 'numbers-and-punctuations' layout then.
      //       But as it is uncertain if this will stay the same in future iOS (iPadOS) releases,
      //       the 'keyboardType' is explicitly set.
      if (isPositive && !Platform.isPad)
        return isInteger ? "number-pad" : "decimal-pad"
      else return "numbers-and-punctuation"
    }

    const isManufacturerExcluded =
      DEVICE_MANUFACTURERS_WITH_DEFAULT_KEYBOARD.some(device =>
        (Device.manufacturer ?? "")
          .toLowerCase()
          .includes(device.toLowerCase()),
      )

    if (isManufacturerExcluded) return "default"

    if (Platform.OS == "web" || Platform.OS == "android") return "decimal-pad"
    return "default"
  }, [isInteger, isPositive])
}
