import { fromUnixTime } from "date-fns"
import { useCallback } from "react"
import { useRecoilCallback } from "recoil"

import { secondsToDate } from "@axtesys/react-tools"
import * as Sentry from "@sentry/minimal"
import { useQueryClient } from "@tanstack/react-query"

import {
  AuthenticatedUserFragment,
  UserRole,
} from "../../api/graphql/GeneratedOperations"
import { useSnackbar } from "../../components/providers/SnackbarProvider"
import { useGeneralTranslation } from "../Translation/hooks"
import { MIN_REMAINING_JWT_TTL } from "./constants"
import { decodeJwt, transformAuthenticatedUser } from "./lib"
import { useIsLoggedIn } from "./queries"
import { authenticatedUserState, userLoggedInState } from "./state"
import { JWT } from "./types"

export function useVerifyRefreshToken(userRole?: UserRole) {
  return useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const authenticatedUser = snapshot
          .getLoadable(authenticatedUserState)
          .getValue()

        if (!authenticatedUser) return false

        return (
          (userRole ? authenticatedUser.userRole == userRole : true) &&
          // In case the remaining time to live of the
          // refresh token is lower than MIN_REMAINING_JWT_TTL,
          // consider it no longer valid.
          secondsToDate(authenticatedUser.refreshExpiresAt) >=
            MIN_REMAINING_JWT_TTL
        )
      },
    [],
  )
}

function useRetrieveRefreshToken() {
  return useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const authenticatedUser = snapshot
          .getLoadable(authenticatedUserState)
          .getValue()

        if (!authenticatedUser) return undefined

        const refreshExpiresIn = secondsToDate(
          authenticatedUser.refreshExpiresAt,
        )

        // In case the remaining time to live of the
        // refresh token is lower than MIN_REMAINING_JWT_TTL,
        // do not use it and force the user to re-authenticate.
        if (refreshExpiresIn < MIN_REMAINING_JWT_TTL) return undefined

        return authenticatedUser.refreshToken
      },
    [],
  )
}

export function useRetrieveBearerToken() {
  const retrieveRefreshToken = useRetrieveRefreshToken()

  return useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const authenticatedUser = snapshot
          .getLoadable(authenticatedUserState)
          .getValue()

        if (!authenticatedUser) return undefined

        const accessExpiresIn = secondsToDate(authenticatedUser.accessExpiresAt)

        // In case the remaining time to live of the
        // access token is greater than MIN_REFRESH_TTL,
        // use it for authentication towards the server.
        // Otherwise, use the stored refresh token
        // to get a new valid accessToken.
        if (
          accessExpiresIn > MIN_REMAINING_JWT_TTL &&
          authenticatedUser.accessToken
        )
          return authenticatedUser.accessToken

        return retrieveRefreshToken()
      },
    [retrieveRefreshToken],
  )
}

export function useRefreshAccessToken() {
  return useRecoilCallback(
    ({ set }) =>
      (accessToken: JWT) => {
        const accessDecoded = decodeJwt(accessToken)
        const accessExpiresAt = fromUnixTime(accessDecoded.exp).toISOString()

        set(authenticatedUserState, state => {
          if (!state) return state
          return { ...state, accessToken, accessExpiresAt }
        })
      },
    [],
  )
}

export function useLogin() {
  const queryClient = useQueryClient()

  return useRecoilCallback(
    ({ set }) =>
      (user: AuthenticatedUserFragment) => {
        const authentication = transformAuthenticatedUser(user)
        const { userId: id, firstName, lastName, companyName } = authentication

        set(authenticatedUserState, authentication)
        queryClient.clear()
        Sentry.setUser({
          id,
          username: `${firstName} ${lastName} (${companyName})`,
        })
      },
    [queryClient],
  )
}

export function useLogoutSilently() {
  const queryClient = useQueryClient()
  const isNotLoggedIn = !useIsLoggedIn()

  return useRecoilCallback(
    ({ set }) =>
      () => {
        if (isNotLoggedIn) return

        set(userLoggedInState, false)
        set(authenticatedUserState, undefined)

        queryClient.clear()
        Sentry.setUser(null)
      },
    [isNotLoggedIn, queryClient],
  )
}

export function useLogoutAndFail() {
  const { showSnackbar } = useSnackbar()
  const isNotLoggedIn = !useIsLoggedIn()
  const logoutSilently = useLogoutSilently()
  const { tGeneral } = useGeneralTranslation()

  return useCallback(() => {
    if (isNotLoggedIn) return

    logoutSilently()
    showSnackbar({
      mode: "error",
      key: "loggedOutByServerError",
      message: tGeneral("errorUserLoggedOut"),
    })
  }, [isNotLoggedIn, logoutSilently, showSnackbar, tGeneral])
}
