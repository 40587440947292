import { useFonts } from "expo-font"
import React, { ReactNode } from "react"

import { LoadingIndicator, ThemeProvider } from "@axtesys/kassen-app-ui"
import {
  RobotoMono_300Light,
  RobotoMono_300Light_Italic,
  RobotoMono_400Regular,
  RobotoMono_400Regular_Italic,
  RobotoMono_600SemiBold,
  RobotoMono_600SemiBold_Italic,
} from "@expo-google-fonts/roboto-mono"
import {
  SourceSansPro_300Light,
  SourceSansPro_300Light_Italic,
  SourceSansPro_400Regular,
  SourceSansPro_400Regular_Italic,
  SourceSansPro_600SemiBold,
  SourceSansPro_600SemiBold_Italic,
} from "@expo-google-fonts/source-sans-pro"

export function StyleProvider({ children }: { children: ReactNode }) {
  // Fonts used in theme: Only has effect on Android and Web applications.
  const [isFontsLoaded] = useFonts({
    "SourceSansPro-Regular": SourceSansPro_400Regular,
    "SourceSansPro-Light": SourceSansPro_300Light,
    "SourceSansPro-SemiBold": SourceSansPro_600SemiBold,
    "RobotoMono-Regular": RobotoMono_400Regular,
    "RobotoMono-Light": RobotoMono_300Light,
    "RobotoMono-SemiBold": RobotoMono_600SemiBold,
    "SourceSansPro-Italic": SourceSansPro_400Regular_Italic,
    "SourceSansPro-LightItalic": SourceSansPro_300Light_Italic,
    "SourceSansPro-SemiBoldItalic": SourceSansPro_600SemiBold_Italic,
    "RobotoMono-Italic": RobotoMono_400Regular_Italic,
    "RobotoMono-LightItalic": RobotoMono_300Light_Italic,
    "RobotoMono-SemiBoldItalic": RobotoMono_600SemiBold_Italic,
  })

  if (!isFontsLoaded) return <LoadingIndicator />

  return <ThemeProvider>{children}</ThemeProvider>
}
