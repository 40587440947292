import { useCallback, useRef } from "react"

type Subscriber<T> = (value: T) => void
type Subscribe<T> = (subscriber: Subscriber<T>) => Unsubscribe
type Unsubscribe = () => void

// Alternative to rxjs - BehaviorSubject:
// Provides publish/subscribe functionality.
// Listeners can subscribe to published values and
// unsubscribe at a later point.
// Publishing or subscribing will not (explicitly)
// trigger a re-render of the component.
export function usePubSub<T>() {
  const subscribersRef = useRef<Subscriber<T>[]>([])

  const publish = useCallback((value: T) => {
    for (const notifySubscriber of subscribersRef.current) {
      notifySubscriber(value)
    }
  }, [])

  const subscribe: Subscribe<T> = useCallback(subscriber => {
    subscribersRef.current.push(subscriber)

    return (() => {
      subscribersRef.current = subscribersRef.current.filter(
        item => item != subscriber,
      )
    }) as Unsubscribe
  }, [])

  return { publish, subscribe }
}
