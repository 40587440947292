import { useMemo } from "react"

import { createCustomContext } from "@axtesys/react-tools"

import { useScreenSizeType } from "./hooks/useScreenSizeType"
import { BorderRadius, Spacing, TextSize } from "./types"

type FontTypes = {
  regular: string
  bold: string
  light: string
  italic: string
  boldItalic: string
  lightItalic: string
}
type FontFamily = {
  sourceSansPro: FontTypes
  robotoMono: FontTypes
}
type ColorVariants = {
  default: string
  hovered: string
  pressed: string
  disabled: string
}

export type Theme = {
  color: {
    background: string
    border: string

    primary: string
    accent: string
    disabled: string

    error: string
    success: string
    warning: string
    info: string

    secondary1: string
    secondary2: string
    secondary3: string

    drawerBlue: string

    base1: string
    base2: string
    base3: string
    base4: string
    base5: string
    base6: string

    attention: string
    selection: string

    button: {
      default: ColorVariants
      callToAction: ColorVariants
    }

    text: {
      default: string
      inverted: string
      light: string
      medium: string
    }

    keyboardLayoutPalette: string[]
  }
  borderRadius: Record<BorderRadius, number>
  spacing: Record<Spacing, number>
  fontSize: Record<TextSize, number>
  fontFamily: FontFamily
  lineHeightCorrection: readonly [number, number]
}

export const [ThemeProvider, useTheme] = createCustomContext(() => {
  const screenSizeType = useScreenSizeType()

  const color = useMemo(
    () => ({
      background: "#FFFFFF",
      border: "#000000",

      primary: "#00305B",
      accent: "#B71E3F",
      disabled: "#99ACBD",

      error: "#FE0707",
      success: "#4DC322",
      warning: "#F6CA38",
      info: "#3982DB",

      secondary1: "#2B5B95",
      secondary2: "#45ABED",
      secondary3: "#14D9D9",

      drawerBlue: "#547491",

      base1: "#2E2E2E",
      base2: "#4E4F50",
      base3: "#696A6B",
      base4: "#B2B3B5",
      base5: "#DDDDDD",
      base6: "#E5E5E587",

      attention: "#FFBFBF",
      selection: "#5B00FF",

      button: {
        callToAction: {
          default: "#B71E3F",
          hovered: "#E44668",
          pressed: "#920C29",
          disabled: "#F37F98",
        },
        default: {
          default: "#00305B",
          hovered: "#2B5B95",
          pressed: "#04294A",
          disabled: "#99ACBD",
        },
      },

      text: {
        default: "#2E2E2E",
        inverted: "#F6F6F6",
        light: "#929597",
        medium: "#4E4F50",
      },

      keyboardLayoutPalette: [
        "#00305B",
        "#B71E3F",
        "#6F28DF",
        "#CB1967",
        "#B03C4C",
        "#4169E1",

        "#8860D0",
        "#6B6757",
        "#D66F4B",
        "#FF6F00",
        "#00B248",
        "#8186BD",

        "#4998D9",
        "#86C232",
        "#5FA09E",
        "#FF89AA",
        "#F6D000",
        "#00BCD4",

        "#54C69E",
        "#C8C846",
        "#C796BE",
        "#F6C343",
        "#DDAC66",
        "#FFAB91",

        "#EFB17B",
        "#8BBFAD",
        "#AED291",
        "#F5C884",
        "#ECB2C4",
        "#80DEEA",
      ],
    }),
    [],
  )

  const borderRadius = useMemo(() => ({ none: 0, S: 2, M: 8, L: 8 }), [])

  const spacing = useMemo(
    () => ({
      none: 0,
      XXXXXS: 2,
      XXXXS: 4,
      XXXS: 8,
      XXS: 12,
      XS: 16,
      SX: 20,
      S: 24,
      SM: 36,
      M: 48,
      L: 56,
      XL: 112,
    }),
    [],
  )

  const fontSize = useMemo(
    () =>
      ({
        S: {
          h1: 52,
          h2: 36,
          h3: 24,
          h4: 22,
          h5: 18,
          default: 16,
          medium: 14,
          small: 12,
          tiny: 10,
        },
        M: {
          h1: 58,
          h2: 38,
          h3: 30,
          h4: 24,
          h5: 18,
          default: 16,
          medium: 14,
          small: 12,
          tiny: 10,
        },
        L: {
          h1: 60,
          h2: 40,
          h3: 32,
          h4: 26,
          h5: 20,
          default: 16,
          medium: 14,
          small: 12,
          tiny: 10,
        },
      })[screenSizeType],
    [screenSizeType],
  )

  const fontFamily = useMemo(
    () => ({
      sourceSansPro: {
        regular: "SourceSansPro-Regular",
        bold: "SourceSansPro-SemiBold",
        light: "SourceSansPro-Light",
        italic: "SourceSansPro-Italic",
        boldItalic: "SourceSansPro-SemiBoldItalic",
        lightItalic: "SourceSansPro-LightItalic",
      },
      robotoMono: {
        regular: "RobotoMono-Regular",
        bold: "RobotoMono-SemiBold",
        light: "RobotoMono-Light",
        italic: "RobotoMono-Italic",
        boldItalic: "RobotoMono-SemiBoldItalic",
        lightItalic: "RobotoMono-LightItalic",
      },
    }),
    [],
  )

  const lineHeightCorrection = useMemo(() => [0.2, 0.08] as const, [])

  return {
    color,
    spacing,
    fontSize,
    fontFamily,
    borderRadius,
    lineHeightCorrection,
  } as Theme
})
