import { useCallback, useRef } from "react"

import {
  useAppDialog,
  useDialog,
  useSimpleDialog,
} from "@axtesys/kassen-app-ui"

import { useIsAppFrontend } from "../Config/queries"
import { t_General, t_Translations } from "../Translation/lib"

export function useSystemTimeDialog() {
  const currentDisplayRef = useRef(false)
  const { showConfirmDialog } = useSimplePlatformDialog()

  const showSystemTimeDialog = useCallback(
    async (type: "past" | "future", latestOfflineTimestamp?: string) => {
      if (currentDisplayRef.current) return

      currentDisplayRef.current = true

      const { tGeneral, tValidations } = t_Translations()
      await showConfirmDialog({
        title: tGeneral("warning"),
        message:
          tValidations("validationMutationInPastOrFuture", {
            type: tGeneral(type),
          }) +
          (latestOfflineTimestamp
            ? `\n\n${tValidations("validationMutationInPastDeadlock", {
                timestamp: latestOfflineTimestamp,
              })}`
            : ""),
        label: tGeneral("understood"),
      })

      currentDisplayRef.current = false
    },
    [showConfirmDialog],
  )

  return { showSystemTimeDialog }
}

export function useErrorNoticeDialog() {
  const { showConfirmDialog } = useSimplePlatformDialog()

  const showErrorNoticeDialog = useCallback(
    async (message?: string) => {
      if (!message) return

      const { tGeneral } = t_General()
      await showConfirmDialog({
        message,
        title: tGeneral("error"),
        label: tGeneral("understood"),
      })
    },
    [showConfirmDialog],
  )

  return { showErrorNoticeDialog }
}

export function useDeleteDialog() {
  const { showConfirmCancelDialog } = usePlatformDialog()

  const showDeleteDialog = useCallback(
    (config: { title: string; message: string }) => {
      const { tGeneral } = t_General()

      return showConfirmCancelDialog({
        ...config,
        cancelLabel: tGeneral("cancel"),
        confirmLabel: tGeneral("delete"),
      })
    },
    [showConfirmCancelDialog],
  )

  return { showDeleteDialog }
}

export function useSimplePlatformDialog() {
  const simpleDialog = useSimpleDialog()
  const appDialog = useAppDialog()

  const { showConfirmDialog } = useIsAppFrontend() ? appDialog : simpleDialog

  return { showConfirmDialog }
}

export function usePlatformDialog() {
  const dialog = useDialog()
  const appDialog = useAppDialog()

  const { showConfirmDialog, showConfirmCancelDialog } = useIsAppFrontend()
    ? appDialog
    : dialog

  return { showConfirmDialog, showConfirmCancelDialog }
}
