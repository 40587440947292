import * as z from "zod"

export type JsonDbEntity<T, TSchema extends z.ZodType> = {
  schema: TSchema
  serialize: (data: T) => z.infer<TSchema>
  deserialize: (json: z.infer<TSchema>) => T
}

// Fluent API for creating entities with a zod schema
// and serialize/deserialize code for
// mapping runtime types (T) to the zod schema.

type JsonDbEntityCreator3<T, TSchema extends z.ZodType> = {
  deserialize(
    deserialize: (json: z.infer<TSchema>) => T,
  ): JsonDbEntity<T, TSchema>
}
type JsonDbEntityCreator2<T, TSchema extends z.ZodType> = {
  serialize(
    serialize: (data: T) => z.infer<TSchema>,
  ): JsonDbEntityCreator3<T, TSchema>
}
type JsonDbEntityCreator<T> = {
  withSchema<TSchema extends z.ZodType>(
    schema: TSchema,
  ): JsonDbEntityCreator2<T, TSchema>
}

export function createEntityFor<T>(): JsonDbEntityCreator<T> {
  return {
    withSchema: schema => ({
      serialize: serialize => ({
        deserialize: deserialize => ({ schema, serialize, deserialize }),
      }),
    }),
  }
}
