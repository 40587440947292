import { DependencyList, useEffect, useRef } from "react"

// Executes an effect after the initial mount and
// whenever a dependency in the dependencyList changes.
export function useSubsequentEffect(
  effect: () => void,
  dependencyList: DependencyList,
) {
  const mountRef = useRef<boolean>(false)

  useEffect(() => {
    // Skip the first effect execution.
    if (!mountRef.current) {
      mountRef.current = true
      return
    }

    effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList)
}
