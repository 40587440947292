import { entries } from "lodash"
import React, { useMemo } from "react"
import { Platform, ScrollView, StyleSheet, View } from "react-native"

import { useOnOff } from "@axtesys/hooks"

import { HoverablePressableOpacity } from "../button/HoverablePressableOpacity"
import { Icon } from "../display/Icon"
import { Row } from "../layout/FlexBox"
import { useKassenAppUI } from "../providers/KassenAppUIProvider"
import { useTheme } from "../theme"
import { ScreenCoordinates } from "../types"
import { Label } from "../typography/Label"
import { TransparentModal } from "./TransparentModal"

export type KeyLabels = Record<string, string>

type MenuProps = {
  visible: boolean
  position: ScreenCoordinates
  displayKeyValuePairs: KeyLabels

  onDismiss: () => void
  onSelect: (key: keyof KeyLabels) => void

  minWidth?: number
  excludedValues?: (keyof KeyLabels)[]
  selectedValues?: (keyof KeyLabels)[]
}

export function Menu(props: MenuProps) {
  const {
    visible,
    minWidth,
    position,
    selectedValues,
    excludedValues,
    displayKeyValuePairs,
    onSelect,
    onDismiss,
  } = props

  const { translations } = useKassenAppUI()
  const displayItems = useMemo(
    () =>
      visible
        ? entries(displayKeyValuePairs).filter(
            ([key, _]) => !excludedValues || !excludedValues.includes(key),
          )
        : [],
    [displayKeyValuePairs, excludedValues, visible],
  )

  if (!visible) return null

  const items =
    displayItems.length > 0
      ? displayItems.map(([key, label]) => (
          <MenuItem
            key={key}
            label={label}
            isSelected={
              selectedValues && !!selectedValues.find(value => value == key)
            }
            onPress={() => onSelect(key)}
          />
        ))
      : [
          <MenuItem
            disabled
            key="noItemsPresentItem"
            label={translations.emptyOptionMessage}
          />,
        ]

  return (
    <TransparentModal isVisible={visible} onPressOutside={onDismiss}>
      <View
        style={[
          styles.container,
          { minWidth, top: position.y, left: position.x },
        ]}
      >
        <ScrollView style={[styles.scroll, { minWidth }]}>{items}</ScrollView>
      </View>
    </TransparentModal>
  )
}

type MenuItemProps = {
  label: string

  disabled?: boolean
  isSelected?: boolean
  onPress?: () => void
}

function MenuItem({ label, disabled, isSelected, onPress }: MenuItemProps) {
  const { color } = useTheme()
  const [isHovered, onHoverIn, onHoverOut] = useOnOff(false)

  const item = (
    <Row
      alignCenter
      style={styles.item}
      disabled={disabled}
      padHorizontal="XXXS"
      gap={isSelected ? "XXS" : "none"}
    >
      {isSelected && <Icon name="check" />}
      <Label medium text={label} selectable={false} />
    </Row>
  )

  return (
    <HoverablePressableOpacity
      disabled={disabled}
      style={[
        styles.hoverable,
        isHovered && {
          backgroundColor: color.base5,
          borderLeftColor: color.secondary2,
        },
      ]}
      onPress={onPress}
      onPressIn={onHoverIn}
      onHoverIn={onHoverIn}
      onPressOut={onHoverOut}
      onHoverOut={onHoverOut}
    >
      {item}
    </HoverablePressableOpacity>
  )
}

const styles = StyleSheet.create({
  item: { height: 48 },
  scroll: { maxHeight: 250 },
  container: {
    shadowRadius: 4,
    shadowOpacity: 0.5,
    position: "absolute",
    shadowColor: "black",
    backgroundColor: "white",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    shadowOffset: { height: 2, width: 0 },
    elevation: Platform.OS == "android" ? 5 : undefined,
  },
  hoverable: { borderLeftWidth: 4, borderLeftColor: "transparent" },
})
