import Big from "big.js"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { TaxRate } from "../lib/TaxRate"

export type TaxInfo = {
  taxRate: TaxRate
  gross: Big
  tax: Big
  net: Big
  discount: Big
  undiscountedGross: Big
}

export type TotalTaxInfo = Omit<TaxInfo, "taxRate"> & { correctedGross: Big }

export const TaxInfoEntity = createEntityFor<TaxInfo>()
  .withSchema(
    z.object({
      taxRate: z.number(),
      gross: z.string(),
      tax: z.string(),
      net: z.string(),
      discount: z.string(),
      undiscountedGross: z.string(),
    }),
  )
  .serialize(data => ({
    taxRate: data.taxRate,
    gross: data.gross.toString(),
    tax: data.tax.toString(),
    net: data.net.toString(),
    discount: data.discount.toString(),
    undiscountedGross: data.undiscountedGross.toString(),
  }))
  .deserialize(json => ({
    taxRate: json.taxRate,
    gross: Big(json.gross),
    tax: Big(json.tax),
    net: Big(json.net),
    discount: Big(json.discount),
    undiscountedGross: Big(json.undiscountedGross),
  }))

export const TotalTaxInfoEntity = createEntityFor<TotalTaxInfo>()
  .withSchema(
    z.object({
      gross: z.string(),
      tax: z.string(),
      net: z.string(),
      discount: z.string(),
      correctedGross: z.string(),
      undiscountedGross: z.string(),
    }),
  )
  .serialize(data => ({
    gross: data.gross.toString(),
    tax: data.tax.toString(),
    net: data.net.toString(),
    discount: data.discount.toString(),
    correctedGross: data.correctedGross?.toString(),
    undiscountedGross: data.undiscountedGross.toString(),
  }))
  .deserialize(json => ({
    gross: Big(json.gross),
    tax: Big(json.tax),
    net: Big(json.net),
    discount: Big(json.discount),
    correctedGross: Big(json.correctedGross),
    undiscountedGross: Big(json.undiscountedGross),
  }))
