import React from "react"
import { Text } from "react-native"

import { Column } from "../layout/FlexBox"
import { useTheme } from "../theme"

export type TextCircleProps = {
  text: string
  size: "XXXS" | "XXS" | "XS" | "S" | "M" | "L" | "XL"

  color?: string
  labelBold?: boolean
  labelColor?: "primary" | "inverted"

  onPress?: () => void
}

const CIRCLE_SIZE = { XXXS: 16, XXS: 20, XS: 24, S: 32, M: 40, L: 64, XL: 96 }

export function TextCircle(props: TextCircleProps) {
  const theme = useTheme()

  const size = CIRCLE_SIZE[props.size]

  const color =
    props.labelColor && props.labelColor == "inverted"
      ? theme.color.text.default
      : theme.color.text.inverted

  const fontSize = {
    XXXS: theme.fontSize.tiny,
    XXS: theme.fontSize.small,
    XS: theme.fontSize.medium,
    S: theme.fontSize.default,
    M: theme.fontSize.h5,
    L: theme.fontSize.h4,
    XL: theme.fontSize.h3,
  }[props.size]

  const fontFamily = props.labelBold
    ? theme.fontFamily.sourceSansPro.bold
    : theme.fontFamily.sourceSansPro.regular

  const backgroundColor = props.color ? props.color : theme.color.drawerBlue

  return (
    <Column
      alignCenter
      style={{
        width: size,
        height: size,
        backgroundColor,
        borderRadius: size / 2,
        justifyContent: "center",
      }}
      onPress={props.onPress}
    >
      <Text style={{ color, fontSize, fontFamily }}>{props.text}</Text>
    </Column>
  )
}
