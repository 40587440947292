import { useEffect } from "react"
import { Dimensions, Platform } from "react-native"

// Observes the change of window/screen dimensions and executes a callback.
export function useResizeEffect(effect: () => void) {
  useEffect(() => {
    if (Platform.OS == "web") {
      window.addEventListener("resize", effect)

      return () => window.removeEventListener("resize", effect)
    }

    const screenSizeSubscription = Dimensions.addEventListener("change", effect)
    return () => screenSizeSubscription.remove()
  })
}
