import React, { useMemo } from "react"
import { Platform } from "react-native"

import { useSelection } from "~shared/components/providers/SelectionProvider"

import { KeyLabels } from "../../container/Menu"
import { useDisplayValue } from "../../hooks/useDisplayValue"
import { IconConfig, TextInput, TextInputProps } from "./TextInput"

type SelectionInputProps = Omit<TextInputProps, "mode" | "onChange"> & {
  displayKeyValuePairs: KeyLabels

  title?: string
  tooltip?: string
  clearable?: boolean
  enableSearch?: boolean
  excludedValues?: (keyof KeyLabels)[]
}

type SingleSelectionInputProps = {
  value?: keyof KeyLabels
  onChange?(value: keyof KeyLabels): void
} & SelectionInputProps

type MultiSelectionInputProps = {
  category: string
  values: (keyof KeyLabels)[]
  onChange?(values: (keyof KeyLabels)[]): void
} & SelectionInputProps

type SelectionInputInternalProps = {
  displayValue: string
} & (SingleSelectionInputProps | MultiSelectionInputProps)

export function SingleSelectionInput(props: SingleSelectionInputProps) {
  const { value, displayKeyValuePairs } = props

  const displayValue = value ? displayKeyValuePairs[value] : ""

  return <InternalSelectionInput {...props} displayValue={displayValue} />
}

export function MultiSelectionInput(props: MultiSelectionInputProps) {
  return (
    <InternalSelectionInput {...props} displayValue={useDisplayValue(props)} />
  )
}

function InternalSelectionInput(props: SelectionInputInternalProps) {
  const { iconRight, textInputProps, onSelect } = useTextInputProps(props)

  return (
    <TextInput
      {...textInputProps}
      editable={false}
      iconRight={iconRight}
      value={props.displayValue}
      pointerEvents={Platform.OS == "web" ? "none" : undefined}
      onChange={() => {}}
      // Required for web (does not support onPressIn)
      onPress={onSelect}
      // Required for app (does not support onPress)
      onPressIn={onSelect}
    />
  )
}

function useTextInputProps(props: SelectionInputInternalProps) {
  const {
    title,
    tooltip,
    clearable,
    enableSearch,
    displayValue,
    excludedValues,
    displayKeyValuePairs,
    ...textInputProps
  } = props

  const { selectSingle, selectMulti } = useSelection()

  const iconRight: IconConfig =
    !displayValue || !clearable
      ? { name: "format-list-checkbox" }
      : {
          name: "close",
          onPress: () =>
            !("category" in props)
              ? props.onChange?.("")
              : props.onChange?.([]),
        }

  const items = useMemo(
    () =>
      Object.keys(displayKeyValuePairs).filter(
        key => !excludedValues?.includes(key),
      ),
    [displayKeyValuePairs, excludedValues],
  )
  const onSelect = async () => {
    const sharedConfig = {
      title,
      items,
      tooltip,
      displayMapping: (identifier: keyof KeyLabels) =>
        displayKeyValuePairs[identifier],
      filterSelector: enableSearch
        ? (identifier: keyof KeyLabels) => [displayKeyValuePairs[identifier]]
        : undefined,
    }

    if (!("category" in props)) {
      selectSingle({ ...sharedConfig, selection: props.value }).then(
        selection => props.onChange?.(selection),
      )
      return
    }

    selectMulti({
      ...sharedConfig,
      selection: props.values ? [...props.values] : [],
    }).then(selection => props.onChange?.(selection))
  }

  return { iconRight, textInputProps, onSelect }
}
