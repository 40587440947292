import React from "react"

import { Button } from "../button/Button"
import { BoxProps } from "../layout/Box"
import { Row } from "../layout/FlexBox"

type ButtonConfig = {
  label: string
  disabled?: boolean
  errorMessage?: string
  onPress?: () => void
}

type ButtonBarProps = {
  cancelButton?: ButtonConfig
  contentContainer?: BoxProps
  actionButtons?: ButtonConfig[]
}

export function ButtonBar(props: ButtonBarProps) {
  return (
    <Row
      gap="XS"
      style={{ justifyContent: "flex-end" }}
      {...props.contentContainer}
    >
      {props.cancelButton && (
        <Button
          outlined
          text={props.cancelButton.label}
          onPress={props.cancelButton.onPress}
        />
      )}

      <Row gap="XS">
        {props.actionButtons?.map(buttonConfig => (
          <Button
            text={buttonConfig.label}
            key={`buttonBarButton_${buttonConfig.label}`}
            {...buttonConfig}
          />
        ))}
      </Row>
    </Row>
  )
}
