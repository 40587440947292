import { atom } from "recoil"
import * as z from "zod"

import { jsonDbAtom } from "@axtesys/react-tools"

import { TecsConnection, ZvtConnection } from "./types"

export const hobexConnectionArgsState = jsonDbAtom({
  key: "hobexConnectionArgs",
  default: undefined as
    | null
    | undefined
    | Pick<TecsConnection, "tid" | "password">
    | Pick<ZvtConnection, "host" | "port">,
  schema: z.union([
    z.null(),
    z.undefined(),
    z.object({ tid: z.string(), password: z.string() }),
    z.object({ host: z.string(), port: z.number() }),
  ]),
})

export const hobexConnectionState = atom({
  key: "hobexConnection",
  default: "not used" as
    | "not used"
    | "connecting"
    | "connected"
    | "failed to connect",
})
