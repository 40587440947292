import { useDeviceInfo } from "./useDeviceInfo"

interface Device<T = any> {
  desktop: T
  tablet: T
  phone: T
}

export function useDeviceDependentValue(values: Device): any {
  return values[useDeviceInfo().deviceType]
}
