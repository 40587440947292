import React, { ReactNode } from "react"
import { Text } from "react-native"

import { useColor } from "../hooks/useColor"
import { BoxStyleProps, useBoxStyle } from "../layout/Box"
import { useTheme } from "../theme"
import { Color } from "../types"

export type LabelProps = Omit<BoxStyleProps, "alignCenter"> & {
  // A Label can either take a regular string
  // or some kind of custom defined component.
  text?: ReactNode

  // Pre-configured text size and styling properties
  // (regulates size and bold (h1-5) emphasis;
  //  setting multiple of the following properties has no effect
  //  since the Label will always use the highest order property passed).
  h1?: boolean
  h2?: boolean
  h3?: boolean
  h4?: boolean
  h5?: boolean
  medium?: boolean
  small?: boolean
  tiny?: boolean

  // If set, overwrites any of the
  // above size and styling properties.
  fontSize?: number

  // Standard emphasis properties that apply
  // font styles to the Label
  // (in case 'underline' and 'strikethrough'
  //  are both set to 'true', 'strikethrough' will take precedence).
  bold?: boolean
  italic?: boolean
  underline?: boolean
  strikethrough?: boolean

  // If set to 'false' the user cannot select text from the Label
  // and/or use the native copy and paste functionality.
  selectable?: boolean

  // In case either of the two following properties is set,
  // and there is no available space left in line for the Label to expand to,
  // the text will be truncated and three dots '...'
  // will be appended at the end of the line.

  // The text of the Label will only ever take up a single line and
  // will be truncated if it exceeds the line.
  singleLine?: boolean

  // Number of lines the text of the Label is allowed to expand to
  // (after that, truncation happens; in case 'singleLine' is set to 'true',
  //  setting this property will have no effect).
  numberOfLines?: number

  // Additional styling properties of the Label.

  color?: Color

  // In case 'uppercase' and 'lowercase'
  // are set to 'true', 'uppercase' will take precedence.
  uppercase?: boolean
  lowercase?: boolean

  fontFamily?: "normal" | "mono"
  textAlign?: "left" | "center" | "right"
}

export function Label(props: LabelProps) {
  const theme = useTheme()
  const color = useColor(props.color)
  const boxStyle = useBoxStyle(props)

  let fontSize = theme.fontSize.default
  if (props.fontSize) fontSize = props.fontSize
  else if (props.h1) fontSize = theme.fontSize.h1
  else if (props.h2) fontSize = theme.fontSize.h2
  else if (props.h3) fontSize = theme.fontSize.h3
  else if (props.h4) fontSize = theme.fontSize.h4
  else if (props.h5) fontSize = theme.fontSize.h5
  else if (props.medium) fontSize = theme.fontSize.medium
  else if (props.small) fontSize = theme.fontSize.small
  else if (props.tiny) fontSize = theme.fontSize.tiny

  const fontFamilyType =
    props.fontFamily == "mono"
      ? theme.fontFamily.robotoMono
      : theme.fontFamily.sourceSansPro

  let fontFamily = fontFamilyType.regular
  const isBold =
    props.bold != false &&
    (props.h1 || props.h2 || props.h3 || props.h4 || props.h5 || props.bold)
  if (isBold && props.italic) fontFamily = fontFamilyType.boldItalic
  else if (isBold) fontFamily = fontFamilyType.bold
  else if (props.italic) fontFamily = fontFamilyType.italic

  const marginTop = -theme.lineHeightCorrection[0] * fontSize
  const marginBottom = -theme.lineHeightCorrection[1] * fontSize
  const textDecorationLine = props.strikethrough
    ? "line-through"
    : props.underline
    ? "underline"
    : "none"

  let numberOfLines: number | undefined
  if (props.singleLine) numberOfLines = 1
  else if (props.numberOfLines) numberOfLines = props.numberOfLines

  let text = props.text
  if (typeof props.text == "string") {
    if (props.uppercase) text = props.text.toUpperCase()
    else if (props.lowercase) text = props.text.toLowerCase()
  }

  return (
    <Text
      numberOfLines={numberOfLines}
      selectable={props.selectable}
      style={[
        {
          color,
          fontSize,
          marginTop,
          fontFamily,
          marginBottom,
          textDecorationLine,
          textAlign: props.textAlign,
        },
        boxStyle,
        props.style,
      ]}
    >
      {text}
    </Text>
  )
}
