import React, { ReactNode } from "react"
import { useRecoilValue, waitForAllSettled } from "recoil"

import { cashFlowReceiptCountState } from "~src/application/app/feature/CashFlowReceipts/state"
import {
  cardDeviceState,
  cashRegisterState,
  defaultReceiptPrinterIdState,
  installationIdConstant,
  receiptPrintersState,
} from "~src/application/app/feature/CashRegister/state"
import {
  drawerConnectedPrinterIdState,
  drawerDeviceState,
} from "~src/application/app/feature/Drawer/state"
import { hobexConnectionArgsState } from "~src/application/app/feature/Hobex/state"
import { lastAppVersionState } from "~src/application/app/feature/Migrations/state"
import {
  articleGroupsState,
  articlesState,
  cashFlowsState,
  closeOfDayTimeState,
  companyInfoState,
  contactsState,
  isRksvEnabledState,
  keyboardLayoutState,
  mainGroupsState,
  paymentMethodsState,
  predefinedDiscountsState,
  receiptDesignState,
  taxOfficeCashBoxIdState,
} from "~src/application/app/feature/OfflineData/state"
import {
  deadMutationsState,
  failedDeadMutationsState,
  offlineMutationsState,
} from "~src/application/app/feature/OfflineMutations/state"
import {
  lastSuccessfulCancelReceiptIdState,
  lastSuccessfulReceiptIdState,
  latestCompletedDisplayReceipt,
  receiptCountState,
  receiptIdState,
} from "~src/application/app/feature/Receipts/state"
import {
  cartDiscountHistoryState,
  cartItemsHistoryState,
  cartRefundInfoState,
  currentInvoiceContactIdState,
  currentInvoiceDepartmentIdState,
  currentInvoiceShippingAddressIdState,
} from "~src/application/app/feature/ShoppingCart/state"
import { askForPrinterSetupState } from "~src/application/app/feature/Walkthrough/state"

import { authenticatedUserState } from "../../feature/Authentication/state"
import { backendState, frontendState } from "../../feature/Config"
import { lastServerInteractionState } from "../../feature/FraudProtection/state"
import {
  logLevelState,
  logMessageQueueState,
} from "../../feature/Logging/state"

// Preloads all data from JsonDb before rendering anything.
// This avoids some weird bugs when
// accessing or setting states in callbacks/selectors
// that are still in a loading state.
export function JsonDbLoadingGateProvider({
  children,
}: {
  children: ReactNode
}) {
  useRecoilValue(
    waitForAllSettled([
      installationIdConstant,
      logMessageQueueState,
      authenticatedUserState,
      lastAppVersionState,
      lastServerInteractionState,
      cashRegisterState,
      receiptCountState,
      receiptIdState,
      cashFlowReceiptCountState,
      defaultReceiptPrinterIdState,
      receiptPrintersState,
      cardDeviceState,
      drawerDeviceState,
      drawerConnectedPrinterIdState,
      hobexConnectionArgsState,
      mainGroupsState,
      articleGroupsState,
      articlesState,
      predefinedDiscountsState,
      paymentMethodsState,
      cashFlowsState,
      isRksvEnabledState,
      closeOfDayTimeState,
      receiptDesignState,
      companyInfoState,
      deadMutationsState,
      offlineMutationsState,
      failedDeadMutationsState,
      cartRefundInfoState,
      cartItemsHistoryState,
      cartDiscountHistoryState,
      askForPrinterSetupState,
      backendState,
      logLevelState,
      contactsState,
      currentInvoiceContactIdState,
      currentInvoiceDepartmentIdState,
      currentInvoiceShippingAddressIdState,
      frontendState,
      lastSuccessfulReceiptIdState,
      lastSuccessfulCancelReceiptIdState,
      latestCompletedDisplayReceipt,
      keyboardLayoutState,
      taxOfficeCashBoxIdState,
    ]),
  )

  return <>{children}</>
}
