import React, { ReactNode } from "react"

import { Box, BoxProps } from "../layout/Box"

type CardProps = BoxProps & { children: ReactNode }

export function Card({ children, ...props }: CardProps) {
  return (
    <Box
      elevation={10}
      borderRadius="L"
      backgroundColor="white"
      pad={["XXXS", "XS", "S"]}
      {...props}
    >
      {children}
    </Box>
  )
}
