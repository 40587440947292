import React from "react"
import { Platform } from "react-native"

import { TextInput, TextInputProps } from "./TextInput"

export function NumberTextInput(props: TextInputProps) {
  return (
    <TextInput
      {...props}
      keyboardType={
        Platform.OS == "ios" ? "numbers-and-punctuation" : "default"
      }
    />
  )
}
