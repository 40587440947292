import {
  lockAsync,
  OrientationLock,
  unlockAsync,
} from "expo-screen-orientation"
import { useEffect } from "react"
import { Dimensions, Platform } from "react-native"

type DeviceOrientationLockArgs = {
  // Screens larger than this number of pixels are considered large.
  sizeThreshold: number

  // The requested locked orientation for the corresponding screen size.
  smallScreenOrientation?: OrientationLock
  largeScreenOrientation?: OrientationLock
}

export function useDeviceOrientationLock(args: DeviceOrientationLockArgs) {
  useEffect(() => {
    if (Platform.OS == "web") return undefined

    const isSmallScreen = getScreenMaxDimension() < args.sizeThreshold
    if (isSmallScreen && args.smallScreenOrientation) {
      lockAsync(args.smallScreenOrientation)
    }

    if (!isSmallScreen && args.largeScreenOrientation) {
      lockAsync(args.largeScreenOrientation)
    }

    return () => {
      unlockAsync()
    }
  }, [
    args.sizeThreshold,
    args.smallScreenOrientation,
    args.largeScreenOrientation,
  ])
}

function getScreenMaxDimension() {
  const windowWidth = Dimensions.get("window").width
  const windowHeight = Dimensions.get("window").height

  return Math.max(windowWidth, windowHeight)
}
