import { useState } from "react"

export type OnOffFunction = () => void

export function useOnOff(
  initialValue: boolean,
): [boolean, OnOffFunction, OnOffFunction] {
  const [value, setValue] = useState(initialValue)

  const on = () => setValue(true)
  const off = () => setValue(false)

  return [value, on, off]
}
