import React from "react"

import { ResponsiveSpacing } from "../types"
import { Box } from "./Box"

type GapProps = {
  size?: ResponsiveSpacing
  vertical?: ResponsiveSpacing
  horizontal?: ResponsiveSpacing
}

export function Gap({ size, vertical, horizontal }: GapProps) {
  return <Box padTop={vertical ?? size} padLeft={horizontal ?? size} />
}
