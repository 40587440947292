import { useCallback } from "react"

import { ScreenSizeType, useScreenSizeType } from "./useScreenSizeType"

export function useScreenSizeDependentValue<T>(
  values: Record<ScreenSizeType, T>,
): T {
  return values[useScreenSizeType()]
}

export function useRetrieveScreenSizeDependentValue<T>() {
  const screenSizeType = useScreenSizeType()
  return useCallback(
    (values: Record<ScreenSizeType, T>) => values[screenSizeType],
    [screenSizeType],
  )
}
