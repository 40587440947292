import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import {
  PhysicalReceiptDesignFragment,
  ReceiptDesignEntryFragment,
} from "../api/graphql/types"

export type TextAlign = "left" | "right" | "center"

export type ReceiptDesignEntry = {
  text: string
  bold: boolean
  underline: boolean

  textAlign?: TextAlign
}

export type SharedOptionalReceiptDesign = {
  headerImage?: number[]
  showHeaderEntries?: boolean
  showFooterEntries?: boolean
  header?: ReceiptDesignEntry[]
  footer?: ReceiptDesignEntry[]
  headerImageAspectRatio?: number
}

const ReceiptDesignEntryEntity = createEntityFor<ReceiptDesignEntry>()
  .withSchema(
    z.object({
      text: z.string(),
      bold: z.boolean(),
      underline: z.boolean(),
      textAlign: z.enum(["left", "right", "center"]).optional(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)

export const ReceiptDesignEntity =
  createEntityFor<SharedOptionalReceiptDesign>()
    .withSchema(
      z.object({
        headerImage: z.array(z.number()).optional(),
        showHeaderEntries: z.oboolean(),
        showFooterEntries: z.oboolean(),
        header: z.array(ReceiptDesignEntryEntity.schema).optional(),
        footer: z.array(ReceiptDesignEntryEntity.schema).optional(),
      }),
    )
    .serialize(data => data)
    .deserialize(json => json)

export function transformFragmentToPhysicalReceiptDesign(
  receiptDesign: PhysicalReceiptDesignFragment & { headerImage?: string },
): SharedOptionalReceiptDesign {
  const transformEntry = (
    entry: ReceiptDesignEntryFragment,
  ): ReceiptDesignEntry => ({
    ...entry,
    textAlign: entry.textAlign?.toLowerCase() as any,
  })

  return {
    header: receiptDesign.header?.map(transformEntry) ?? [],
    footer: receiptDesign.footer?.map(transformEntry) ?? [],
    showHeaderEntries: receiptDesign.showHeaderEntries ?? true,
    showFooterEntries: receiptDesign.showFooterEntries ?? true,
    headerImage: receiptDesign.headerImage
      ? Buffer.from(receiptDesign.headerImage, "base64").toJSON().data
      : undefined,
    headerImageAspectRatio: receiptDesign.headerImageAspectRatio ?? 1.0,
  }
}
