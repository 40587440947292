import React, { useMemo } from "react"

import { PressableOpacity } from "../button/PressableOpacity"
import { Row } from "../layout/FlexBox"
import { Label } from "../typography/Label"

export type BreadCrumbItem = { label: string; onPress?: () => void }
type BreadcrumbsProps = { items: BreadCrumbItem[] }

export function Breadcrumbs({ items }: BreadcrumbsProps) {
  const elements = useMemo(() => {
    const elements = []

    for (let index = 0; index < items.length; index++) {
      const { label, onPress } = items[index]

      if (index > 0) elements.push(<Label key={`${index}/`} text=" / " />)

      elements.push(
        <PressableOpacity
          key={`breadCrumbItem_${index}`}
          disabled={onPress == undefined}
          onPress={onPress}
        >
          <Label
            text={label}
            color={index == items.length - 1 ? "link" : undefined}
          />
        </PressableOpacity>,
      )
    }

    return elements
  }, [items])

  return <Row>{elements}</Row>
}
