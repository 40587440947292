import { setDefaultOptions } from "date-fns"
import { registerLocale } from "i18n-iso-countries"
import i18next, { FlatNamespace, KeyPrefix, Resource } from "i18next"
import { useEffect, useState } from "react"
import {
  FallbackNs,
  initReactI18next,
  useTranslation,
  UseTranslationOptions,
} from "react-i18next"
import { $Tuple as Tuple } from "react-i18next/helpers"
import { AppState, Platform } from "react-native"

import { useAsyncEffect } from "@axtesys/hooks"

import {
  DEFAULT_LANGUAGE_CODE,
  DEFAULT_NAMESPACE,
  SUPPORTED_LANGUAGE_CODES,
  SUPPORTED_LOCALES,
} from "./constants"
import { retrieveLanguageCode } from "./languageCode"

// Translation setup logic

// Aggregated setup hook to fully initialise and
// configure all relevant parts for the translation service.
export function useSetupTranslationService(resources?: Resource) {
  const isInitialised = useInitialiseTranslations(resources)

  useRegisterTranslationChangeListeners()
  useDevTranslationChangeEffect(resources)

  return isInitialised
}

// Effect hook that initialises the i18next translation service,
// registers locales for i18n-iso-countries and configures date-fns.
// The return value of this hook states,
// whether the initialisation process has been completed or not.
// This process/effect will only ever execute once
// per application start (as per i18next definition).
function useInitialiseTranslations(resources?: Resource) {
  const [initialised, setInitialised] = useState(i18next.isInitialized)

  useAsyncEffect(async () => {
    if (i18next.isInitialized || i18next.isInitializing) return

    const languageCode = retrieveLanguageCode()

    // Calling the init function loads all passed resources and
    // configures i18next with the specified parameters.
    await i18next.use(initReactI18next).init({
      resources,
      ns: [],
      debug: __DEV__,
      load: "languageOnly",

      // Prevents the following error message on native side:
      // i18next::pluralResolver:
      // Your environment seems not to be Intl API compatible,
      // use an Intl.PluralRules polyfill.
      // Will fallback to the compatibilityJSON v3 format handling.
      compatibilityJSON: "v3",

      lng: languageCode,
      returnEmptyString: false,
      defaultNS: DEFAULT_NAMESPACE,
      nonExplicitSupportedLngs: true,
      fallbackLng: DEFAULT_LANGUAGE_CODE,

      // React already escapes strings by default
      interpolation: { escapeValue: false },
    })

    // These registerLocale call(s) must be added by hand and
    // should be in sync with the SUPPORTED_LANGUAGE_CODES array.
    registerLocale(require("i18n-iso-countries/langs/de.json"))
    // registerLocale(require("i18n-iso-countries/langs/en.json"))

    updateDateFnsDefaultOptions(languageCode)
    setInitialised(true)
  }, [])

  return initialised
}

// Effect hook that registers language change listeners
// for respective platforms (either web or native).
function useRegisterTranslationChangeListeners() {
  useEffect(() => {
    const handleLanguageChange = async () => {
      const languageCode = retrieveLanguageCode()

      if (i18next.language == languageCode) return

      await i18next.changeLanguage(languageCode)
      updateDateFnsDefaultOptions(languageCode)
    }

    // For web-backed applications, a reload would be required
    // in order for language-related changes to take effect.
    // Therefore, listen to the browser's languagechange events
    // and update all required package configurations accordingly.
    if (Platform.OS == "web") {
      const event = "languagechange"
      window.addEventListener(event, handleLanguageChange)
      return () => window.removeEventListener(event, handleLanguageChange)
    }

    // Typically apps (on both iOS/iPadOS and Android)
    // restart/re-initialise after a language change.
    // However, to be extra cautious and fail-proof:
    // Check whether the language changed
    // after the application becomes active again.
    const subscription = AppState.addEventListener("change", async state => {
      if (state != "active") return
      await handleLanguageChange()
    })

    return () => subscription.remove()
  }, [])
}

// Effect hook that (re-)loads changed resource bundles
// so that the UI always shows up-to-date translations.
// This is required in order for translations
// to be properly loaded for the respective application.
// Without this, switching applications via the DevSwitcher or
// updating translation files via hot reload will not result
// in an update to translations presented on the UI.
function useDevTranslationChangeEffect(resources?: Resource) {
  useAsyncEffect(async () => {
    if (!__DEV__) return

    for (const languageCode of SUPPORTED_LANGUAGE_CODES) {
      const namespaces = resources?.[languageCode]
      const namespaceKeys = namespaces ? Object.keys(namespaces) : []

      for (const namespaceKey of namespaceKeys) {
        i18next.addResourceBundle(
          languageCode,
          namespaceKey,
          resources?.[languageCode]?.[namespaceKey],
          false,
          true,
        )
      }
    }

    await i18next.changeLanguage(i18next.language)
  }, [resources])
}

// Sets the default date-fns configuration
// (currently used in connection with the
//  DateTimePickerProvider [web] / LocalizationProvider).
function updateDateFnsDefaultOptions(languageCode: string) {
  setDefaultOptions({
    weekStartsOn: 1,
    locale: SUPPORTED_LOCALES?.[languageCode] ?? SUPPORTED_LOCALES.de,
  })
}

// Translation infrastructure logic

export function useDialogsTranslation() {
  return { tDialogs: useTFunction("dialogs") }
}

export function useEnumsTranslation() {
  return { tEnums: useTFunction("enums") }
}

export function useGeneralTranslation() {
  return { tGeneral: useTFunction("general") }
}

export function useReportsTranslation() {
  return { tReports: useTFunction("reports") }
}

export function useValidationsTranslation() {
  return { tValidations: useTFunction("validations") }
}

export function useErrorsTranslation() {
  return { tErrors: useTFunction("errors") }
}

export function useNavigationTranslation() {
  return { tNavigation: useTFunction("navigation") }
}

export function useScreensTranslation<KPrefix extends KeyPrefix<"screens">>(
  keyPrefix?: KPrefix,
) {
  return { tScreen: useTFunction("screens", { keyPrefix }) }
}

export function useTranslations<KPrefix extends KeyPrefix<"screens">>(
  keyPrefix?: KPrefix,
) {
  return {
    ...useDialogsTranslation(),
    ...useEnumsTranslation(),
    ...useGeneralTranslation(),
    ...useReportsTranslation(),
    ...useValidationsTranslation(),
    ...useErrorsTranslation(),
    ...useNavigationTranslation(),
    ...useScreensTranslation(keyPrefix),
  }
}

function useTFunction<
  Ns extends FlatNamespace | Tuple<FlatNamespace> | undefined = undefined,
  KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined,
>(ns?: Ns, options?: UseTranslationOptions<KPrefix>) {
  return useTranslation(ns, options).t
}
