import {
  addDays,
  differenceInDays,
  endOfDay,
  endOfYesterday,
  isAfter,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  startOfYesterday,
} from "date-fns"
import React, { useCallback, useEffect, useState } from "react"

import { useSubsequentEffect } from "@axtesys/hooks"
import {
  formatDate,
  formatDateTime,
  formatIsoDate,
  formatIsoDateTime,
  interpolateString,
  validateDate,
} from "@axtesys/react-tools"

import { ToolTipIcon } from "../display/ToolTipIcon"
import { useDateTimeRangeEffect } from "../hooks/useDateTimeRangeEffect"
import { Row } from "../layout/FlexBox"
import { useKassenAppUI } from "../providers/KassenAppUIProvider"
import {
  DateQuickSelection,
  DateRange,
  DateRangeType,
  DateTimeMode,
} from "../types"
import { Label } from "../typography/Label"
import { DateTimeInput } from "./field/DateTimeInput"
import { SingleDropdownInput } from "./field/DropdownInput"

export type DateTimeRangeFormProps = {
  mode: DateTimeMode
  value: DateRangeType
  onChange: (dateRange: DateRangeType) => void

  disabled?: boolean
  disableFuture?: boolean
  selectionLimit?: number
  disableFutureUntil?: Date
  hideSelectionLimitBubble?: boolean
  excludeFromQuickSelection?: DateQuickSelection[]
  onSelectionViolationChange?: (state: boolean) => void
}

export function DateTimeRangeForm(props: DateTimeRangeFormProps) {
  const {
    mode,
    value,
    disabled,
    selectionLimit,
    disableFutureUntil,
    hideSelectionLimitBubble,
    onChange,
    onSelectionViolationChange,
  } = props

  const showQuickSelection = "mode" in value
  const shouldDisableFutureUntil = disableFutureUntil
    ? (date: Date) => isAfter(date, disableFutureUntil)
    : undefined
  const disableFuture = !shouldDisableFutureUntil
    ? props.disableFuture ?? true
    : false

  const { translations } = useKassenAppUI()
  const { endDate, startDate, setEndDate, setStartDate } =
    useDateTimeRangeEffect(value.range)
  const computeSelectionMode = useComputeSelectionMode(mode)
  const [selectionViolation, setSelectionViolation] = useState(false)
  const [endDateError, setEndDateError] = useState<string | undefined>(
    undefined,
  )
  const [startDateError, setStartDateError] = useState<string | undefined>(
    undefined,
  )
  const [quickSelection, setQuickSelection] = useState<DateQuickSelection>(
    "mode" in value ? value.mode : "Today",
  )

  // Validation: Check if the entered timespan is valid.
  // 1. Check for general date format validity.
  // 2. Verify whether a possible selectionLimit constraint passes.
  // 3. Verify that the start is before the end date.
  useEffect(() => {
    const validStartDate = validateDate(mode, startDate)
    const validEndDate = validateDate(mode, endDate)

    if (validStartDate) setStartDateError(undefined)
    else setStartDateError(translations.validationDate)

    if (validEndDate) setEndDateError(undefined)
    else setEndDateError(translations.validationDate)

    if (!validStartDate || !validEndDate) return

    if (
      selectionLimit &&
      differenceInDays(addDays(endDate, 1), startDate) > selectionLimit
    ) {
      setSelectionViolation(true)
      onSelectionViolationChange?.(true)
      setStartDateError(translations.validationDate)
      setEndDateError(translations.validationDateRange)
      return
    } else {
      setSelectionViolation(false)
      onSelectionViolationChange?.(false)
      setStartDateError(undefined)
      setEndDateError(undefined)
    }

    if (startDate > endDate) {
      setEndDateError(translations.validationDateRange)
      return
    }

    const range: DateRange =
      mode == "date"
        ? [new Date(formatIsoDate(startDate)), new Date(formatIsoDate(endDate))]
        : [
            new Date(formatIsoDateTime(startDate)),
            new Date(formatIsoDateTime(endDate)),
          ]

    onChange(showQuickSelection ? { range, mode: quickSelection } : { range })
  }, [
    endDate,
    mode,
    onChange,
    onSelectionViolationChange,
    quickSelection,
    selectionLimit,
    showQuickSelection,
    startDate,
    translations.validationDate,
    translations.validationDateRange,
  ])

  // Responsible for setting the correct timespan,
  // when using the quick selection feature.
  //
  // Special case:
  // 'Custom' - in this case a user defined timespan is requested.
  // Therefore, do not update the start and end date (return),
  // as it was already changed
  // by the corresponding DateTimeInput onChange handler.
  useSubsequentEffect(() => {
    if (!showQuickSelection) return

    let end: Date
    let start: Date
    const now = Date.now()

    switch (quickSelection) {
      case "Today":
        start = startOfDay(now)
        end = endOfDay(now)
        break
      case "Yesterday":
        start = startOfYesterday()
        end = endOfYesterday()
        break
      case "ThisWeek":
        start = startOfWeek(now)
        end = endOfDay(now)
        break
      case "ThisMonth":
        start = startOfMonth(now)
        end = endOfDay(now)
        break
      case "ThisYear":
        start = startOfYear(now)
        end = endOfDay(now)
        break
      default:
        return
    }

    setStartDate(start)
    setEndDate(end)
  }, [quickSelection, showQuickSelection])

  // In case the start and end date is a custom / user defined selection,
  // set the quick selection mode to 'Custom'
  // (only if the quick selection is displayed).
  // Otherwise, do not update the selection mode again,
  // as it would lead to more side effect
  // executions as actually necessary.
  useEffect(() => {
    if (!showQuickSelection) return
    setQuickSelection(computeSelectionMode(startDate, endDate))
  }, [startDate, endDate, showQuickSelection, computeSelectionMode])

  const onChangeStart = (date?: Date) => date && setStartDate(date)
  const onChangeEnd = (date?: Date) => date && setEndDate(date)

  return (
    <Row alignCenter gap="XS">
      {showQuickSelection && (
        <QuickSelectionInput
          {...props}
          quickSelection={quickSelection}
          onChange={setQuickSelection}
        />
      )}

      <Row alignCenter gap="XXS">
        <Label medium text={translations.from} />
        <DateTimeInput
          transparent
          type={mode}
          value={startDate}
          disabled={disabled}
          errorMessage={startDateError}
          disableFuture={disableFuture}
          shouldDisableSelection={shouldDisableFutureUntil}
          onChange={onChangeStart}
        />
      </Row>

      <Row alignCenter gap="XXS">
        <Label medium text={translations.to} />
        <DateTimeInput
          transparent
          type={mode}
          value={endDate}
          disabled={disabled}
          errorMessage={endDateError}
          disableFuture={disableFuture}
          shouldDisableSelection={shouldDisableFutureUntil}
          onChange={onChangeEnd}
        />
      </Row>

      {hideSelectionLimitBubble != true && selectionLimit && (
        <ToolTipIcon
          color={selectionViolation ? "error" : undefined}
          icon={selectionViolation ? "alert-circle" : undefined}
          text={interpolateString(translations.selectionLimit, selectionLimit)}
        />
      )}
    </Row>
  )
}

type QuickSelectionInputProps = Omit<DateTimeRangeFormProps, "onChange"> & {
  quickSelection: DateQuickSelection
  onChange: (quickSelection: DateQuickSelection) => void
}

function QuickSelectionInput({
  disabled,
  quickSelection,
  selectionLimit,
  excludeFromQuickSelection,
  onChange,
}: QuickSelectionInputProps) {
  const { durations } = useKassenAppUI().translations

  let excludedSelectionValues = excludeFromQuickSelection
    ? [...excludeFromQuickSelection, "Custom"]
    : ["Custom"]

  if (selectionLimit != undefined) {
    if (selectionLimit < 1)
      throw Error("Selection limit must be greater or equal to 1")

    if (selectionLimit < 2)
      excludedSelectionValues = [...excludedSelectionValues, "Yesterday"]

    if (selectionLimit < 7)
      excludedSelectionValues = [...excludedSelectionValues, "ThisWeek"]

    if (selectionLimit < 31)
      excludedSelectionValues = [...excludedSelectionValues, "ThisMonth"]

    if (selectionLimit < 366)
      excludedSelectionValues = [...excludedSelectionValues, "ThisYear"]
  }

  return (
    <SingleDropdownInput
      transparent
      fontSize="medium"
      disabled={disabled}
      style={{ width: 85 }}
      value={quickSelection}
      displayKeyValuePairs={durations}
      excludedValues={excludedSelectionValues}
      onChange={onChange}
    />
  )
}

export function useComputeSelectionMode(mode: DateTimeMode) {
  return useCallback(
    (startDate: Date, endDate: Date): DateQuickSelection => {
      const now = Date.now()

      const formatToIso = (date: Date) =>
        mode == "date" ? formatDate(date) : formatDateTime(date)

      const end = formatToIso(endDate)
      const start = formatToIso(startDate)
      const endOfToday = formatToIso(endOfDay(now))

      if (start == formatToIso(startOfDay(now)) && end == endOfToday) {
        return "Today"
      } else if (
        start == formatToIso(startOfYesterday()) &&
        end == formatToIso(endOfYesterday())
      ) {
        return "Yesterday"
      } else if (start == formatToIso(startOfWeek(now)) && end == endOfToday) {
        return "ThisWeek"
      } else if (start == formatToIso(startOfMonth(now)) && end == endOfToday) {
        return "ThisMonth"
      } else if (start == formatToIso(startOfYear(now)) && end == endOfToday) {
        return "ThisYear"
      } else return "Custom"
    },
    [mode],
  )
}
