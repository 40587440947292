import { useCallback, useEffect, useState } from "react"

import { createCustomContext } from "@axtesys/react-tools"
import { addEventListener } from "@react-native-community/netinfo"
import { onlineManager } from "@tanstack/react-query"

export const [NetworkStatusProvider, useNetworkStatus] = createCustomContext(
  () => {
    // Flag that states whether the activity ping was successful or not.
    // Indicating if the server is reachable.
    const [serverReachability, setServerReachability] = useState(true)

    // Flag that states whether there is
    // an active network connection present or not.
    const [networkConnectivity, setNetworkConnectivity] = useState(true)

    // Only if both flags evaluate to true,
    // it means that the application has a proper working network state.
    const isNetworkConnectionAvailable =
      serverReachability && networkConnectivity

    // Subscription management effect hook
    // to listen to changes of the network adapter.
    useEffect(() => {
      const unsubscribe = addEventListener(({ isConnected }) =>
        // If isConnected evaluates to undefined,
        // we cannot be sure that there is an actual connection present.
        // Therefore, set the connectivity flag to false.
        //
        // NOTE: If this somehow leads to problems on certain devices/platforms,
        // switch to an optimistic approach and change the default value to true.
        setNetworkConnectivity(isConnected ?? false),
      )

      return unsubscribe
    }, [])

    // Effect hook to manage react-query's internal online state.
    useEffect(
      () => onlineManager.setOnline(isNetworkConnectionAvailable),
      [isNetworkConnectionAvailable],
    )

    const switchToReachable = useCallback(() => setServerReachability(true), [])
    const switchToUnreachable = useCallback(
      () => setServerReachability(false),
      [],
    )

    return {
      switchToReachable,
      switchToUnreachable,

      connected: isNetworkConnectionAvailable,
      info: { serverReachability, networkConnectivity },
    }
  },
)
