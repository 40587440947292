import React from "react"
import MaterialCommunityIcon from "react-native-vector-icons/MaterialCommunityIcons"

import { useColor } from "../hooks/useColor"
import { Box } from "../layout/Box"
import { Color } from "../types"
import { MCIcon } from "./MCIcon"

type PaddingSpacing = "XS" | "XXS" | "XXXS" | "XXXXS"
export type IconSize = "XS" | "SX" | "S" | "SM" | "M" | "L" | "XL"

type IconProps = {
  name: MCIcon

  color?: Color
  size?: IconSize
  disabled?: boolean
  disabledColor?: Color

  pad?: PaddingSpacing
  padVertical?: PaddingSpacing
  padHorizontal?: PaddingSpacing

  onPress?: () => void
}

const ICON_SPACING = { XS: 16, SX: 20, S: 24, SM: 28, M: 32, L: 38, XL: 48 }

export function Icon(props: IconProps) {
  const color = useColor(
    !props.disabled
      ? props.color ?? "default"
      : props.disabledColor ?? "disabled",
  )

  const icon = (
    <MaterialCommunityIcon
      color={color}
      name={props.name}
      size={ICON_SPACING[props.size ?? "S"]}
    />
  )

  const { pad, disabled, padVertical, padHorizontal, onPress } = props
  const isRenderBox = pad || disabled || padVertical || padHorizontal || onPress

  return isRenderBox ? (
    <Box
      pad={pad}
      disabled={disabled}
      padVertical={padVertical}
      padHorizontal={padHorizontal}
      onPress={onPress}
    >
      {icon}
    </Box>
  ) : (
    icon
  )
}
