import { clamp, floor } from "lodash"
import React, { ReactNode, useState } from "react"
import { LayoutChangeEvent, View } from "react-native"

import { Box, useSpacing } from "../layout/Box"
import { Column, ColumnProps, Row } from "../layout/FlexBox"
import { ResponsiveSpacing } from "../types"

type GridSpecificProps = ColumnProps & {
  children: ReactNode[]
  alignRowsCenter?: boolean
  verticalGap?: ResponsiveSpacing
  horizontalGap?: ResponsiveSpacing
}

export type GridProps = GridSpecificProps &
  ({ numCols: number; flexCols?: number[] } | { colWidth: number })

// Lays out children in a grid, filling rows first.
// Children are expected to be the same height/width.
export function Grid(props: GridProps) {
  return "numCols" in props ? (
    <GridWithNumCols {...props}>{props.children}</GridWithNumCols>
  ) : (
    <GridWithCalculatedNumCols {...props}>
      {props.children}
    </GridWithCalculatedNumCols>
  )
}

function GridWithNumCols(
  props: GridSpecificProps & {
    numCols: number
    flexCols?: number[]
  },
) {
  const {
    gap,
    numCols,
    flexCols,
    verticalGap,
    horizontalGap,
    alignRowsCenter,
    ...columnProps
  } = props

  // Prevent division through zero
  // when 0 was passed as a value for numCols.
  if (numCols <= 0) return null

  const children = props.children.filter(child => child != null)

  const rows: ReactNode[] = []
  const numRows = Math.ceil(children.length / numCols)
  for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
    const childrenPerRow: ReactNode[] = []

    for (let colIndex = 0; colIndex < numCols; colIndex++) {
      const cell = children[rowIndex * numCols + colIndex]

      childrenPerRow.push(
        <Box
          expand
          key={`gridCell_${colIndex}`}
          flex={flexCols && flexCols.length > colIndex ? flexCols[colIndex] : 1}
        >
          {cell}
        </Box>,
      )
    }

    rows.push(
      <Row
        gap={horizontalGap ?? gap}
        key={`gridRow_${rowIndex}`}
        alignCenter={alignRowsCenter}
      >
        {childrenPerRow}
      </Row>,
    )
  }

  return (
    <Column {...columnProps} gap={verticalGap ?? gap}>
      {rows}
    </Column>
  )
}

function GridWithCalculatedNumCols(props: GridProps) {
  const [numCols, setNumCols] = useState(0)

  if (!("colWidth" in props) || props.colWidth <= 0) return null

  const onLayoutChange = ({ nativeEvent }: LayoutChangeEvent) =>
    setNumCols(
      clamp(
        floor(nativeEvent.layout.width / props.colWidth),
        1,
        Number.MAX_VALUE,
      ),
    )

  return (
    <View style={{ flexShrink: 1 }} onLayout={onLayoutChange}>
      <GridWithNumCols {...props} numCols={numCols}>
        {props.children}
      </GridWithNumCols>
    </View>
  )
}

type WebGridProps = {
  colWidth: number
  children: ReactNode[]
  gap: ResponsiveSpacing
}

// WebGrid is available on Web only and uses CSS grid
// to vastly simplify layouting.
export function WebGrid({ gap, children, colWidth }: WebGridProps) {
  return (
    <div
      style={{
        display: "grid",
        gridGap: useSpacing(gap),
        gridTemplateColumns: `repeat( auto-fit, minmax(${colWidth}px, 1fr) )`,
      }}
    >
      {children}
    </div>
  )
}
