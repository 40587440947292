import React, { Children, Fragment, ReactNode } from "react"
import { ScrollView, ScrollViewProps, StyleProp, ViewStyle } from "react-native"

import { ResponsiveSpacing } from "../types"
import { Box, BoxProps, useBoxStyle } from "./Box"
import { Gap } from "./Gap"

type FlexDirectionProps = {
  flexDirection: "row" | "column" | "row-reverse" | "column-reverse"
}

type ExtendedBoxProps = BoxProps &
  Pick<
    ScrollViewProps,
    "showsVerticalScrollIndicator" | "showsHorizontalScrollIndicator"
  >

type FlexBoxProps = ExtendedBoxProps & {
  // Enables scrolling within the container along its main axis.
  scrollable?: boolean

  // Inserts equal spaces / gaps along the container's main axis
  // that use the available space as a reference
  // (between, in front and at the end of the passed elements;
  //  when 'spaceBetween' is set to 'true',
  //  setting this property will have no effect).
  spaceEvenly?: boolean

  // Inserts equal spaces / gaps along the container's main axis
  // that use the available space as a reference (between passed elements).
  spaceBetween?: boolean

  // Adds a gap between passed children elements.
  gap?: ResponsiveSpacing
}

export type RowProps = Omit<FlexBoxProps, "showsVerticalScrollIndicator">
export type ColumnProps = Omit<FlexBoxProps, "showsHorizontalScrollIndicator">

export function Row(props: RowProps) {
  return <FlexBox {...props} flexDirection="row" />
}

export function Column(props: ColumnProps) {
  return <FlexBox {...props} flexDirection="column" />
}

function FlexBox({
  gap,
  style,
  children,
  scrollable,
  spaceEvenly,
  spaceBetween,
  flexDirection,
  ...props
}: FlexBoxProps & FlexDirectionProps) {
  const boxStyle: StyleProp<ViewStyle> = [
    {
      flexDirection,
      justifyContent: spaceBetween
        ? "space-between"
        : spaceEvenly
        ? "space-evenly"
        : undefined,
    },
    style,
  ]

  const content = insertGaps(
    children,
    gap && (
      <Gap
        horizontal={flexDirection == "row" ? gap : undefined}
        vertical={flexDirection == "column" ? gap : undefined}
      />
    ),
  )

  if (scrollable)
    return (
      <ScrollableBox {...props} style={boxStyle} flexDirection={flexDirection}>
        {content}
      </ScrollableBox>
    )

  return (
    <Box {...props} style={boxStyle}>
      {content}
    </Box>
  )
}

function ScrollableBox({
  style,
  children,
  showsVerticalScrollIndicator,
  showsHorizontalScrollIndicator,
  ...props
}: ExtendedBoxProps & FlexDirectionProps) {
  const isRow = props.flexDirection == "row"

  return (
    <ScrollView
      horizontal={isRow}
      keyboardShouldPersistTaps="handled"
      style={[useBoxStyle(props), style]}
      showsVerticalScrollIndicator={showsVerticalScrollIndicator}
      showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
      contentContainerStyle={!isRow ? { paddingBottom: 24 } : undefined}
    >
      {children}
    </ScrollView>
  )
}

function insertGaps(children: ReactNode, gap?: ReactNode): ReactNode {
  if (!gap) return children

  const elements: ReactNode[] = []
  const childrenArray = Children.toArray(children)
  for (let i = 0; i < childrenArray.length; i++) {
    // Do not insert a gap in front of the first container element.
    if (i != 0) elements.push(<Fragment key={`${i}_`}>{gap}</Fragment>)

    elements.push(<Fragment key={`${i}`}>{childrenArray[i]}</Fragment>)
  }

  return elements
}
