import React from "react"
import { TextInput, TextInputProps } from "react-native"

import { isEmptyOrBlank } from "@axtesys/react-tools"

import { Icon } from "../display/Icon"
import { Row } from "../layout/FlexBox"
import { useTheme } from "../theme"

export type MobileSearchBarProps = Pick<
  TextInputProps,
  "autoFocus" | "placeholder"
> & {
  value: string
  onFocus?: () => void
  onClose?: () => void
  onChange?: (value: string) => void
  onSubmit?: (value: string) => void
}

const HEIGHT = 48

export function MobileSearchBar(props: MobileSearchBarProps) {
  const { value, onFocus, onClose, onChange, onSubmit, ...inputProps } = props

  const { color } = useTheme()

  const leftIcon = onClose ? (
    <Icon pad="XXXS" name="arrow-left" onPress={onClose} />
  ) : (
    <Icon pad="XXXS" name="magnify" />
  )

  const onSubmitEditing = () => onSubmit?.(value)
  const field = (
    <TextInput
      {...inputProps}
      value={value}
      returnKeyType="search"
      placeholderTextColor={color.base4}
      underlineColorAndroid={color.background}
      style={{ flex: 1, paddingLeft: 3, backgroundColor: color.background }}
      onFocus={onFocus}
      onChangeText={onChange}
      onSubmitEditing={onSubmitEditing}
    />
  )

  const rightIcon = !isEmptyOrBlank(value) && (
    <Icon
      pad="XXXS"
      name="close"
      onPress={() => {
        onChange?.("")
        onSubmit?.("")
      }}
    />
  )

  return (
    <Row
      expand
      alignCenter
      backgroundColor={color.background}
      style={{ minHeight: HEIGHT, maxHeight: HEIGHT }}
    >
      {leftIcon}
      {field}
      {rightIcon}
    </Row>
  )
}
