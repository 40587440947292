import {
  FormData,
  useForm as useLibForm,
  UseFormProps,
  UseFormValue,
} from "@axtesys/hooks"

import { useValidationsTranslation } from "../feature/Translation/hooks"

export function useForm<T extends FormData>(
  props: UseFormProps<T>,
): UseFormValue<T> {
  return useLibForm({
    ...props,
    defaultValidationErrorMessage:
      useValidationsTranslation().tValidations("validationEmpty"),
  })
}
