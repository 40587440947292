import React from "react"

import { Column } from "../../layout/FlexBox"
import { ResponsiveSpacing } from "../../types"
import { InputLabel, InputLabelProps } from "../../typography/InputLabel"
import { ErrorField, ErrorFieldProps } from "./ErrorField"

export type FormFieldProps = InputLabelProps &
  ErrorFieldProps & {
    gap?: ResponsiveSpacing
    noErrorMessage?: boolean
    onPress?: () => void
  }

// A top labeled form input / field component.
export function FormField({
  gap,
  children,
  errorMessage,
  noErrorMessage,
  onPress,
  ...labelProps
}: FormFieldProps) {
  const label = labelProps.label && <InputLabel {...labelProps} />

  const input =
    // showErrorMessage
    noErrorMessage != true ? (
      <ErrorField errorMessage={errorMessage}>{children}</ErrorField>
    ) : (
      children
    )

  return (
    <Column gap={gap ?? "XXXXS"} onPress={onPress}>
      {label}
      {input}
    </Column>
  )
}
