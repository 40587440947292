import { DeviceType, getDeviceTypeAsync } from "expo-device"
import { createContext, createElement, ReactNode, useContext } from "react"
import { Dimensions, PixelRatio, Platform } from "react-native"

import { LoadingIndicator } from "@axtesys/kassen-app-ui"

import { useAsyncValue } from "../state/useAsyncValue"

type DeviceInfo = { deviceType: "desktop" | "tablet" | "phone" }

export { DeviceInfoProvider, useDeviceInfo }

// Returns information about the device the application is running on.
// Requires the DeviceInfoProvider to be mounted before the hook is used.
function useDeviceInfo(): DeviceInfo {
  return useContext(deviceInfoContext)
}

function DeviceInfoProvider({ children }: { children: ReactNode }) {
  const deviceType = useAsyncValue(getDeviceTypeAsync, [])

  if (deviceType == null) return createElement(LoadingIndicator)

  return createElement(
    deviceInfoContext.Provider,
    { value: { deviceType: mapDeviceType(deviceType) } },
    children,
  )
}

function defaultDeviceType() {
  return Platform.OS == "android" || Platform.OS == "ios" ? "phone" : "desktop"
}

// Some tablets are misinterpreted by 'expo-device'.
//
// Workaround:
// For iOS: Use the standard 'expo-device'
//          implementation as it seems to work properly.
// For Android: Use the pixel density in combination with
//              the screen dimensions of the device to retrieve the device type.
// https://stackoverflow.com/questions/44562769/react-native-check-if-tablet-or-screen-in-inches
function mapDeviceType(deviceType: DeviceType): "desktop" | "tablet" | "phone" {
  if (Platform.OS == "android") {
    const pixelDensity = PixelRatio.get()
    const { width, height } = Dimensions.get("screen")

    const adjustedWidth = width * pixelDensity
    const adjustedHeight = height * pixelDensity

    if (pixelDensity < 2 && (adjustedWidth >= 1000 || adjustedHeight >= 1000)) {
      return "tablet"
    } else if (
      pixelDensity == 2 &&
      (adjustedWidth >= 1920 || adjustedHeight >= 1920)
    ) {
      return "tablet"
    } else return "phone"
  }

  switch (deviceType) {
    case DeviceType.DESKTOP:
      return "desktop"
    case DeviceType.TABLET:
      return "tablet"
    case DeviceType.PHONE:
      return "phone"
    default:
      return defaultDeviceType()
  }
}

const deviceInfoContext = createContext<DeviceInfo>({
  deviceType: defaultDeviceType(),
})
