import React, { ReactNode } from "react"

import { Column } from "../layout/FlexBox"
import { Spacer } from "../layout/Spacer"
import { Label } from "../typography/Label"

type FilterBoxProps = {
  title: string
  children: ReactNode
  zIndex?: number
}

export function FilterBox({ title, zIndex, children }: FilterBoxProps) {
  return (
    <Column gap="XXXS" style={{ zIndex }}>
      <Label bold medium text={title} />
      <Spacer />
      {children}
      <Spacer />
    </Column>
  )
}
