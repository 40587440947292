import { mapValues } from "lodash"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { ArticleGroupId } from "./ArticleGroup"
import {
  KeysSubGroupContent,
  KeysSubGroupId,
  SpecificKeysSubGroupContentEntity,
} from "./KeysSubGroup"
import { MainGroupId } from "./MainGroup"

export type KeysMainGroupId = string

export type KeysMainGroupContent = {
  keysMainGroupId: KeysMainGroupId
  name: string
  color: string
  orderIndex: number
  keysSubGroups: KeysSubGroupContent[]
}

type KeysMainGroupBasicInfo = Pick<KeysMainGroupContent, "name" | "color">

export type KeysMainGroupData = KeysMainGroupBasicInfo & {
  mainGroupId: MainGroupId | undefined
  articleGroupId: ArticleGroupId | undefined
}

export type KeysMainGroupDataWithId = KeysMainGroupBasicInfo &
  Pick<KeysMainGroupContent, "keysMainGroupId">

export type SpecificKeysMainGroupContent = Omit<
  KeysMainGroupContent,
  "keysSubGroups"
> & { keysSubGroups: Record<KeysSubGroupId, KeysSubGroupContent> }

export const SpecificKeysMainGroupContentEntity =
  createEntityFor<SpecificKeysMainGroupContent>()
    .withSchema(
      z.object({
        keysMainGroupId: z.string(),
        name: z.string(),
        color: z.string(),
        orderIndex: z.number(),
        keysSubGroups: z.record(SpecificKeysSubGroupContentEntity.schema),
      }),
    )
    .serialize(data => ({
      ...data,
      keysSubGroups: mapValues(
        data.keysSubGroups,
        SpecificKeysSubGroupContentEntity.serialize,
      ),
    }))
    .deserialize(json => ({
      ...json,
      keysSubGroups: mapValues(
        json.keysSubGroups,
        SpecificKeysSubGroupContentEntity.deserialize,
      ),
    }))
