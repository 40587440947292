import React, { ReactElement, ReactNode, useState } from "react"

import { Box } from "@axtesys/kassen-app-ui"
import { createCustomContext } from "@axtesys/react-tools"

export { AbsoluteAccessorProvider, useAbsoluteAccessor }

export type AbsoluteAccessorConfig = {
  element: ReactElement | null

  top?: number
  left?: number
  right?: number
  bottom?: number
}

function AbsoluteAccessorProvider({ children }: { children: ReactNode }) {
  return (
    <AbsoluteAccessorContextProvider>
      {children}
      <AbsoluteAccessOverlay />
    </AbsoluteAccessorContextProvider>
  )
}

function AbsoluteAccessOverlay() {
  const { currentConfig } = useAbsoluteAccessorInternal()

  if (!currentConfig || !currentConfig.element) return null

  const { top, left, right, bottom, element } = currentConfig

  return (
    <Box style={{ top, left, right, bottom, position: "absolute" }}>
      {element}
    </Box>
  )
}

function useAbsoluteAccessor() {
  const { setCurrentConfig, resetCurrentConfig } = useAbsoluteAccessorInternal()

  return {
    showAbsoluteAccessor: setCurrentConfig,
    resetAbsoluteAccessor: resetCurrentConfig,
  }
}

const [AbsoluteAccessorContextProvider, useAbsoluteAccessorInternal] =
  createCustomContext(() => {
    const [currentConfig, setCurrentConfig] =
      useState<AbsoluteAccessorConfig | null>(null)

    const resetCurrentConfig = () => setCurrentConfig(null)

    return { currentConfig, setCurrentConfig, resetCurrentConfig }
  })
