import * as z from "zod"

import { jsonDbAtom } from "@axtesys/react-tools"

import { OfflineMutationRequest } from "./types"

export const offlineMutationsState = jsonDbAtom({
  key: "offlineMutations",
  default: [] as OfflineMutationRequest[],
  schema: z.array(
    z.object({
      operationName: z.string(),
      variables: z.any(),
      createdAt: z.string(),
      timeout: z.onumber(),

      // Should not be optional,
      // but still is for backward compatibility.
      commandId: z.ostring(),

      retryCount: z.onumber(),
    }),
  ),
  serialize: data => data,
  deserialize: json => json as OfflineMutationRequest[],
})

export const deadMutationsState = jsonDbAtom({
  key: "deadOfflineMutations",
  default: [] as OfflineMutationRequest[],
  schema: z.array(
    z.object({
      operationName: z.string(),
      variables: z.any(),
      createdAt: z.string(),
      retryCount: z.onumber(),
    }),
  ),
  serialize: data => data,
  deserialize: json => json as OfflineMutationRequest[],
})

export const failedDeadMutationsState = jsonDbAtom({
  key: "failedDeadMutations",
  default: [] as OfflineMutationRequest[],
  schema: z.array(
    z.object({
      operationName: z.string(),
      variables: z.any(),
      createdAt: z.string(),
    }),
  ),
  serialize: data => data,
  deserialize: json => json as OfflineMutationRequest[],
})
