import React, { useState } from "react"
import { LayoutChangeEvent, View } from "react-native"

import { useOnOff } from "@axtesys/hooks"

import { PressableOpacity } from "../../button/PressableOpacity"
import { KeyLabels, Menu } from "../../container/Menu"
import { useDisplayValue } from "../../hooks/useDisplayValue"
import { useReCalculationEffect } from "../../hooks/useReCalculationEffect"
import { calculatePosition } from "../../lib"
import { ScreenCoordinates } from "../../types"
import { TextInput, TextInputProps } from "./TextInput"

type DropdownInputProps = Omit<
  TextInputProps,
  "iconLeft" | "iconRight" | "mode" | "onChange"
> & {
  displayKeyValuePairs: KeyLabels
  excludedValues?: (keyof KeyLabels)[]
}

export type SingleDropdownInputProps = {
  onChange(value: keyof KeyLabels): void

  value?: keyof KeyLabels
} & DropdownInputProps

export type MultiDropdownInputProps = {
  category: string
  values: (keyof KeyLabels)[]
  onChange(values: (keyof KeyLabels)[]): void

  defaultKey?: keyof KeyLabels
} & DropdownInputProps

type DropdownInputInternalProps = {
  displayValue: string
  mode: "single" | "multi"
  onSelect(key: keyof KeyLabels): void

  values?: (keyof KeyLabels)[]
} & DropdownInputProps

export function SingleDropdownInput(props: SingleDropdownInputProps) {
  const { value, displayKeyValuePairs, onChange } = props

  return (
    <InternalDropdownInput
      mode="single"
      displayValue={displayKeyValuePairs[value ?? ""] ?? ""}
      onSelect={onChange}
      {...props}
    />
  )
}

export function MultiDropdownInput(props: MultiDropdownInputProps) {
  const { values, defaultKey = "default", onChange } = props

  const onSelectMulti = (key: keyof KeyLabels) => {
    if (!values.find(value => value == key)) {
      if (
        key == defaultKey ||
        (values.length == 1 && values[0] == defaultKey)
      ) {
        onChange([key])
      } else onChange([...values, key])
    } else if (values.length > 1) {
      onChange(values.filter(value => value != key))
    } else onChange([defaultKey])
  }

  return (
    <InternalDropdownInput
      mode="multi"
      displayValue={useDisplayValue(props)}
      onSelect={onSelectMulti}
      {...props}
    />
  )
}

function InternalDropdownInput(props: DropdownInputInternalProps) {
  const {
    mode,
    values,
    displayValue,
    excludedValues,
    displayKeyValuePairs,
    onSelect,
    ...textInputProps
  } = props
  const { disabled } = textInputProps

  // States whether the dropdown menu is shown or not.
  const [menuVisible, showMenu, hideMenu] = useOnOff(false)

  // Dimensions received from the `TextInput` component in order to:
  // Set the `minWidth` of the menu. This leads to a better fit /
  // display for a non-collapsed `Menu`.
  const [minWidth, setMinWidth] = useState<number | undefined>()

  // Required to adjust positioning
  // of dropdown when window dimensions change.
  const viewRef = React.createRef<View>()
  const [position, setPosition] = useState<ScreenCoordinates>({ x: 0, y: 0 })
  const calculateMenuPosition = () =>
    calculatePosition({ viewRef, setPosition, viewHeightAddition: true })

  useReCalculationEffect({
    dependency: menuVisible,
    executeIfNotVisible: hideMenu,
    calculate: calculateMenuPosition,
  })

  const selectOption = (key: string) => {
    onSelect(key)
    calculateMenuPosition()
    if (mode == "single") hideMenu()
  }

  const onLayout = ({ nativeEvent: { layout } }: LayoutChangeEvent) =>
    setMinWidth(layout.width)

  const interceptor = (
    <PressableOpacity onPress={!disabled ? showMenu : undefined}>
      <View ref={viewRef} pointerEvents="none">
        <TextInput
          {...textInputProps}
          editable={false}
          value={displayValue}
          iconRight={{ name: menuVisible ? "chevron-up" : "chevron-down" }}
          onChange={() => {}}
        />
      </View>
    </PressableOpacity>
  )

  const menu = (
    <Menu
      minWidth={minWidth}
      position={position}
      visible={menuVisible}
      selectedValues={values}
      excludedValues={excludedValues}
      displayKeyValuePairs={displayKeyValuePairs}
      onDismiss={hideMenu}
      onSelect={selectOption}
    />
  )

  return (
    <View pointerEvents={disabled ? "none" : undefined} onLayout={onLayout}>
      {interceptor}
      {!disabled && menu}
    </View>
  )
}
