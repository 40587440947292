import React, { useEffect, useState } from "react"

import { bigToEuros, Euros, eurosToBig } from "~shared/lib/Money"

import { Row } from "../layout/FlexBox"
import { useKassenAppUI } from "../providers/KassenAppUIProvider"
import { Label } from "../typography/Label"
import { ErrorField } from "./field/ErrorField"
import { MoneyInput } from "./field/MoneyInput"

type MoneyRangeFormProps = {
  initialRange: [Euros | undefined, Euros | undefined]
  onChange: (range: [Euros | undefined, Euros | undefined]) => void

  disabled?: boolean
}

const INPUT_WIDTH = 80

export function MoneyRangeForm(props: MoneyRangeFormProps) {
  const { disabled, initialRange, onChange } = props

  const { translations } = useKassenAppUI()
  const [end, setEnd] = useState(eurosToBig(initialRange[1]))
  const [start, setStart] = useState(eurosToBig(initialRange[0]))
  const [endError, setEndError] = useState<string | undefined>(undefined)

  const isInvalid = start && end && start.gt(end)

  const setValidationError = () => {
    if (!isInvalid) return
    setEndError(translations.validationPriceRange)
  }

  useEffect(() => {
    onChange([bigToEuros(start), bigToEuros(end)])
    if (isInvalid || !endError) return
    setEndError(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end])

  return (
    <Row alignCenter gap="XS">
      <Label medium text={translations.from} />
      <ErrorField>
        <MoneyInput
          transparent
          value={start}
          fontSize="medium"
          disabled={disabled}
          style={{ width: INPUT_WIDTH }}
          onChange={setStart}
          onSubmit={setValidationError}
        />
      </ErrorField>

      <Label medium text={translations.to} />
      <ErrorField errorMessage={endError}>
        <MoneyInput
          transparent
          value={end}
          fontSize="medium"
          disabled={disabled}
          errorMessage={endError}
          style={{ width: INPUT_WIDTH }}
          onChange={setEnd}
          onSubmit={setValidationError}
        />
      </ErrorField>
    </Row>
  )
}
