import Big from "big.js"
import { isEqual } from "lodash"
import { useCallback, useEffect, useRef, useState } from "react"

import { isEmptyOrBlank } from "@axtesys/react-tools"

import { useIsMounted } from "../info/useIsMounted"
import { FocusChainLink, useFocusChain } from "../util/useFocusChain"

type FormFieldProps<V> = {
  value: V
  onChange(value: V): void
  onSubmitIfValid(): Promise<boolean>

  label?: string
  subLabel?: string
  editable?: boolean
  mandatory?: boolean
  placeholder?: string
  errorMessage?: string
  focus?: FocusChainLink
}
type Fields<T> = keyof T
type FieldsAndSubmit<T> = Fields<T> | "submit"
type FormFieldValue = string | boolean | number | Big | undefined
export type FormData = Record<string, FormFieldValue>

type ValidationError = string
type FormErrorMessages<T> = Partial<
  Record<
    FieldsAndSubmit<T> /* key: 'field name' or 'submit' */,
    string | undefined /* value: 'error message' or 'undefined' */
  >
>

// Configuration passed to the useForm hook.
export type UseFormProps<T extends FormData> = {
  data: T

  // If this flag is set, all fields are read-only and cannot be manipulated.
  editable?: boolean

  // If this flag is set, the form will be validated on mount time.
  validateOnStart?: boolean

  // Labels for each field.
  label?: { [Field in Fields<T>]?: string }

  // Sub-labels (information or explanatory purpose) for each field.
  subLabel?: { [Field in Fields<T>]?: string }

  // Placeholders for each field.
  placeholder?: { [Field in Fields<T>]?: string }

  // Mandatory fields are required
  // to be filled out in a valid way before being able to submit.
  mandatory?: { [Field in Fields<T>]?: boolean | ((formData: T) => boolean) }

  // Validation rules for each field.
  // Validation must return true (if valid) or an error message.
  validate?: {
    [Field in Fields<T>]?: (
      fieldData: T[Field],
      formData: T,
    ) => ValidationError | true
  }

  // The submit action is only called with valid data.
  // If it throws an error, that error will be displayed with the submit element.
  submit: (data: T, form: UseFormValue<T>) => SubmitResult<T>
}

type InternalUseFormProps<T extends FormData> = UseFormProps<T> & {
  // In case there is no custom validation error handling,
  // the field is mandatory and is empty,
  // the following message will be presented.
  defaultValidationErrorMessage?: string
}

export type SubmitProps = {
  disabled: boolean
  errorMessage?: string
  onPress?: () => void | Promise<void>
}

type SubmitResult<T extends FormData> =
  | void
  | Promise<void>
  | { data?: Partial<T>; errors?: FormErrorMessages<T> }
  | Promise<{ data?: Partial<T>; errors?: FormErrorMessages<T> }>

// Values returned by the useForm hook.
export type UseFormValue<T extends FormData> = {
  // Current data of all form fields.
  data: T

  // The initial data passed to the form.
  initialData: T

  // Whether the data changed from its initial data.
  isDirty: boolean

  // If the form passes the validation check.
  // NOTE: Fields will not be validated (unless validateOnStart is true)
  // until the first onChange(...) or submit(...) call occurs.
  isValid: boolean

  // Props that can be passed to input elements.
  field: { [Field in Fields<T>]: FormFieldProps<T[Field]> }

  // Props that can be passed to a submit element.
  submit: SubmitProps

  // Reset to initialData.
  reset(): void

  // Sets error messages for particular fields or the submit element
  // (e.g. after a command failed due to some condition).
  // An error for a field will get cleared when it is updated,
  // or all errors will get cleared when the form is submitted again.
  setErrorMessages(errorMessages: FormErrorMessages<T>): void

  // Overwrites the values for the specified fields.
  // If `updateInitialData` is true (default),
  // the new data will be reflected in `initialData` as well.
  setData(data: Partial<T>, updateInitialData?: boolean): void
}

export function useForm<T extends FormData>(
  props: InternalUseFormProps<T>,
): UseFormValue<T> {
  // Used for focus and therefore navigation purposes.
  let linkIndex = 0

  // Flag required to make sure the component
  // is still mounted when setting submit errors.
  const isMounted = useIsMounted()

  // Activates the next input Element when
  // the user presses "next" in a field.
  const focusChain = useFocusChain()

  // The initial data passed when the form got created or
  // the data prop has been changed.
  const initialDataRef = useRef(props.data)

  // Represents the actual values displayed by the form
  // (changes on every input).
  const [formData, setFormData] = useState(props.data)

  // The error from the last call to submit(...), if any.
  const [submitError, setSubmitError] = useState<string | undefined>()

  // Indicates whether the form is in a submitting process or not.
  const safetySubmittingRef = useRef<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)

  // The error messages of each individual field or submit element.
  const [errorMessages, setErrorMessages] = useState<FormErrorMessages<T>>({})

  // Whether a field has already been touched or not.
  const [touched, setTouched] = useState<{ [Field in Fields<T>]?: boolean }>({})

  // On every change of data, check if it
  // has changed since the initialisation of the form.
  // If so update the form and its initial state to the newly passed values.
  useEffect(() => {
    if (!isEqual(props.data, initialDataRef.current)) {
      initialDataRef.current = props.data
      setFormData(props.data)
      setErrorMessages({})
    }
  }, [props.data])

  // In case the validateOnStart flag is
  // set, the form should be validated on mount time
  useEffect(() => {
    if (props.validateOnStart) setAllTouched(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setData = useCallback(
    (data: Partial<T>, updateInitialData: boolean = true) => {
      setFormData(formData => ({ ...formData, ...data }))
      if (updateInitialData)
        initialDataRef.current = { ...initialDataRef.current, ...data }
    },
    [],
  )

  const fieldIsMandatory = (field: Fields<T>): boolean => {
    const mandatory = props.mandatory?.[field]
    if (typeof mandatory == "boolean") return mandatory
    if (typeof mandatory == "function") return mandatory(formData)
    // undefined -> not mandatory
    return false
  }

  // Returns the validation error message for a given field,
  // or none if the validation passes.
  const validationError = (
    field: Fields<T>,
    forceTouch?: boolean,
  ): string | undefined => {
    // If we did not touch the field before, it is considered valid.
    if (!touched[field] && !forceTouch) return undefined

    const fieldData = formData[field]

    // In case the field is not mandatory, but empty or blank,
    // it should also be considered valid.
    const isMandatory = fieldIsMandatory(field)
    const emptyOrBlank = isEmptyOrBlank(fieldData)
    if (!isMandatory && emptyOrBlank) return undefined

    // Execute the per field validation logic.
    const validOrError = props.validate?.[field]?.(fieldData, formData)

    // In case the validation result is a string (not true),
    // then the field is invalid and should return the error message.
    if (validOrError != undefined && validOrError != true)
      return validOrError as string

    // In case the field is mandatory,
    // empty or blank and has no special validation handling
    // return a default error message.
    if (isMandatory && emptyOrBlank)
      return props.defaultValidationErrorMessage ?? ""

    // Otherwise, at this point the field should be considered valid.
    return undefined
  }

  // Executes the submission logic with the current form data.
  // Validity needs to be ensured beforehand.
  const submit = async () => {
    if (safetySubmittingRef.current) return

    // Prevent additional submission calls during the ongoing one.
    safetySubmittingRef.current = true
    setSubmitting(true)

    // When submitting, clear any previous field or submit errors.
    setErrorMessages({})
    setSubmitError(undefined)

    try {
      // Perform a specified submit logic, which may take a bit.
      // The submit element will be disabled in the meantime.
      const result = await props.submit(formData, form)

      if (typeof result == "object") {
        setData(result.data ?? {})
        setErrorMessages(result.errors ?? {})
      }
    } catch (error: any) {
      // If submit throws,
      // show the exception message as a submit error.
      if (isMounted.current) setSubmitError(error.message)
    } finally {
      // Re-enable submission possibility
      // in case the form hook is still mounted.
      if (isMounted.current) {
        safetySubmittingRef.current = false
        setSubmitting(false)
      }
    }
  }

  // Build up the props for input fields.
  const fieldProps = {} as UseFormValue<T>["field"]
  for (const field in initialDataRef.current) {
    fieldProps[field] = {
      value: formData[field],
      editable: props.editable,
      mandatory: fieldIsMandatory(field),
      focus: focusChain.link(linkIndex++),
      placeholder: props.placeholder?.[field],
      label: props.label?.[field] ?? undefined,
      subLabel: props.subLabel?.[field] ?? undefined,
      errorMessage: errorMessages[field] ?? validationError(field),
      onChange: value => {
        let fieldValue: FormFieldValue = value
        if (isEmptyOrBlank(fieldValue)) fieldValue = undefined

        // Do reset a possible present error message
        // of the field on every field state change.
        setErrorMessages(errorMessages => ({
          ...errorMessages,
          [field]: undefined,
        }))

        // Update the field as it is now touched
        // (therefore validation will be executed).
        setTouched(touched => ({ ...touched, [field]: true }))

        // Finally, update the actual field value.
        setFormData(formData => ({ ...formData, [field]: fieldValue }))
      },
      onSubmitIfValid: async () => {
        if (!ensureAllValid(true) || !submitProps.onPress) return false
        await submit()
        return true
      },
    }
  }

  const setAllTouched = (allTouched: boolean): void => {
    const touchAll = {} as { [Field in Fields<T>]: boolean }
    for (const field in formData) touchAll[field] = allTouched
    setTouched(touchAll)
  }

  // Makes sure that all fields are valid,
  // even if they were not validated before.
  const ensureAllValid = (silent: boolean): boolean => {
    let allValid = true
    let allTouched = true

    for (const field in formData) {
      if (touched[field]) {
        // Use the previous validation result,
        // if there is already a field error message present.
        if (fieldProps[field].errorMessage != undefined) allValid = false
      } else {
        allTouched = false
        if (validationError(field, true)) allValid = false
      }
    }

    if (!allTouched && !silent) setAllTouched(true)

    return allValid
  }

  // See if any form field failed the validation check(s).
  let anyValidationErrors = false
  for (const field in formData) {
    if (fieldProps[field].errorMessage == undefined) continue

    anyValidationErrors = true
    break
  }

  // Build up the props for the submit element.
  const submitProps = {
    // The submit logic should be disabled
    // when there are validation errors present or
    // the form is already in a submission process.
    disabled: anyValidationErrors || submitting,

    // Attach the last submit error to the submitting element.
    errorMessage: errorMessages.submit ?? submitError,

    onPress: async () => {
      // If the data is not valid,
      // do not execute the submission logic.
      if (!ensureAllValid(false)) return
      await submit()
    },
  } as UseFormValue<T>["submit"]

  // Resets the form to its initial state (data and error messages).
  const reset = () => {
    setFormData(initialDataRef.current)
    setErrorMessages({})
    if (!props.validateOnStart) setAllTouched(false)
  }

  const form = {
    data: formData,
    field: fieldProps,
    submit: submitProps,
    isValid: !anyValidationErrors,
    initialData: initialDataRef.current,
    isDirty: !isEqual(formData, initialDataRef.current),

    reset,
    setData,
    setErrorMessages,
  }

  return form
}
