import * as z from "zod"

import { MCIcon } from "@axtesys/kassen-app-ui"
import { createEntityFor } from "@axtesys/react-tools"

import { ValueTransferFlagsInput } from "../api/graphql/types"

export type PaymentMethodId = string

type PaymentMethodProperties = {
  rksvMandatory: boolean
  usableForPayment: boolean
  usableForPayout: boolean
  isCardPayment: boolean
  openDrawer: boolean
  overpayAllowed: boolean
}

export type PaymentMethod = {
  paymentMethodId: PaymentMethodId
  icon: MCIcon
  methodName: string
  properties: PaymentMethodProperties
  programmaticProperties: { affectsCashTransfers: boolean }
}

export type PaymentMethodFormData = PaymentMethodProperties & {
  icon: string
  name: string
  enabledByDefault: boolean
}

export type PaymentMethodData = {
  name: string
  icon: string
  properties: PaymentMethodProperties & { enabledByDefault: boolean }
}

export const PaymentMethodEntity = createEntityFor<PaymentMethod>()
  .withSchema(
    z.object({
      paymentMethodId: z.string(),
      methodName: z.string(),
      icon: z.string(),
      properties: z.object({
        rksvMandatory: z.boolean(),
        usableForPayment: z.boolean(),
        usableForPayout: z.boolean(),
        isCardPayment: z.boolean(),
        openDrawer: z.boolean(),
        overpayAllowed: z.boolean(),
      }),
      programmaticProperties: z.object({ affectsCashTransfers: z.boolean() }),
    }),
  )
  .serialize(data => data)
  .deserialize(json => ({ ...json, icon: json.icon as MCIcon }))

export function transformPaymentMethodToValueTransferFlags(
  paymentMethod: PaymentMethod,
): ValueTransferFlagsInput {
  return {
    overpayAllowed: paymentMethod.properties.overpayAllowed,
    usableForPayout: paymentMethod.properties.usableForPayout,
    affectsCashTransfers:
      paymentMethod.programmaticProperties.affectsCashTransfers,
  }
}
