import { isEqual } from "lodash"
import React, { useCallback, useState } from "react"
import { Platform, StyleSheet, View } from "react-native"

import { useOnOff } from "@axtesys/hooks"

import { TransparentModal } from "../container/TransparentModal"
import { useReCalculationEffect } from "../hooks/useReCalculationEffect"
import { IconConfig as TIIconConfig } from "../input/field/TextInput"
import { Row } from "../layout/FlexBox"
import { calculatePosition } from "../lib"
import { ScreenCoordinates } from "../types"
import { Label } from "../typography/Label"
import { Icon } from "./Icon"
import { MCIcon } from "./MCIcon"

export type ContextMenuItem = {
  icon: MCIcon
  label: string
  disabled?: boolean | (() => boolean)
  onPress?: () => void
}

type IconConfig = Omit<TIIconConfig, "name"> & {
  name?: MCIcon
  disabled?: boolean
}

type ContextMenuProps = {
  items: ContextMenuItem[]
  icon?: IconConfig
}

type ItemProps = ContextMenuItem & {
  isMedium: boolean
  onCloseMenu: () => void
}

type MenuProps = Pick<ContextMenuProps, "items"> & {
  isVisible: boolean
  position: ScreenCoordinates
  onCloseMenu: () => void
}

export function ContextMenu(props: ContextMenuProps) {
  const { position, isVisible, menuItems, viewRef, onOpenMenu, onCloseMenu } =
    useLogic(props)

  const { icon } = props
  const interceptor = (
    <View ref={viewRef}>
      <Icon
        size={icon?.size ?? "L"}
        color={icon?.color ?? "primary"}
        disabled={icon?.disabled ?? false}
        name={icon?.name ?? "dots-vertical-circle"}
        onPress={onOpenMenu}
      />
    </View>
  )

  const menu = (
    <Menu
      items={menuItems}
      position={position}
      isVisible={isVisible && !isEqual(INIT_POSITION, position)}
      onCloseMenu={onCloseMenu}
    />
  )

  return (
    <>
      {interceptor}
      {menu}
    </>
  )
}

function Item({
  icon,
  label,
  isMedium,
  disabled,
  onPress,
  onCloseMenu,
}: ItemProps) {
  const onSelect = () => {
    onCloseMenu()
    onPress?.()
  }

  return (
    <Row
      expand
      alignCenter
      gap="XXS"
      padLeft="XXXXS"
      style={styles.item}
      disabled={!!disabled}
      onPress={onSelect}
    >
      <Icon size="M" name={icon} />
      <Label expand medium={isMedium} text={label} />
    </Row>
  )
}

function Menu({ items, isVisible, position, onCloseMenu }: MenuProps) {
  return (
    <TransparentModal isVisible={isVisible} onPressOutside={onCloseMenu}>
      <View style={[styles.menu, { top: position.y, left: position.x }]}>
        {items.map((item, index) => (
          <Item
            {...item}
            isMedium={false}
            key={`cMenu_${item.label}_${index}`}
            onCloseMenu={onCloseMenu}
          />
        ))}
      </View>
    </TransparentModal>
  )
}

function useLogic({ items, icon }: ContextMenuProps) {
  const viewRef = React.createRef<View>()
  const [isVisible, showMenu, hideMenu] = useOnOff(false)
  const [menuItems, setMenuItems] = useState<ContextMenuItem[]>(items)
  const [position, setPosition] = useState<ScreenCoordinates>(INIT_POSITION)

  const calculateMenuPosition = () =>
    calculatePosition({ viewRef, setPosition, xReduction: WIDTH - 36 })

  useReCalculationEffect({
    dependency: isVisible,
    executeIfNotVisible: hideMenu,
    calculate: calculateMenuPosition,
  })

  const onOpenMenu = useCallback(() => {
    setMenuItems(
      items.map(item => ({
        ...item,
        disabled:
          typeof item.disabled == "function"
            ? item.disabled()
            : item.disabled ?? false,
      })),
    )

    showMenu()
    icon?.onPress?.()
  }, [icon, items, showMenu])

  return {
    viewRef,
    position,
    isVisible,
    menuItems,
    onOpenMenu,
    onCloseMenu: hideMenu,
  }
}

const WIDTH = 200
const ITEM_HEIGHT = 48
const INIT_POSITION = { x: 0, y: 0 }

const styles = StyleSheet.create({
  item: { minHeight: ITEM_HEIGHT },
  menu: {
    width: WIDTH,
    shadowRadius: 4,
    shadowOpacity: 0.5,
    position: "absolute",
    shadowColor: "black",
    backgroundColor: "white",
    shadowOffset: { height: 2, width: 0 },
    elevation: Platform.OS == "android" ? 5 : undefined,
  },
})
