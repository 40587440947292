import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

export type CashFlowId = string

export type CashFlowMode = "deposit" | "disbursal"

export type CashFlow = {
  cashFlowId: CashFlowId
  name: string
  properties: { usableForDeposit: boolean; usableForDisbursal: boolean }
}

export const CashFlowEntity = createEntityFor<CashFlow>()
  .withSchema(
    z.object({
      cashFlowId: z.string(),
      name: z.string(),
      properties: z.object({
        usableForDeposit: z.boolean(),
        usableForDisbursal: z.boolean(),
      }),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
