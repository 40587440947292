import { mapValues } from "lodash"
import * as z from "zod"

import { UseFormValue } from "@axtesys/hooks"
import { createEntityFor } from "@axtesys/react-tools"

import {
  ContactFragment,
  ContactTurnoverFragment,
  ContactType,
  DiscountInput,
  Salutation,
} from "../api/graphql/types"
import { Department, DepartmentEntity, DepartmentId } from "./Department"
import { Discount, DiscountEntity } from "./Discount"
import { ManipulationMode } from "./ManipulationMode"
import { PersonInCharge, PersonInChargeId } from "./PersonInCharge"
import {
  ShippingAddress,
  ShippingAddressEntity,
  ShippingAddressId,
} from "./ShippingAddress"

export type ContactId = string

export type SelectionMode = "Invoice" | "Receipt"

export type BillingAddress = {
  contactId: ContactId
  salutation: Salutation
  lastName: string

  firstName?: string
  city?: string
  street?: string
  streetNumber?: string
  zipCode?: string
  countryCode?: string
}

export type Contact = BillingAddress & {
  type: ContactType

  contactNumber?: string
  additionalInfo?: string
  landLinePhoneNumber?: string
  mobilePhoneNumber?: string
  email?: string
  taxId?: string
  receiptText?: string
  printOnReceipt: boolean
  periodOfCashback?: number
  nameOfBank?: string
  iban?: string
  bic?: string
  periodOfCashbackDiscount?: Discount
  cartDiscount?: Discount
}

type ContactIntermediate = Omit<
  Contact,
  "contactId" | "cartDiscount" | "periodOfCashbackDiscount"
>

export type ContactFormData = ContactIntermediate & {
  client: boolean
  supplier: boolean
  clientSupplier: boolean

  periodOfCashbackDiscount?: number
  cartDiscount?: number
}

export type ContactFormOutput = ContactIntermediate & {
  contactId?: ContactId
  cartDiscount?: DiscountInput
  periodOfCashbackDiscount?: DiscountInput
}

export type ContactInfo = Contact & {
  defaultDepartmentId?: string
  departments?: Record<DepartmentId, Department>
  defaultPersonInChargeId?: string
  personsInCharge?: Record<PersonInChargeId, PersonInCharge>
  defaultShippingAddressId?: string
  shippingAddresses?: Record<ShippingAddressId, ShippingAddress>
}

export type ContactFormType = {
  form: UseFormValue<ContactFormData>
  setContactType: (contactType: ContactType) => void
}

export type GeneralContactFormProps = {
  mode: ManipulationMode
  initialValue?: ContactFragment & ContactTurnoverFragment

  onSubmit(
    contact: ContactFormOutput,
    form: UseFormValue<ContactFormData>,
  ): void
}

export const ContactEntity = createEntityFor<ContactInfo>()
  .withSchema(
    z.object({
      contactId: z.string(),
      type: z.enum(["Client", "Supplier", "ClientSupplier"]),
      salutation: z.enum([
        "Mr",
        "Ms",
        "NonBinary",
        "Enterprise",
        "NotSpecified",
        "Family",
        "Club",
      ]),
      lastName: z.string(),
      firstName: z.ostring(),
      contactNumber: z.ostring(),
      additionalInfo: z.ostring(),
      landLinePhoneNumber: z.ostring(),
      mobilePhoneNumber: z.ostring(),
      email: z.ostring(),
      taxId: z.ostring(),
      city: z.ostring(),
      street: z.ostring(),
      streetNumber: z.ostring(),
      zipCode: z.ostring(),
      countryCode: z.ostring(),
      receiptText: z.ostring(),
      printOnReceipt: z.boolean(),
      periodOfCashback: z.onumber(),
      nameOfBank: z.ostring(),
      iban: z.ostring(),
      bic: z.ostring(),
      periodOfCashbackDiscount: z.optional(DiscountEntity.schema),
      cartDiscount: z.optional(DiscountEntity.schema),
      defaultDepartmentId: z.ostring(),
      departments: z.optional(z.record(DepartmentEntity.schema)),
      defaultShippingAddressId: z.ostring(),
      shippingAddresses: z.optional(z.record(ShippingAddressEntity.schema)),
    }),
  )
  .serialize(data => ({
    ...data,
    periodOfCashbackDiscount: data.periodOfCashbackDiscount
      ? DiscountEntity.serialize(data.periodOfCashbackDiscount)
      : undefined,
    cartDiscount: data.cartDiscount
      ? DiscountEntity.serialize(data.cartDiscount)
      : undefined,
    departments: mapValues(data.departments, DepartmentEntity.serialize),
    shippingAddresses: mapValues(
      data.shippingAddresses,
      ShippingAddressEntity.serialize,
    ),
  }))
  .deserialize(json => ({
    ...json,
    periodOfCashbackDiscount: (json.periodOfCashbackDiscount
      ? DiscountEntity.deserialize(json.periodOfCashbackDiscount)
      : undefined) as Discount | undefined,
    cartDiscount: (json.cartDiscount
      ? DiscountEntity.deserialize(json.cartDiscount)
      : undefined) as Discount | undefined,
    departments: mapValues(json.departments, DepartmentEntity.deserialize),
    shippingAddresses: mapValues(
      json.shippingAddresses,
      ShippingAddressEntity.deserialize,
    ),
  }))

export const BillingAddressEntity = createEntityFor<BillingAddress>()
  .withSchema(
    z.object({
      contactId: z.string(),
      salutation: z.enum([
        "Mr",
        "Ms",
        "NonBinary",
        "Enterprise",
        "NotSpecified",
        "Family",
        "Club",
      ]),
      lastName: z.string(),
      firstName: z.ostring(),
      city: z.ostring(),
      street: z.ostring(),
      streetNumber: z.ostring(),
      zipCode: z.ostring(),
      countryCode: z.ostring(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
