import React, { ReactNode, useMemo } from "react"
import { isMobile } from "react-device-detect"
import { PressableProps } from "react-native"

import { PressableOpacity, PressableOpacityProps } from "./PressableOpacity"

type HoverablePressableOpacityProps = Omit<PressableProps, "style"> &
  Pick<PressableOpacityProps, "alwaysActive" | "style"> & {
    children?: ReactNode
    onHoverIn?: () => void
    onHoverOut?: () => void
  }

export function HoverablePressableOpacity(
  props: HoverablePressableOpacityProps,
) {
  const { children, onHoverIn, onHoverOut } = props

  // Exposes the hidden onMouseEnter and onMouseLeave
  // callbacks available in react-native-web on mobile browsers.
  const mobileTargetedProps = useMemo(
    () =>
      isMobile
        ? { onMouseEnter: onHoverIn, onMouseLeave: onHoverOut }
        : undefined,
    [onHoverIn, onHoverOut],
  )

  return (
    <PressableOpacity {...props} {...mobileTargetedProps}>
      {children}
    </PressableOpacity>
  )
}
