import z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

export type LogMessage = { level: string; message: string }
export type LogFunc = (message: string, ...data: any) => void

export const LogMessageEntity = createEntityFor<LogMessage>()
  .withSchema(z.object({ level: z.string(), message: z.string() }))
  .serialize(data => data)
  .deserialize(json => json)
