import React, { useMemo } from "react"
import { isIOS } from "react-device-detect"
import { Platform } from "react-native"

import { TextInput, TextInputProps } from "./TextInput"

export function PhoneInput(props: TextInputProps) {
  const keyboardType = useMemo(() => {
    if (Platform.OS == "ios") {
      return "numbers-and-punctuation"
    } else return isIOS ? "default" : "phone-pad"
  }, [])

  return <TextInput {...props} keyboardType={keyboardType} />
}
