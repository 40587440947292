import React, { ReactNode, useEffect } from "react"
import { AppState, Platform } from "react-native"

import { useNetworkStatus } from "@axtesys/hooks"
import {
  focusManager,
  onlineManager,
  QueryClient,
  QueryClientProvider,
  useQueryErrorResetBoundary,
} from "@tanstack/react-query"

import { QUERY_RETRY_EXEMPTIONS } from "./constants"

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: { retry: false },
    queries: {
      retryDelay: 1000,
      networkMode: "always",
      refetchOnReconnect: "always",
      retry: (failureCount, error) =>
        failureCount <= 3 &&
        onlineManager.isOnline() &&
        !QUERY_RETRY_EXEMPTIONS.some(exemption =>
          error.message.includes(exemption),
        ),
    },
  },
})

export function ReactQueryProvider({ children }: { children: ReactNode }) {
  useResetErrorsOnComingOnline()
  useSetupForRefetchFocusHandling()

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

function useResetErrorsOnComingOnline() {
  const { connected } = useNetworkStatus()
  const { reset } = useQueryErrorResetBoundary()

  useEffect(() => {
    if (!connected) return

    const erroredQueries = queryClient
      .getQueryCache()
      .getAll()
      .filter(it => it.state.status == "error")
    for (const query of erroredQueries) query.reset()

    reset()
  }, [connected, reset])
}

function useSetupForRefetchFocusHandling() {
  // Focus behaviour for browsers.
  //
  // Purposely reimplemented the
  // refetchOnWindowFocus behaviour of pre react-query v5
  // as it better suits the use case of this application.
  //
  // The developers of this package changed it
  // because of the reasons stated in this PR.
  // https://github.com/TanStack/query/pull/4805
  useEffect(() => {
    if (Platform.OS != "web") return () => {}
    if (!(typeof window !== "undefined" && window.addEventListener))
      return () => {}

    const handleFocus = () => focusManager.setFocused(true)
    const handleBlur = () => focusManager.setFocused(false)

    window.addEventListener("focus", handleFocus)
    window.addEventListener("blur", handleBlur)

    return () => {
      window.removeEventListener("focus", handleFocus)
      window.removeEventListener("blur", handleBlur)
    }
  }, [])

  // Focus behaviour for mobile devices.
  //
  // Only re-fetch when the app has been in background and becomes active again
  // (does not include app switcher,
  //  notification or control center navigations on iOS).
  useEffect(() => {
    if (Platform.OS == "web") return () => {}

    const subscription = AppState.addEventListener("change", state => {
      if (state == "active") focusManager.setFocused(true)
      else if (state == "background") focusManager.setFocused(false)
    })

    return () => subscription.remove()
  }, [])
}
