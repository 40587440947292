import React, { ComponentType, createElement, ReactNode } from "react"

// Given an ordered list of container components (which only take children),
// nests the components inside each other in order, so that the first component
// given is the outermost (mounted first),
// and the children are the innermost (mounted last).
export function ContextStack({
  children,
  containers,
}: {
  children: ReactNode
  containers: Array<ComponentType<{ children: ReactNode }>>
}) {
  let element = <>{children}</>
  let nextWrapper = containers.pop()

  while (nextWrapper) {
    // eslint-disable-next-line react/no-children-prop
    element = createElement(nextWrapper, { children: element })
    nextWrapper = containers.pop()
  }

  return element
}
