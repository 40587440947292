import { isEqual } from "lodash"
import React from "react"
import { DimensionValue, StyleSheet } from "react-native"

import { Column, Row } from "../layout/FlexBox"
import { WrappingRow } from "../layout/WrappingRow"
import { useTheme } from "../theme"
import { Label } from "../typography/Label"
import { Icon } from "./Icon"

export type ChipViewProps = {
  emptyValuesLabel: string
  onChange: (values?: string[]) => void

  values?: string[]
  erroredValues?: string[]
  chipMinWidth?: DimensionValue
}

export function ChipView({
  values,
  chipMinWidth,
  erroredValues,
  emptyValuesLabel,
  onChange,
}: ChipViewProps) {
  const onDelete = (valueToDelete: string) => {
    const changedValues = values?.filter(
      value => !isEqual(value.trim(), valueToDelete.trim()),
    )
    onChange(
      changedValues && changedValues.length > 0 ? changedValues : undefined,
    )
  }

  return (
    <Column expand style={styles.chipView}>
      {!values || values.length <= 0 ? (
        <Label text={emptyValuesLabel} />
      ) : (
        <WrappingRow gap="XXXS">
          {values?.map(value => (
            <Chip
              value={value}
              key={`chip_${value}`}
              minWidth={chipMinWidth}
              isError={erroredValues?.includes(value)}
              onDelete={onDelete}
            />
          ))}
        </WrappingRow>
      )}
    </Column>
  )
}

type ChipProps = {
  value: string
  onDelete: (value: string) => void

  isError?: boolean
  minWidth?: DimensionValue
}

function Chip({ value, minWidth, isError = false, onDelete }: ChipProps) {
  const { color } = useTheme()

  const signifierColor = isError ? "error" : undefined

  const onPressIcon = () => onDelete(value)

  return (
    <Row
      alignCenter
      spaceBetween
      padVertical="XXXS"
      padHorizontal="XXS"
      backgroundColor={color.base6}
      style={[styles.chip, { minWidth, borderColor: color.accent }]}
    >
      <Label text={value} color={signifierColor} />
      <Icon
        size="SX"
        name="trash-can"
        color={signifierColor}
        onPress={onPressIcon}
      />
    </Row>
  )
}

const styles = StyleSheet.create({
  // @ts-ignore - overflow: "auto" -
  // Similar to "scroll", but it adds scrollbars only when necessary
  chipView: { overflow: "auto" },
  chip: { borderWidth: 2, borderRadius: 8 },
})
