import Big from "big.js"
import { Platform } from "react-native"

import { formatDecimal, FormatDecimalOpts } from "./Big"

// Types

export type Currency = "€"
export type MoneyAmount = { amount?: Big; currency?: Currency }
export type Euros = { cents: number }
export type Turnover = {
  amount: number
  gross: { cents: number }
  tax: { cents: number }
  discount: { cents: number }
  net: { cents: number }
}
type OptionalTurnover = {
  amount?: number
  gross?: { cents: number }
  tax?: { cents: number }
  discount?: { cents: number }
  net?: { cents: number }
}
export type CountedTotal = {
  amount: number
  total: { cents: number }
}
type OptionalCountedTotal = {
  amount?: number
  total?: { cents: number }
}
type FormatMoneyOpts = Pick<FormatDecimalOpts, "withoutThousandSeparator"> & {
  exact?: boolean
  noSymbol?: boolean
}

// Conversions

export function bigToEuros(big: Big): Euros
export function bigToEuros(big?: Big): Euros | undefined
export function bigToEuros(big?: Big): Euros | undefined {
  if (!big) return undefined
  return { cents: Math.round(big.toNumber() * 100) }
}

export function eurosToBig(euros: Euros): Big
export function eurosToBig(euros?: Euros): Big | undefined
export function eurosToBig(euros?: Euros): Big | undefined {
  if (!euros) return undefined
  return Big(euros.cents / 100)
}

export function stringToEuros(moneyString: string): Euros | undefined {
  const float = parseFloat(moneyString.replace(",", "."))
  if (Number.isNaN(float)) return undefined
  return { cents: Math.round(float * 100) }
}

// Formatting

function formatMoneyInternal(numberInput: Big, opts?: FormatMoneyOpts) {
  const number = Big(numberInput)

  let numberString
  if (opts?.exact || number.lt(1e4)) {
    numberString = number.toFixed(2)
  } else if (number.gt(1e9)) {
    numberString = `${number.div(1e6).toFixed(2)}t`
  } else if (number.gt(1e6)) {
    numberString = `${number.div(1e6).toFixed(2)}m`
  } else if (number.gt(1e3)) {
    numberString = `${number.div(1e3).toFixed(2)}k`
  } else numberString = number.toFixed()

  // Convert to xxx.xxx,xx notation
  return formatDecimal(numberString, opts)
}

export function formatMoney(
  amountOrEuros: (Euros | Big) | undefined,
  opts?: FormatMoneyOpts,
): string {
  if (!amountOrEuros) return ""

  // In app, we shorten sums > 9999€ by default.
  // On web, we show the full string representation by default.
  const updatedOpts = { ...opts, exact: opts?.exact ?? Platform.OS == "web" }

  const moneyAmount =
    "cents" in amountOrEuros ? eurosToBig(amountOrEuros) : amountOrEuros

  const prefix = updatedOpts?.noSymbol ? "" : "€ "

  return moneyAmount.lt(0)
    ? `${prefix} -${formatMoneyInternal(moneyAmount.mul(-1), updatedOpts)}`
    : `${prefix}${formatMoneyInternal(moneyAmount, updatedOpts)}`
}

// Arithmetics

export function sumByTurnover(turnovers: OptionalTurnover[]): Turnover {
  const summedTurnover = {
    amount: 0,
    gross: { cents: 0 },
    tax: { cents: 0 },
    discount: { cents: 0 },
    net: { cents: 0 },
  }

  for (const turnover of turnovers) {
    summedTurnover.amount += turnover.amount ?? 0
    summedTurnover.gross.cents += turnover.gross?.cents ?? 0
    summedTurnover.tax.cents += turnover.tax?.cents ?? 0
    summedTurnover.discount.cents += turnover.discount?.cents ?? 0
    summedTurnover.net.cents += turnover.net?.cents ?? 0
  }

  return summedTurnover
}

export function sumByCountedTotal(
  turnovers: OptionalCountedTotal[],
): CountedTotal {
  const summedCountedTotal = { amount: 0, total: { cents: 0 } }

  for (const turnover of turnovers) {
    summedCountedTotal.amount += turnover.amount ?? 0
    summedCountedTotal.total.cents += turnover.total?.cents ?? 0
  }

  return summedCountedTotal
}

export function isEurosBetween(euros: Euros, startSum?: Big, endSum?: Big) {
  let matchTotalConstraint = true

  const total = eurosToBig(euros)
  if (startSum != null && endSum != null)
    matchTotalConstraint = total.gte(startSum) && total.lte(endSum)
  else if (startSum != null) matchTotalConstraint = total.gte(startSum)
  else if (endSum != null) matchTotalConstraint = total.lte(endSum)

  return matchTotalConstraint
}
