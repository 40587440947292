import Big from "big.js"

// Replaces the interpolation template identifier (%s)
// with possible passed parameter values.
// - In case no parameter values are passed,
//   the identifier will stay present within the returned string.
// - If the number of identifier and parameter values do not match,
//   template identifiers will stay present or
//   passed parameters will be omitted from the returned string.
export function interpolateString(
  templateString: string,
  ...parameters: (string | number | Big)[]
) {
  let resultString = templateString

  for (let i = 0; i < parameters.length; i++)
    resultString = resultString.replace("%s", parameters[i].toString())

  return resultString
}
