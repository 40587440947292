import Big from "big.js"
import * as z from "zod"

import { createEntityFor, ISODate } from "@axtesys/react-tools"

import { ReceiptOutput, ReceiptType } from "../api/graphql/types"
import { CashRegisterInfo, CashRegisterInfoEntity } from "./CashRegister"
import { CompanyEntity, CompanyInfo } from "./Company"
import { BillingAddress, BillingAddressEntity } from "./Contact"
import { DepartmentInfo, DepartmentInfoEntity } from "./Department"
import { CartDiscountHistoryEntry } from "./Discount"
import {
  GPtomReceiptData,
  GPtomReceiptDataEntity,
  GPtomTransactionResponse,
} from "./GPtom"
import {
  HobexReceiptData,
  HobexReceiptDataEntity,
  HobexResponse,
} from "./Hobex"
import { InvoiceItem, OutputInvoiceItem } from "./InvoiceItem"
import { OperatorInfo, OperatorInfoEntity } from "./Operator"
import {
  DetailedArticleReceiptItem,
  ReceiptItem,
  ReceiptItemEntity,
} from "./ReceiptItem"
import { PrinterId } from "./ReceiptPrinter"
import {
  ShippingAddressInfo,
  ShippingAddressInfoEntity,
} from "./ShippingAddress"
import {
  TaxInfo,
  TaxInfoEntity,
  TotalTaxInfo,
  TotalTaxInfoEntity,
} from "./TaxInfo"
import {
  DisplayValueTransfer,
  DisplayValueTransferEntity,
  ValueTransfer,
} from "./ValueTransfer"

export type ReceiptId = string
export type TaxOfficeCashBoxId = string

type SharedReceiptInfo = {
  receiptId: ReceiptId
  receiptNumber: string

  // Info about the creator of the receipt
  company: CompanyInfo
  operator: OperatorInfo
  cashRegister: CashRegisterInfo

  // The entire shopping cart changelog.
  // Tracks all additions, changes and therefore voids.
  // Implemented for the cash register ledger report / journal / protocol requirements,
  // which require full action tracking per cash register.
  itemsHistory: InvoiceItem[]

  // The entire cartDiscounts changelog.
  // We track all movements in regard to cartDiscounts.
  cartDiscountsHistory: CartDiscountHistoryEntry[]

  // Ways in which the customer paid the company.
  payments: ValueTransfer[]

  // Information about a potential contact / client assignment.
  department?: DepartmentInfo
  billingAddress?: BillingAddress
  shippingAddress?: ShippingAddressInfo
  receiptText?: string

  // NOTES:
  // 1. Ways in which the company paid the customer.
  // 2. Former payments are inverted in case of a cancel / void receipt.
  payouts?: ValueTransfer[]

  // The taxOfficeCashBoxId of the cash register
  // that the receipt is/was created on.
  taxOfficeCashBoxId?: TaxOfficeCashBoxId
}

export type InputReceipt = SharedReceiptInfo & {
  // Used output printer
  printerId: PrinterId

  // Current state of the shopping cart (may include voids).
  // See OutputReceipt type for more explanation.
  items: OutputInvoiceItem[]

  // Rounding difference that
  // can appear when cart discounts are applied.
  roundingDifference?: Big
}

export type OutputReceipt = SharedReceiptInfo & {
  // Metadata about the receipt
  createdAt: ISODate
  receivedAs: ReceiptOutput

  // All non-voided / not-empty items that were sold.
  // Including item discounts and (proportional) cart discounts.
  // It represents the final state of a receipt / an invoice.
  items: OutputInvoiceItem[]

  // Used for the cash register ledger report / journal / protocol features.
  // All voided or zero amount items that
  // were generated during filling and changing the shopping cart.
  zeroAndVoidedItems: OutputInvoiceItem[]

  // Total sums including
  // (gross, net, tax, discount, undiscountedGross and correctedGross)
  totalTaxInfo: TotalTaxInfo

  // Aggregated tax information
  taxInfos: TaxInfo[]

  // True, if we did not get a signature from A-Trust,
  // either because we are offline or their server failed
  signatureDeviceFailed: boolean

  // Signature as QRCode hash.
  qrCodeRepresentation?: string

  // RKSV url for retrieving the QRCode signature
  // via a REST API and JSON response.
  signatureUrlHash?: string

  // Card payment information
  hobexResponse?: HobexResponse
  gpTomResponse?: GPtomTransactionResponse
}

// Because of requirement changes (e.g. fraud protection)
// the createdAt timestamp of the receipt is now created,
// when the submit button in the PaymentScreen is used.
//
// Previous behaviour: As soon as the navigation takes place from,
// ShoppingCartScreen to PaymentScreen the createdAt timestamp was created.
export type PreOutputReceipt = Omit<OutputReceipt, "createdAt">

type Receipt = Omit<
  OutputReceipt,
  | "zeroAndVoidedItems"
  | "itemsHistory"
  | "items"
  | "operator"
  | "cashRegister"
  | "cartDiscountsHistory"
> & {
  items: ReceiptItem[]

  operator?: OperatorInfo
  receiptType?: ReceiptType
  refundsReceiptNumber?: string
  cashRegister?: CashRegisterInfo
  refundedByReceiptNumber?: string
  cancelledByReceiptNumber?: string
  gpTomReceiptData?: GPtomReceiptData
  hobexReceiptData?: HobexReceiptData
}

export type DetailedItemReceipt = Omit<Receipt, "items"> & {
  items: DetailedArticleReceiptItem[]
}

export type DisplayReceipt = Omit<Receipt, "payouts" | "payments"> & {
  payments: DisplayValueTransfer[]

  payouts?: DisplayValueTransfer[]
}

export const DisplayReceiptEntity = createEntityFor<
  DisplayReceipt | undefined
>()
  .withSchema(
    z.optional(
      z.object({
        receiptId: z.string(),
        createdAt: z.string(),
        receiptNumber: z.string(),
        company: CompanyEntity.schema,
        signatureDeviceFailed: z.boolean(),
        taxInfos: z.array(TaxInfoEntity.schema),
        totalTaxInfo: TotalTaxInfoEntity.schema,
        items: z.array(ReceiptItemEntity.schema),
        receivedAs: z.enum(["Email", "NoOutput", "Paper"]),
        payments: z.array(DisplayValueTransferEntity.schema),
        receiptType: z.optional(
          z.enum([
            "CANCEL",
            "DEFAULT",
            "END",
            "MONTHLY",
            "REACTIVATE",
            "REFUND",
            "START",
            "TRAINING",
            "YEARLY",
            "ZERO",
          ]),
        ),
        receiptText: z.ostring(),
        signatureUrlHash: z.ostring(),
        taxOfficeCashBoxId: z.ostring(),
        qrCodeRepresentation: z.ostring(),
        refundsReceiptNumber: z.ostring(),
        refundedByReceiptNumber: z.ostring(),
        cancelledByReceiptNumber: z.ostring(),
        operator: z.optional(OperatorInfoEntity.schema),
        department: z.optional(DepartmentInfoEntity.schema),
        billingAddress: z.optional(BillingAddressEntity.schema),
        cashRegister: z.optional(CashRegisterInfoEntity.schema),
        hobexReceiptData: z.optional(HobexReceiptDataEntity.schema),
        gpTomReceiptData: z.optional(GPtomReceiptDataEntity.schema),
        shippingAddress: z.optional(ShippingAddressInfoEntity.schema),
        payouts: z.optional(z.array(DisplayValueTransferEntity.schema)),
      }),
    ),
  )
  .serialize(data => {
    if (!data) return undefined

    const taxInfos = data.taxInfos.map(TaxInfoEntity.serialize)
    const items = data.items.map(ReceiptItemEntity.serialize)
    const totalTaxInfo = TotalTaxInfoEntity.serialize(data.totalTaxInfo)
    const payouts = data.payouts?.map(DisplayValueTransferEntity.serialize)
    const payments = data.payments.map(DisplayValueTransferEntity.serialize)

    return { ...data, items, payouts, payments, taxInfos, totalTaxInfo }
  })
  .deserialize(json => {
    if (!json) return undefined

    const taxInfos = json.taxInfos.map(TaxInfoEntity.deserialize)
    const items = json.items.map(ReceiptItemEntity.deserialize)
    const totalTaxInfo = TotalTaxInfoEntity.deserialize(json.totalTaxInfo)
    const payouts = json.payouts?.map(DisplayValueTransferEntity.deserialize)
    const payments = json.payments.map(DisplayValueTransferEntity.deserialize)

    return { ...json, items, payouts, payments, taxInfos, totalTaxInfo }
  })
