import Big from "big.js"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { InvoiceItemArticle } from "./Article"
import { CompleteDiscountInfo, Discount, DiscountEntity } from "./Discount"
import { TaxInfo } from "./TaxInfo"

export type InvoiceItemId = string

export type InvoiceItem = {
  invoiceItemId: InvoiceItemId
  amount: Big
  createdAt: Date
  modifiedAt: Date
  discounts: Discount[]
  isNegativeAmount: boolean
  isDiscountBlocked: boolean
  article: InvoiceItemArticle

  additionalText?: string
}

export type InvoiceItemData = Omit<
  InvoiceItem,
  "invoiceItemId" | "createdAt" | "modifiedAt"
>

export type InvoiceItemConfiguration = Pick<
  InvoiceItem,
  "article" | "discounts" | "additionalText" | "amount" | "isNegativeAmount"
>

export type OutputInvoiceItem = Omit<InvoiceItem, "discounts"> & {
  taxInfo: TaxInfo
  discounts: CompleteDiscountInfo[]

  discountedPricePerUnit?: Big
}

export const InvoiceItemEntity = createEntityFor<InvoiceItem>()
  .withSchema(
    z.object({
      invoiceItemId: z.string(),
      amount: z.string(),
      createdAt: z.string(),
      modifiedAt: z.string(),
      discounts: z.array(DiscountEntity.schema),
      article: z.object({
        articleId: z.string(),
        mainGroupId: z.string(),
        articleGroupId: z.string(),
        name: z.string(),
        price: z.string(),
        taxRate: z.number(),
        number: z.ostring(),
      }),
      isNegativeAmount: z.boolean(),
      isDiscountBlocked: z.boolean(),
      additionalText: z.ostring(),
    }),
  )
  .serialize(data => ({
    ...data,
    amount: data.amount.toString(),
    createdAt: data.createdAt.toISOString(),
    modifiedAt: data.modifiedAt.toISOString(),
    discounts: data.discounts.map(DiscountEntity.serialize),
    article: { ...data.article, price: data.article.price.toString() },
  }))
  .deserialize(json => ({
    ...json,
    amount: Big(json.amount),
    createdAt: new Date(json.createdAt),
    modifiedAt: new Date(json.modifiedAt),
    discounts: json.discounts?.map(DiscountEntity.deserialize),
    article: { ...json.article, price: Big(json.article.price) },
  }))
