import { produce } from "immer"
import * as z from "zod"

import AsyncStorage from "@react-native-async-storage/async-storage"

import { JsonDbEntity } from "./JsonDbEntity"

// JsonDBEntry stores a JSON object in AsyncStorage.
//
// Entries stored in and loaded from the DB
// are checked against a schema (using zod)
// to make sure that no data corruption occurs.
//
// Entries are versioned. So when the schema changes,
// you must add a migration from the previous schema to the current one.
// Migrations use `immer` under the hood, so you can make any changes in-place.
// Migrations need to use the version number
// (starting with 1 for the first migration) as keys in the `migrateJson` record.

type InPlaceMutation<T = any> = (entity: T) => void
type DataMigration = Record<number, InPlaceMutation>

// Represents and provides access to a stored database entry.
type JsonDbEntry<T> = {
  seedData: T
  store: (data: T) => Promise<void>
  load: () => Promise<T | undefined>
  reset: () => Promise<void>
}

// Configuration where storage schema is equal to runtime schema.
type JsonDbEntryConfig<TSchema extends z.ZodType> = {
  key: string
  schema: TSchema
  seedData: z.infer<TSchema>
  migrateData?: DataMigration
}

// Configuration where we convert the data into storage schema on store.
export type JsonDbEntryConfig2<TSchema extends z.ZodType, T> = {
  key: string
  entity: JsonDbEntity<T, TSchema>
  seedData: z.infer<TSchema>
  migrateData?: DataMigration
}

// Overload 1 (Storage Schema == Runtime Schema)
export function createJsonDbEntry<TSchema extends z.ZodType>(
  config: JsonDbEntryConfig<TSchema>,
): JsonDbEntry<z.infer<TSchema>>

// Overload 2 (Storage Schema != Runtime Schema)
export function createJsonDbEntry<TSchema extends z.ZodType, TData>(
  config: JsonDbEntryConfig2<TSchema, TData>,
): JsonDbEntry<TData>

// Implementation
export function createJsonDbEntry<
  TSchema extends z.ZodType,
  TData = z.infer<TSchema>,
>(
  config: JsonDbEntryConfig<TSchema> | JsonDbEntryConfig2<TSchema, TData>,
): JsonDbEntry<TData> {
  const { key, seedData, migrateData } = config

  const schema = "entity" in config ? config.entity.schema : config.schema

  let currentRevision = 0
  for (const revision of Object.keys(migrateData ?? {})) {
    currentRevision = Math.max(currentRevision, parseInt(revision))
  }

  const serialize =
    "entity" in config
      ? config.entity.serialize
      : (data: TData) => data as z.infer<TSchema>

  const deserialize =
    "entity" in config
      ? config.entity.deserialize
      : (json: z.infer<TSchema>) => json as TData

  // WARNING: Do not change the structure of this key retrieval function
  // as otherwise existing data will become inaccessible.
  const getStorageKey = (revision: number) => `jsonDb/${key}/${revision}`

  // Tries to store specifically targeted runtime data to storage.
  async function store(data: TData) {
    // Convert from runtime schema to storage schema.
    const serializedData = serialize(data)

    // Validate that the data is actually
    // what we expect it to be by parsing it.
    const result = schema.safeParse(serializedData)

    // Retrieve the storage key for the current revision.
    const storageKey = getStorageKey(currentRevision)

    // In case validation failed or there is no store-worthy data,
    // remove the item from storage.
    if (!result.success || result.data == undefined)
      await AsyncStorage.removeItem(storageKey)
    // Otherwise, convert the parsed data
    // to a JSON string representation and store it.
    else await AsyncStorage.setItem(storageKey, JSON.stringify(result.data))
  }

  // Helper function to load data from the storage and
  // utilise the possibility of a recursive call.
  async function loadStorageData(revision: number): Promise<any | null> {
    // In case the item with the specified revision is present,
    // retrieve it from storage as a JSON string (or null if not found).
    const jsonString = await AsyncStorage.getItem(getStorageKey(revision))

    // If there is actually a non-null string returned,
    // parse it to a proper representation and return the data.
    if (jsonString != null) return JSON.parse(jsonString)

    // Otherwise, the item could not be retrieved through the targeted revision.
    // Now, try to retrieve a possible revision migration.
    const migrateFromPrevious = migrateData?.[revision]
    if (migrateFromPrevious) {
      // When a revision migration has been found,
      // try to load a previous version of the item.
      const previousData = await loadStorageData(revision - 1)

      // If a previous version of the item has been retrieved,
      // apply the specified migration and
      // return the new version of the storage item.
      if (previousData) return produce(previousData, migrateFromPrevious)
    }

    // At this point, an attempt failed
    // to load a valid revision of the storage item.
    return null
  }

  // Tries to load specifically targeted data
  // from storage that previously has been stored.
  async function load(): Promise<TData | undefined> {
    // Try to retrieve the targeted data.
    const storageData = await loadStorageData(currentRevision)

    // In case null is received, the load operation failed
    // and the item could not be found.
    if (storageData == null) return undefined

    try {
      // Otherwise, it has been found and needs to be validated and parsed
      // based on the specified data schema now.
      const parsedStorageData = schema.parse(storageData)

      // Validation passed:
      // Now try to convert the data from storage to runtime schema.
      return deserialize(parsedStorageData)
    } catch (error) {
      // In this case validation or deserialization errors occurred
      // and undefined needs to be returned,
      // as the requested data could not be successfully retrieved.

      if (__DEV__)
        console.error(error, {
          storageKey: getStorageKey(currentRevision),
          stateInStorage: JSON.stringify(storageData),
        })

      return undefined
    }
  }

  // Removes all item revisions from the storage.
  async function reset(): Promise<void> {
    const promises: Promise<void>[] = []

    for (let revision = currentRevision; revision >= 0; revision--)
      promises.push(AsyncStorage.removeItem(getStorageKey(revision)))

    await Promise.all(promises)
  }

  return { seedData, store, load, reset }
}
