import {
  isValidBIC as isITValidBIC,
  isValidIBAN as isITValidIBAN,
} from "ibantools"
import { inRange, last, map, sum } from "lodash"

// In case the passed value is undefined or
// the string representation of it is either empty or
// consists out of whitespaces only, this function will evaluate to 'true'.
export function isEmptyOrBlank(
  input: string | boolean | number | Big | undefined | null,
) {
  return input == undefined || input.toString().trim().length == 0
}

export function isIntegerNumberValid(input?: number | string): boolean {
  if (!input) return false
  return input.toString().match(/^\d+$/) != null
}

const dateTimeRegex =
  /^(?:(?:(?:(?:(?:(?:0[1-9]|[1-2][0-9]|3[01])\.(?:0[13578]|1[02]))|(?:(?:0[1-9]|[1-2][0-9]|30)\.(?:0[469]|11))|(?:(?:0[1-9]|1[0-9]|2[0-8])\.02))\.\d{4})|(?:29\.02\.(?:(?:\d{2}(?:0[48]|[2468][048]|[13579][26]))|(?:(?:[02468][048])|[13579][26])00))) (0[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]))$/
const dateRegex =
  /^(?:(?:(?:(?:0[1-9]|[1-2][0-9]|3[01])\.(?:0[13578]|1[02]))|(?:(?:0[1-9]|[1-2][0-9]|30)\.(?:0[469]|11))|(?:(?:0[1-9]|1[0-9]|2[0-8])\.02))\.\d{4})|(?:29\.02\.(?:(?:\d{2}(?:0[48]|[2468][048]|[13579][26]))|(?:(?:[02468][048])|[13579][26])00))$/ // Checks if the given dateString is a valid date with the help of a regular expression
const timeRegex = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/

// Verifies dateTime format and validity of the dateTimeString
// (including leap year validation).
export function isValidDateTime(dateTimeString: string): boolean {
  return dateTimeString.match(dateTimeRegex) != null
}

// Verifies date format and validity of the dateString
// (including leap year validation).
export function isValidDate(dateString: string): boolean {
  return dateString.match(dateRegex) != null
}

// Verifies time format and validity of the timeString.
export function isValidTime(timeString: string): boolean {
  return timeString.match(timeRegex) != null
}

// Adapted and slightly customized based on:
// https://github.com/manishsaraan/email-validator/blob/master/index.js
const emailRegex =
  /^[-!#$%&'*+äöüÄÖÜ/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+äöüÄÖÜ/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

export function isEmailValid(email?: string): boolean {
  if (!email) return false

  const emailParts = email.split("@")
  if (emailParts.length !== 2) return false

  // Pre @-sign
  const userPart = emailParts[0]

  // Post @-sign
  const domainPart = emailParts[1]

  if (userPart.length > 64 || domainPart.length > 255) return false

  const domainParts = domainPart.split(".")
  if (domainParts.some(part => part.length > 63)) return false

  return emailRegex.test(email)
}

const phoneNumberRegex = /^[0-9+\-()\s]*$/

export function isPhoneNumberValid(phoneNumber?: string): boolean {
  if (!phoneNumber) return false

  if (phoneNumber.substring(1).includes("+")) return false

  return phoneNumber.match(phoneNumberRegex) != null
}

export function isZipCodeValid(zipCode?: string) {
  return zipCode && zipCode?.length >= 4
}

const uidRegex = "(A|a)(T|t)(U|u)\\d{8}"
const taxNumberRegex = "\\d{2}-\\d{3}/\\d{4}"

export function isTaxNumberValid(taxNumber?: string): boolean {
  if (!taxNumber) return false

  const uidNumberMatches = taxNumber.match(uidRegex)
  const taxNumberMatches = taxNumber.match(taxNumberRegex)

  return (
    (uidNumberMatches != null && uidNumberMatches[0] == taxNumber) ||
    (taxNumberMatches != null && taxNumberMatches[0] == taxNumber)
  )
}

export function isIBANValid(iban?: string): boolean {
  if (!iban) return false

  // 1. Extract the countryCode from the potential IBAN
  // (should always be represented by the first two characters).
  const countryCode = iban.substring(0, 2)

  // 2. In a second step we want
  // to extract the account identification.
  const accountIdentification = iban.substring(2)

  // 3. Remove all guideline violating characters of the identification.
  const cleanedAccountIdentification = accountIdentification.replace(/\D/g, "")

  // 4. Validate th cleaned IBAN.
  return isITValidIBAN(`${countryCode}${cleanedAccountIdentification}`)
}

export function isBICValid(bic?: string): boolean {
  if (!bic) return false
  const cleanedBIC = bic.replace(/[^0-9a-zA-Z]+/g, "")
  return isITValidBIC(cleanedBIC)
}

// Implemented based on the following specification
// https://en.wikipedia.org/wiki/International_Article_Number#Calculation_of_checksum_digit

// GTIN stands for Global Trade Item Number and exists in four commonly used types.
// GTIN-8: Most commonly used on end consumer packaging that have a small form factor
//         (frequently encoded as an EAN-8 barcode).
// GTIN-12: Most commonly used inside of North America on end consumer packaging
//         (frequently encoded as a UPC [Universal Product Code; UPC-A] barcode).
// GTIN-13: Most common variant used on end consumer packaging
//          (frequently encoded as an EAN-13 barcode).
// GTIN-14: Most commonly used on master / bulk cartons
//          that include several pieces of the same kind of product
//          (act as the outer packaging; frequently encoded as an ITF-14 barcode).

const gtinRegex = /^(\d{8}|\d{12}|\d{13}|\d{14})$/

function calculateGTINChecksum(
  gtin: string,
  evenWeight: number,
  oddWeight: number,
) {
  const preChecksum = sum(
    map(gtin, (digit, index) =>
      index % 2 == 0 ? +digit * evenWeight : +digit * oddWeight,
    ),
  )

  return preChecksum >= 0 ? (10 - (preChecksum % 10)) % 10 : preChecksum
}

export function isGTINValid(gtin?: string): boolean {
  if (!gtin || !gtin.match(gtinRegex)) return false

  const actualChecksum = +last(gtin)!

  let calculatedChecksum: number
  const gtinWithoutChecksum = gtin.slice(0, -1)
  switch (gtinWithoutChecksum.length) {
    case 12:
      calculatedChecksum = calculateGTINChecksum(gtinWithoutChecksum, 1, 3)
      break
    case 7:
    case 11:
    case 13:
      calculatedChecksum = calculateGTINChecksum(gtinWithoutChecksum, 3, 1)
      break
    default:
      calculatedChecksum = -1
      break
  }

  if (!inRange(calculatedChecksum, 0, 10)) return false

  return actualChecksum == calculatedChecksum
}

const moneyRegex = /^-?\d+[.|,]?\d?\d?$/

export function isEurosStringValid(moneyString: string): boolean {
  if (isEmptyOrBlank(moneyString)) return true
  return moneyString.match(moneyRegex) != null
}
