import Big from "big.js"
import React, { useRef } from "react"
import { InputAccessoryView, Keyboard, Platform } from "react-native"

import {
  useNumberInput,
  UseNumberInputProps,
  UseNumberInputResult,
} from "@axtesys/hooks"
import { createId } from "@axtesys/react-tools"

import { Row } from "../../layout/FlexBox"
import { useKassenAppUI } from "../../providers/KassenAppUIProvider"
import { LinkLabel } from "../../typography/LinkLabel"
import { FocusBlurEvent, TextInput, TextInputProps } from "./TextInput"

export type NumberInputProps<T extends Big | number> = Omit<
  TextInputProps,
  "value" | "keyboardType" | "onChange"
> &
  UseNumberInputProps<T> & {
    // Used to hide the iOS specific 'Done' button
    // above a number or decimal keyboard layout.
    hideIOSDoneButton?: boolean
  }

type CustomNumberInputProps = { numberInputProps: UseNumberInputResult }
type GeneralNumberInputProps<T extends Big | number> = NumberInputProps<T> &
  CustomNumberInputProps
type IOSNumberInputProps<T extends Big | number> = NumberInputProps<T> &
  CustomNumberInputProps

export function NumberInput<T extends Big | number>(
  props: NumberInputProps<T>,
) {
  const numberInputProps = useNumberInput<T>({
    maxValue: 999999999 as T,
    ...props,
  })

  return Platform.OS == "ios" && !Platform.isPad ? (
    <IOSNumberInput {...props} numberInputProps={numberInputProps} />
  ) : (
    <GeneralNumberInput {...props} numberInputProps={numberInputProps} />
  )
}

function GeneralNumberInput<T extends Big | number>({
  numberInputProps,
  ...props
}: GeneralNumberInputProps<T>) {
  const optimizedGeneralProps = {
    ...props,
    value: undefined,
    onChange: undefined,
  }

  const onFocus = (event?: FocusBlurEvent) => {
    numberInputProps.onFocus()
    optimizedGeneralProps.onFocus?.(event)
  }
  const onBlur = (event?: FocusBlurEvent) => {
    numberInputProps.onBlur()
    optimizedGeneralProps.onBlur?.(event)
  }

  return (
    <TextInput
      {...optimizedGeneralProps}
      {...numberInputProps}
      // Do not show completion suggestions
      // above the numeric keyboard layout.
      autoCorrect={false}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
}

function IOSAccessoryView({
  nativeId,
  returnKeyLabel,
  onSubmitEditing,
}: {
  nativeId: string
  returnKeyLabel?: string
  onSubmitEditing?: () => void
}) {
  const { translations } = useKassenAppUI()

  const onPress = () => {
    onSubmitEditing?.()
    Keyboard.dismiss()
  }

  return (
    <InputAccessoryView nativeID={nativeId} backgroundColor="white">
      <Row
        alignCenter
        padRight="XS"
        padVertical="XXS"
        style={{ justifyContent: "flex-end" }}
      >
        <LinkLabel
          h5
          color="primary"
          textAlign="center"
          text={returnKeyLabel ?? translations.done}
          onPress={onPress}
        />
      </Row>
    </InputAccessoryView>
  )
}

// Show accessory view on iOS for following keyboard layouts:
const LAYOUTS_ACCESSORY_VIEW = ["number-pad", "decimal-pad"]

function IOSNumberInput<T extends Big | number>({
  returnKeyType,
  returnKeyLabel,
  numberInputProps,
  hideIOSDoneButton = false,
  ...props
}: IOSNumberInputProps<T>) {
  const accessoryViewId = useRef(createId()).current
  const showAccessoryView =
    !hideIOSDoneButton &&
    LAYOUTS_ACCESSORY_VIEW.includes(numberInputProps.keyboardType)

  return (
    <>
      <GeneralNumberInput
        {...props}
        numberInputProps={numberInputProps}
        returnKeyType={!hideIOSDoneButton ? returnKeyType : undefined}
        inputAccessoryViewID={showAccessoryView ? accessoryViewId : undefined}
      />
      {showAccessoryView && (
        <IOSAccessoryView
          nativeId={accessoryViewId}
          returnKeyLabel={returnKeyLabel}
          onSubmitEditing={numberInputProps.onSubmitEditing}
        />
      )}
    </>
  )
}
