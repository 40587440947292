import React from "react"
import { View } from "react-native"

import { useColor } from "../hooks/useColor"
import { Color } from "../types"

type SeparatorProps = { color?: Color }

export function RowSeparator({ color }: SeparatorProps) {
  return (
    <View
      style={[{ height: 1 }, { backgroundColor: useColor(color ?? "base5") }]}
    />
  )
}

export function ColumnSeparator({ color }: SeparatorProps) {
  return (
    <View
      style={[{ width: 1 }, { backgroundColor: useColor(color ?? "base5") }]}
    />
  )
}
