import {
  differenceInSeconds,
  format,
  formatISO,
  isValid,
  parse,
} from "date-fns"

import { isValidDate, isValidDateTime } from "./Validation"

// Constant definitions

const DATE_FORMAT = "dd.MM.yyyy"
const TIME_FORMAT = "HH:mm"
const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`
const DATETIME_FORMAT_SECONDS = `${DATE_FORMAT} ${TIME_FORMAT}:ss`

// Type definitions

// ISO 8601 encoded date
export type ISODate = string

export type DateLike = Date | ISODate | number

// Logic definitions

// Returns the number of seconds left until the given date is passed.
export function secondsToDate(date: DateLike): number {
  return differenceInSeconds(asDate(date), Date.now())
}

// Formats passed date to a human-readable German dateTime string.
export function formatDateTime(
  date: DateLike,
  opts?: { midnightToPrevious?: boolean; showSeconds?: boolean },
) {
  const parsedDate = asDate(date)
  const includeSeconds = opts?.showSeconds ?? false
  const midnightBehavior = opts?.midnightToPrevious ?? false

  const formatString = includeSeconds
    ? DATETIME_FORMAT_SECONDS
    : DATETIME_FORMAT

  const isMidnight =
    midnightBehavior &&
    parsedDate.getHours() == 0 &&
    parsedDate.getMinutes() == 0

  if (isMidnight) parsedDate.setDate(parsedDate.getDate() - 1)

  let formattedDate = format(parsedDate, formatString)

  if (isMidnight) {
    const splitDateTime = formattedDate.split(" ")
    const splitTime = splitDateTime[1].split(":")

    splitTime[0] = "24"

    formattedDate = `${splitDateTime[0]} ${splitTime.join(":")}`
  }

  return formattedDate
}

// Formats passed date to a human-readable German date string.
export function formatDate(date: DateLike, midnightToPrevious?: boolean) {
  const parsedDate = asDate(date)
  const midnightBehavior = midnightToPrevious ?? false

  const isMidnight =
    midnightBehavior &&
    parsedDate.getHours() == 0 &&
    parsedDate.getMinutes() == 0

  if (isMidnight) parsedDate.setDate(parsedDate.getDate() - 1)

  return format(parsedDate, DATE_FORMAT)
}

// Formats passed date to a human-readable German time string.
export function formatTime(date: DateLike, midnightToPrevious?: boolean) {
  const parsedDate = asDate(date)
  const midnightBehavior = midnightToPrevious ?? false
  const formattedTime = format(parsedDate, TIME_FORMAT)

  const isMidnight =
    midnightBehavior &&
    parsedDate.getHours() == 0 &&
    parsedDate.getMinutes() == 0

  return isMidnight ? formattedTime.replace("00:", "24:") : formattedTime
}

// Parses a formated dateTimeString to a Date object.
export function parseDateTime(dateTimeString: string): Date {
  return parse(dateTimeString, DATETIME_FORMAT, new Date())
}

// Parses a formated dateString to a Date object.
export function parseDate(dateString: string): Date {
  return parse(dateString, DATE_FORMAT, new Date())
}

// Parses a formated timeString to a Date object.
export function parseTime(timeString: string): Date {
  return parse(timeString, TIME_FORMAT, new Date())
}

// Tries to format an input to an ISO dateTime string.
export function formatIsoDateTime(date: DateLike): string
export function formatIsoDateTime(date?: DateLike): string | undefined
export function formatIsoDateTime(date?: DateLike): string | undefined {
  if (!date) return undefined
  return asDate(date).toISOString()
}

// Tries to format an input to an ISO date string.
export function formatIsoDate(date: DateLike): string
export function formatIsoDate(date?: DateLike): string | undefined
export function formatIsoDate(date?: DateLike): string | undefined {
  if (!date) return undefined
  return formatISO(asDate(date), { representation: "date" })
}

// Tries to format an input to an ISO time string.
export function formatIsoTime(date: DateLike): string
export function formatIsoTime(date?: DateLike): string | undefined
export function formatIsoTime(date?: DateLike): string | undefined {
  if (!date) return undefined
  return formatISO(asDate(date), { representation: "time" })
}

export function validateDate(mode: "date" | "datetime", date: Date) {
  return mode == "date"
    ? isValidDate(formatDate(date))
    : isValidDateTime(formatDateTime(date))
}

function asDate(date: DateLike): Date {
  if (date instanceof Date) return date

  if (typeof date == "string") {
    const parsedDate = new Date(date)
    if (isValid(parsedDate)) return parsedDate
    else return parse(date, "yyyy-MM-dd'T'HH:mm:ssXX", new Date())
  }

  if (typeof date == "number") return new Date(date)
  else throw Error(`Invalid dateLike ${date}`)
}
