import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

export type MainGroupId = string

export type MainGroup = {
  mainGroupId: MainGroupId
  name: string
  taxRate: number
  orderIndex: number

  description?: string | null
}

export const MainGroupEntity = createEntityFor<MainGroup>()
  .withSchema(
    z.object({
      mainGroupId: z.string(),
      name: z.string(),
      taxRate: z.number(),
      orderIndex: z.number(),
      description: z.string().optional().nullable(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
