import React, { lazy, ReactNode, useMemo } from "react"
import { useTranslation } from "react-i18next"

import {
  KassenAppUIProvider,
  KassenAppUITranslations,
} from "@axtesys/kassen-app-ui"

import { useFrontend } from "../../Config/queries"
import { useTranslations } from "../hooks"

const AdminTranslationProvider = lazy(
  () => import("./AdminTranslationProvider"),
)
const WebTranslationProvider = lazy(() => import("./WebTranslationProvider"))
const AppTranslationProvider = lazy(() => import("./AppTranslationProvider"))

export function TranslationProvider({ children }: { children: ReactNode }) {
  return (
    <ApplicationTranslationProvider>
      <LibraryTranslationProvider>{children}</LibraryTranslationProvider>
    </ApplicationTranslationProvider>
  )
}

function ApplicationTranslationProvider({ children }: { children: ReactNode }) {
  switch (useFrontend()) {
    case "admin":
      return <AdminTranslationProvider>{children}</AdminTranslationProvider>
    case "web":
      return <WebTranslationProvider>{children}</WebTranslationProvider>
    case "app":
      return <AppTranslationProvider>{children}</AppTranslationProvider>
    default:
      return children
  }
}

function LibraryTranslationProvider({ children }: { children: ReactNode }) {
  return (
    <KassenAppUIProvider translations={useKassenAppUITranslations()}>
      {children}
    </KassenAppUIProvider>
  )
}

function useKassenAppUITranslations(): KassenAppUITranslations {
  const { i18n } = useTranslation()
  const { tEnums, tGeneral, tValidations, tReports } = useTranslations()

  return useMemo(
    () => ({
      languageCode: i18n.language,
      to: tGeneral("to"),
      note: tGeneral("note"),
      from: tGeneral("from"),
      done: tGeneral("done"),
      today: tGeneral("today"),
      cancel: tGeneral("cancel"),
      delete: tGeneral("delete"),
      confirm: tGeneral("confirm"),
      understood: tGeneral("understood"),
      selectDate: tGeneral("selectDate"),
      selectionLimit: tReports("selectionLimit"),
      validationDate: tValidations("validationDate"),
      emptyOptionMessage: tGeneral("emptyOptionMessage"),
      validationDateRange: tValidations("validationDateRange"),
      validationPriceRange: tValidations("validationPriceRange"),
      durations: tEnums("durations", { returnObjects: true }) as Record<
        string,
        string
      >,
    }),
    [i18n.language, tEnums, tGeneral, tReports, tValidations],
  )
}
