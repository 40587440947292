import * as Device from "expo-device"
import {
  addOrientationChangeListener,
  getOrientationAsync,
  Orientation,
} from "expo-screen-orientation"
import { Platform } from "react-native"
import { atom, useRecoilValue } from "recoil"

import { useScreenSizeType } from "@axtesys/kassen-app-ui"

import { useNumericScreenSize } from "./useNumericScreenSize"

type ScreenOrientation = "portrait" | "landscape" | "unknown"

export function useScreenOrientation(): ScreenOrientation {
  const screenSize = useNumericScreenSize()
  const screenSizeType = useScreenSizeType()
  const orientation = useRecoilValue(screenOrientationState)

  if (Platform.OS == "web") {
    switch (screenSizeType) {
      case "L":
        return "landscape"
      default:
        return "portrait"
    }
  }

  // Optimised workaround for Android and iOS (even iPads).
  // Since iOS 9, iPads are always in landscape mode, unless you open two
  // applications side by side.
  const osVersion = Device.osVersion

  const isInLandscapeMode = screenSize.width >= screenSize.height
  const isIosVersionGreaterOrEqualNine =
    parseInt(osVersion?.substring(0, osVersion?.indexOf(".")) ?? "9") >= 9

  if (
    !(Platform.OS == "ios") ||
    !Platform.isPad ||
    !isIosVersionGreaterOrEqualNine ||
    (isInLandscapeMode && orientation == "landscape")
  ) {
    return orientation
  } else if (isInLandscapeMode) {
    return "landscape"
  } else return "portrait"
}

function mapOrientation(orientationEnum: Orientation) {
  switch (orientationEnum) {
    case Orientation.PORTRAIT_UP:
    case Orientation.PORTRAIT_DOWN:
      return "portrait"
    case Orientation.LANDSCAPE_LEFT:
    case Orientation.LANDSCAPE_RIGHT:
      return "landscape"
    default:
      return "landscape"
  }
}

const screenOrientationState = atom({
  key: "screenOrientation",
  default: getOrientationAsync().then(mapOrientation),
  effects: [
    ({ setSelf }) => {
      const orientationListener = addOrientationChangeListener(
        ({ orientationInfo: { orientation } }) => {
          setSelf(mapOrientation(orientation))
        },
      )

      return () => orientationListener.remove()
    },
  ],
})
