import React from "react"

import { Column } from "../layout/FlexBox"
import { useTheme } from "../theme"
import { Label } from "../typography/Label"

type QuickSelectButtonProps = {
  text: string
  onPress(): void

  subText?: string
  isSelected?: boolean
}

export function QuickSelectButton(props: QuickSelectButtonProps) {
  const { color } = useTheme()

  const { text, subText, isSelected, onPress } = props
  const textColor = isSelected ? "inverted" : "default"
  const backgroundColor = isSelected ? color.primary : color.background

  return (
    <Column
      alignCenter
      pad="XS"
      borderRadius="L"
      borderColor={color.primary}
      backgroundColor={backgroundColor}
      onPress={onPress}
    >
      <Label h5 text={text} color={textColor} />
      {subText && <Label small singleLine text={subText} color={textColor} />}
    </Column>
  )
}
