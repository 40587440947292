import React from "react"
import { ActivityIndicator } from "react-native"

import { BoxProps } from "../layout/Box"

export function LoadingIndicator({
  backgroundColor,
}: Pick<BoxProps, "backgroundColor">) {
  return (
    <ActivityIndicator
      size="large"
      color="#B71E3F"
      style={{ backgroundColor, flex: 1 }}
    />
  )
}
