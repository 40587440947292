import { useState } from "react"

type ToggleFunction = () => void
type SetValue = (value: boolean) => void

export function useToggle(
  initialValue: boolean,
): [boolean, ToggleFunction, SetValue] {
  const [value, setValue] = useState(initialValue)

  const toggle = () => setValue(prevValue => !prevValue)

  return [value, toggle, setValue]
}
