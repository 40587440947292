import { isEmptyOrBlank } from "@axtesys/react-tools"

// Given a `list` and a `searchString`, returns all items from the list that contain
// the string, looking at all fields selected by `stringSelectors` and comparing those
// case-insensitive with the `searchString`.
// If the `searchString` is empty or blank, all items are returned.

export function filterSearch<T>(
  list: T[],
  searchString: string | undefined,
  stringsSelector: (item: T) => Array<string | undefined>,
): T[] {
  if (isEmptyOrBlank(searchString)) return list

  const searchStr = searchString!.toLowerCase()

  return list.filter(item => {
    for (const currentSearchPart of stringsSelector(item)) {
      if (!currentSearchPart) continue
      if (currentSearchPart.toLowerCase().includes(searchStr)) return true
    }
    return false
  })
}

export function matchesSearch<T>(
  item: T,
  searchString: string | undefined,
  stringsSelector: (item: T) => Array<string | undefined>,
): boolean {
  if (isEmptyOrBlank(searchString)) return true

  const searchStr = searchString!.toLowerCase()

  for (const currentSearchPart of stringsSelector(item)) {
    if (!currentSearchPart) continue
    if (currentSearchPart.toLowerCase().includes(searchStr)) return true
  }

  return false
}
