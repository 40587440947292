import Big from "big.js"
import React, { useMemo } from "react"
import { StyleProp, TextStyle, ViewStyle } from "react-native"

import { MCIcon } from "../../display/MCIcon"
import { useTheme } from "../../theme"
import { NumberInput, NumberInputProps } from "./NumberInput"
import { IconConfig } from "./TextInput"

type CustomProps = {
  value?: Big
  mini?: boolean
  revertOnCancel?: boolean
  currencySymbol?: "€" | "%"
  onCancelBehavior?: "submit" | "ignore" | "revert"

  onChange?(value?: Big): void
  onSubmit?(value?: Big): void
}

type MoneyInputProps = Omit<
  NumberInputProps<Big>,
  "value" | "onChange" | "onSubmit"
> &
  CustomProps

export function MoneyInput(props: MoneyInputProps) {
  return (
    <NumberInput
      {...useMoneyInputProps(props)}
      isBig
      selectTextOnFocus
      returnKeyType="done"
    />
  )
}

function useMoneyInputProps(props: MoneyInputProps) {
  const { fontFamily } = useTheme()

  const currencyIcon = useMemo<MCIcon | undefined>(() => {
    if (props.mini) return undefined
    if (props.currencySymbol == "%") return "percent"
    else return "currency-eur"
  }, [props.currencySymbol, props.mini])

  const iconLeft = currencyIcon
    ? ({ size: "XS", name: currencyIcon } as IconConfig)
    : undefined

  const style: StyleProp<ViewStyle & TextStyle> = useMemo(() => {
    const fontFamilyType = fontFamily.sourceSansPro

    return [
      {
        width: "100%",
        marginRight: 10,
        textAlign: "right",
        fontFamily: fontFamilyType.bold,
      },
      props.mini && {
        padding: 0,
        marginRight: 0,
        fontFamily: fontFamilyType.regular,
      },
      props.style,
    ]
  }, [fontFamily.sourceSansPro, props.mini, props.style])

  return {
    ...props,
    style,
    iconLeft,
    onCancelBehavior: props.revertOnCancel
      ? "revert"
      : props.onCancelBehavior ?? "submit",
    minNumberOfDecimalPlaces: props.minNumberOfDecimalPlaces ?? 2,
    maxNumberOfDecimalPlaces: props.maxNumberOfDecimalPlaces ?? 2,
    onChange: props.onChange,
    onSubmit: props.onSubmit ?? props.onChange,
  }
}
