import React, { ReactNode, useCallback, useRef } from "react"
import { Keyboard, Platform } from "react-native"
import DrawerLayout, {
  DrawerState,
} from "react-native-gesture-handler/DrawerLayout"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import {
  useBackButtonEffect,
  useDeviceDependentValue,
  useNumericScreenSize,
} from "@axtesys/hooks"

import { Box } from "../layout/Box"
import { Row } from "../layout/FlexBox"
import { useTheme } from "../theme"

type DrawerController = {
  isOpen: boolean
  instance: DrawerLayout | null
  open: () => void
  close: () => void
}

type DrawerContainerProps = {
  children: ReactNode
  controller: DrawerController
  renderContent: () => ReactNode

  disabled?: boolean
  anchored?: boolean
  onOpen?: () => void
  onClose?: () => void
}

export { DrawerContainer, useDrawerController, useDrawerContainerWidth }

function DrawerContainer(props: DrawerContainerProps) {
  const { anchored, children, renderContent } = props

  const drawerView = <DrawerView renderContent={renderContent} />

  return anchored ? (
    <Row expand>
      {drawerView}
      {children}
    </Row>
  ) : (
    <ExtendableDrawer {...props} renderDrawerView={() => drawerView}>
      {children}
    </ExtendableDrawer>
  )
}

function DrawerView(props: Pick<DrawerContainerProps, "renderContent">) {
  const { top } = useSafeAreaInsets()
  const {
    color: { background: backgroundColor },
  } = useTheme()
  const maxWidth = useDrawerContainerWidth()

  // Required in order that the navigation view
  // has the correct height and works as desired with the ExtendedScreenLayout.
  // Special case: "web" - there the AppHeader has to be considered.
  const height =
    useNumericScreenSize().height - (Platform.OS != "web" ? top : 50)

  return (
    <Box expand style={{ height, maxWidth, backgroundColor, zIndex: 1000 }}>
      {props.renderContent()}
    </Box>
  )
}

function ExtendableDrawer({
  children,
  disabled,
  controller,
  onOpen,
  onClose,
  renderDrawerView,
}: DrawerContainerProps & { renderDrawerView: () => ReactNode }) {
  const maxWidth = useDrawerContainerWidth()
  const onDrawerStateChanged = useCallback(
    (drawerState: DrawerState, drawerWillShow: boolean) => {
      if (drawerState != "Settling") return

      if (drawerWillShow) {
        controller.open()
        onOpen?.()
      } else {
        controller.close()
        onClose?.()
      }
    },
    [controller, onClose, onOpen],
  )

  return (
    <DrawerLayout
      drawerType="front"
      drawerPosition="left"
      drawerWidth={maxWidth}
      ref={instance => {
        controller.instance = instance
      }}
      // DrawerLayout seems to have problems on Android
      // when useNativeAnimations flag is set to true (default value).
      // Therefore, disable useNativeAnimations for Android only.
      //
      // Related GitHub issues:
      // https://github.com/software-mansion/react-native-gesture-handler/issues/2208
      // https://github.com/software-mansion/react-native-gesture-handler/issues/2707
      useNativeAnimations={Platform.OS != "android"}
      drawerLockMode={disabled == true ? "locked-closed" : "unlocked"}
      renderNavigationView={renderDrawerView}
      onDrawerStateChanged={onDrawerStateChanged}
    >
      {children}
    </DrawerLayout>
  )
}

function useDrawerController(): DrawerController {
  const controllerRef = useRef<DrawerController | undefined>()

  const open = useCallback(() => {
    if (controllerRef.current!.isOpen) return

    controllerRef.current!.isOpen = true
    controllerRef.current!.instance?.openDrawer()
  }, [])

  const close = useCallback(() => {
    if (!controllerRef.current!.isOpen) return

    Keyboard.dismiss()
    controllerRef.current!.isOpen = false
    controllerRef.current!.instance?.closeDrawer()
  }, [])

  if (!controllerRef.current) {
    controllerRef.current = { isOpen: false, instance: null, open, close }
  }

  const controller = controllerRef.current

  // Close the drawer even when
  // a hardware back button is pressed.
  useBackButtonEffect(() => {
    if (controller.isOpen) {
      controller.close()
      return true
    }
    return null
  })

  return controller
}

const DRAWER_WIDTH = { desktop: 270, tablet: 270, phone: 250 }
function useDrawerContainerWidth() {
  return useDeviceDependentValue(DRAWER_WIDTH)
}
