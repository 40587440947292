import i18next, { KeyPrefix, Namespace, TFunction } from "i18next"

export function t_Dialogs() {
  return { tDialogs: tX("dialogs") }
}

export function t_Enums() {
  return { tEnums: tX("enums") }
}

export function t_General() {
  return { tGeneral: tX("general") }
}

export function t_Reports() {
  return { tReports: tX("reports") }
}

export function t_Validations() {
  return { tValidations: tX("validations") }
}

export function t_Errors() {
  return { tErrors: tX("errors") }
}

export function t_Navigation() {
  return { tNavigation: tX("navigation") }
}

export function t_Screens<KPrefix extends KeyPrefix<"screens">>(
  keyPrefix?: KPrefix,
) {
  return { tScreen: tX("screens", keyPrefix) }
}

export function t_Translations<KPrefix extends KeyPrefix<"screens">>(
  keyPrefix?: KPrefix,
) {
  return {
    ...t_Dialogs(),
    ...t_Enums(),
    ...t_General(),
    ...t_Reports(),
    ...t_Validations(),
    ...t_Errors(),
    ...t_Navigation(),
    ...t_Screens(keyPrefix),
  }
}

export function tX<Ns extends Namespace, KPrefix extends KeyPrefix<Ns>>(
  ns: Ns,
  keyPrefix?: KPrefix,
): TFunction<Ns, KPrefix> {
  return i18next.getFixedT(i18next.language, ns, keyPrefix)
}
