import { keys } from "lodash"
import * as z from "zod"

import { jsonDbAtom } from "@axtesys/react-tools"

import versionInfo from "~src/versionInfo.json"

export type BackendType = keyof typeof baseUrls

export const baseUrls = {
  localhost: "http://localhost:8080",

  // develop: "https://develop.maxundben.at",
  test: "https://test.maxundben.at",

  // staging: "https://staging.maxundben.at",
  live: "https://app.maxundben.at",
}

// Export available Backends for the DevSwitcher.
export const backends = keys(baseUrls)

// User facing names for the backends.
// `develop` is only available for DEV builds.
export const backendNames: Record<string, string> = {
  live: "Live",
  // staging: "Staging",
  test: "Test",
}
// if (__DEV__) backendNames.develop = "Entwicklung"
if (__DEV__) backendNames.localhost = "Lokal"

// We store the selected value in AsyncStorage.
// In production builds, this defaults to "live",
// but can also be set to "test" in <ManagerLoginScreen>.
// In dev builds, we have the <DevSwitcher>
// which allows selection of any backend.
export const backendState = jsonDbAtom({
  key: "backend",
  default: __DEV__ ? "localhost" : versionInfo.branch,
  schema: z.string(),
})
