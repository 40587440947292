import React from "react"

import { isEmptyOrBlank } from "@axtesys/react-tools"

import { MCIcon } from "../display/MCIcon"
import { useScreenSizeDependentValue } from "../hooks/useScreenSizeDependentValue"
import { TextInput } from "./field/TextInput"

export type SearchBarProps = {
  value: string
  onChange: (value: string) => void

  placeholder?: string
  includeLeftPadding?: boolean
}

const HEIGHT = 48
const WIDTH = { S: 300, M: 300, L: 400 }

export function SearchBar(props: SearchBarProps) {
  const { value, placeholder, includeLeftPadding, onChange } = props

  const isCleared = isEmptyOrBlank(value)
  const iconRight = {
    name: (isCleared ? "magnify" : "close") as MCIcon,
    onPress: !isCleared ? () => onChange("") : undefined,
  }

  const style = {
    height: HEIGHT,
    paddingLeft: includeLeftPadding ? 10 : 0,
    width: useScreenSizeDependentValue(WIDTH),
  }
  const containerStyle = { height: HEIGHT, paddingRight: 10 }

  return (
    <TextInput
      value={value}
      style={style}
      fontSize="medium"
      iconRight={iconRight}
      placeholder={placeholder}
      containerStyle={containerStyle}
      onChange={onChange}
    />
  )
}
