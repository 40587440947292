import Big, { BigSource } from "big.js"
import { reduce } from "lodash"

export type FormatDecimalOpts = {
  minNumberOfDecimalPlaces?: number
  withoutThousandSeparator?: boolean
}

export function toBig(src?: BigSource): Big | undefined {
  if (src == undefined) return src
  return Big(src)
}

export function bigEquals(a?: Big, b?: Big): boolean {
  if ((a == undefined) != (b == undefined)) return false
  if (a == undefined || b == undefined) return true
  return a.eq(b)
}

export function areBigEquals(a?: Big, b?: BigSource) {
  if (a == undefined && b == undefined) return true
  if (a == undefined || b == undefined) return false
  return Big(a).eq(b)
}

export function sumBigArray(array: Big[]): Big {
  return reduce(array, (sum, addition) => sum.plus(addition), Big(0))
}

export function maxOfBigs(array: Big[]): Big | undefined {
  let max: Big | undefined
  for (const item of array) if (!max || item.gt(max)) max = item
  return max
}

export function minOfBigs(array: Big[]): Big | undefined {
  let min: Big | undefined
  for (const item of array) if (!min || item.lt(min)) min = item
  return min
}

export function clamp(numberInput: Big, lowerInput?: Big, upperInput?: Big) {
  let number = Big(+numberInput)
  const lower = lowerInput ? Big(+lowerInput) : undefined
  const upper = upperInput ? Big(+upperInput) : undefined

  if (number.eq(numberInput)) {
    if (upper != undefined) number = number.lte(upper) ? number : upper
    if (lower != undefined) number = number.gte(lower) ? number : lower
  }

  return number
}

export function formatDecimal(value?: BigSource, opts?: FormatDecimalOpts) {
  if (!value) return ""

  const separatorSanitized = (
    typeof value == "string" ? value : Big(value).toString()
  ).replace(".", ",")

  if (opts?.withoutThousandSeparator) return separatorSanitized

  const parts = separatorSanitized.split(",")
  const integerPart = parts[0]
    .replace(/\B(?=(\d{3})+(?!\d))/g, part => `.${part}`)
    .replace(/^\./, "")

  if (opts?.minNumberOfDecimalPlaces) {
    const { minNumberOfDecimalPlaces } = opts
    parts[1] =
      parts[1]?.padEnd(minNumberOfDecimalPlaces, "0") ??
      "0".repeat(minNumberOfDecimalPlaces)
  }
  const floatingPart = parts?.[1] ? `,${parts[1]}` : ""

  return `${integerPart}${floatingPart}`
}

export function formatAmount(amount?: BigSource) {
  return amount ? formatDecimal(Big(amount).round(3)) : ""
}
