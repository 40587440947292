import { mapValues } from "lodash"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { KeysMainGroupInput } from "../api/graphql/types"
import { CashRegisterId } from "./CashRegister"
import {
  KeysMainGroupContent,
  KeysMainGroupId,
  SpecificKeysMainGroupContent,
  SpecificKeysMainGroupContentEntity,
} from "./KeysMainGroup"

export type KeyboardLayoutId = string

export type KeyboardLayoutContentInfo = {
  name: string
  columnCountPortrait: number
  columnCountLandscape: number
  assignedCashRegisters: CashRegisterId[]
  content: KeysMainGroupContent[]

  keyboardLayoutId?: KeyboardLayoutId
}

export type InputKeyboardLayoutInfo = Omit<
  KeyboardLayoutContentInfo,
  "content"
> & { content: KeysMainGroupInput[] }

export type SpecificKeyboardLayout = Omit<
  KeyboardLayoutContentInfo,
  "keyboardLayoutId" | "assignedCashRegisters" | "content"
> & { keysMainGroups: Record<KeysMainGroupId, SpecificKeysMainGroupContent> }

export const SpecificKeyboardLayoutEntity = createEntityFor<
  SpecificKeyboardLayout | undefined
>()
  .withSchema(
    z.optional(
      z.object({
        name: z.string(),
        columnCountPortrait: z.number(),
        columnCountLandscape: z.number(),
        keysMainGroups: z.record(SpecificKeysMainGroupContentEntity.schema),
      }),
    ),
  )
  .serialize(data =>
    data
      ? {
          ...data,
          keysMainGroups: mapValues(
            data.keysMainGroups,
            SpecificKeysMainGroupContentEntity.serialize,
          ),
        }
      : undefined,
  )
  .deserialize(json =>
    json
      ? {
          ...json,
          keysMainGroups: mapValues(
            json.keysMainGroups,
            SpecificKeysMainGroupContentEntity.deserialize,
          ),
        }
      : undefined,
  )
