import { Platform } from "react-native"

type PlatformKey = "android" | "ios" | "web" | "default"
type Option = { [Platform in PlatformKey]?: any }
type PlatformValue<T extends Option> = {
  [Platform in PlatformKey]: unknown extends T[Platform]
    ? undefined
    : T[Platform]
}[PlatformKey]

export function decideOnPlatform<T extends Option>(
  options: T,
): PlatformValue<T> {
  switch (Platform.OS) {
    case "ios":
      return options.ios ?? options.default
    case "android":
      return options.android ?? options.default
    case "web":
      return options.web ?? options.default
    default:
      return options.default
  }
}

export function isIOSLowerEqual16() {
  return (
    Platform.OS == "ios" &&
    parseInt(Platform.constants.osVersion.split(".")?.[0]) <= 16
  )
}

export function isIOSGreaterEqual16() {
  return (
    Platform.OS == "ios" &&
    parseInt(Platform.constants.osVersion.split(".")?.[0]) >= 16
  )
}
