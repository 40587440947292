import React, { createContext, ReactNode } from "react"
import { ActivityIndicator } from "react-native"

// Creates a custom React context and returns...
// - a `Provider` that sets up the `ContextValue`
//   through being mounted on some higher level within the component stack AND
// - a hook that provides access to the `ContextValue` in related child components.
// This can be used to reduce the need for "prop drilling" in nested components.
//
// If the context value has a truthy `isLoading` property,
// the `Provider` will render an `ActivityIndicator`
// instead of its children, so that descendants of this context
// will not access it while it is loading.
export function createCustomContext<ContextProps, ContextValue>(
  useCreateContext: (props: ContextProps) => ContextValue,
) {
  const context = createContext<ContextValue>(null as any)

  function Provider(props: ContextProps & { children?: ReactNode }) {
    const contextValue = useCreateContext(props)
    const isLoading = (contextValue as any)?.isLoading

    return (
      <context.Provider value={contextValue}>
        {isLoading ? (
          <ActivityIndicator size="large" color="#B71E3F" style={{ flex: 1 }} />
        ) : (
          props.children
        )}
      </context.Provider>
    )
  }

  function useContext(): Omit<ContextValue, "isLoading"> {
    return React.useContext(context)
  }

  return [Provider, useContext] as const
}
