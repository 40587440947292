import React, { ReactNode, useCallback } from "react"

import { useSelection } from "~shared/components/providers/SelectionProvider"

import { Button } from "../../button/Button"
import { Overlay } from "../../container/Overlay"
import { Icon } from "../../display/Icon"
import { Box } from "../../layout/Box"
import { Row } from "../../layout/FlexBox"
import { Gap } from "../../layout/Gap"
import { Spacer } from "../../layout/Spacer"
import { useTheme } from "../../theme"
import {
  SharedDialogAction,
  SharedDialogConfig,
  UseSharedDialog,
} from "../../types"
import { Label } from "../../typography/Label"
import { useKassenAppUI } from "../KassenAppUIProvider"
import { DIALOG_ELEVATION, dialogStyles } from "./DialogProvider"
import {
  InternalDialogContextProvider,
  useInternalDialogContext,
} from "./InternalDialogContextProvider"

type TitleDialogConfig = Pick<SharedDialogConfig, "title">

type ShowConfirmDialog = (
  config: TitleDialogConfig &
    Pick<SharedDialogAction, "label"> & {
      // The message of the dialog can only be a regular string.
      message: string
    },
) => Promise<boolean>

type UseSimpleDialog = Pick<UseSharedDialog, "closeTopDialog"> & {
  // Shows a simple dialog with a 'Confirm' button.
  showConfirmDialog: ShowConfirmDialog
}

type SimpleDialogProps = TitleDialogConfig & {
  // The message of the dialog.
  children: string

  // There always must be an action,
  // otherwise the dialog would not make much sense.
  action: SharedDialogAction

  // Handler that is executed,
  // when the close icon of the dialog is pressed.
  onCloseIconPress?: SharedDialogAction["onPress"]
}

const OVERLAY_Z_INDEX = 1000

export { SimpleDialogProvider, useSimpleDialog }

function SimpleDialogProvider({ children }: { children: ReactNode }) {
  return (
    <InternalDialogContextProvider>
      {children}
      <SimpleDialogOverlay />
    </InternalDialogContextProvider>
  )
}

function useSimpleDialog(): UseSimpleDialog {
  const { translations } = useKassenAppUI()
  const { showDialog, closeTopDialog } = useInternalDialogContext()

  const showConfirmDialog: ShowConfirmDialog = useCallback(
    ({ title, message, label }) =>
      new Promise<boolean>(resolve => {
        const onPress = () => resolve(true)

        const onCloseIconPress = () => {
          onPress()
          closeTopDialog()
        }

        showDialog(
          <SimpleDialog
            title={title ?? translations.note}
            action={{ label: label ?? translations.understood, onPress }}
            onCloseIconPress={onCloseIconPress}
          >
            {message}
          </SimpleDialog>,
        )
      }),
    [closeTopDialog, showDialog, translations.note, translations.understood],
  )

  return { showConfirmDialog, closeTopDialog }
}

function SimpleDialogOverlay() {
  const { selectionInProgress } = useSelection()
  const { activeDialogs } = useInternalDialogContext()

  const activeDialogsLength = activeDialogs.length

  if (activeDialogsLength <= 0) return null

  const dialogOverlays = activeDialogs.map((dialog, index) => {
    const isNotLastElement = index != activeDialogsLength - 1
    const display = isNotLastElement || selectionInProgress ? "none" : undefined

    return (
      <Overlay
        centerContent
        zIndex={OVERLAY_Z_INDEX}
        noBackdrop={isNotLastElement}
        key={`activeSimpleDialog_${index}`}
      >
        <Box pad="XS" style={[dialogStyles.overlayContainer, { display }]}>
          {dialog}
        </Box>
      </Overlay>
    )
  })

  return <>{dialogOverlays}</>
}

function SimpleDialog({ action, children, ...props }: SimpleDialogProps) {
  const { color } = useTheme()
  const { closeTopDialog } = useInternalDialogContext()

  const onPress = async () => {
    const result = await action.onPress?.()
    if (result != false) closeTopDialog()
  }

  const onCloseIconPress = async () => {
    if (!props.onCloseIconPress) closeTopDialog()
    else await props.onCloseIconPress()
  }

  const closeIcon = (
    <Row style={dialogStyles.closeIconContainer}>
      <Icon name="close" color="base3" onPress={onCloseIconPress} />
    </Row>
  )

  const title = props.title ? <Label h4 text={props.title} /> : null

  const gapAboveMessage = <Gap vertical="S" />

  const message = <Label text={children} />

  const button = (
    <Row gap="XS">
      <Spacer />
      <Button text={action.label} onPress={onPress} />
    </Row>
  )

  return (
    <Box
      pad="S"
      padTop="SM"
      borderRadius="L"
      borderColor={color.primary}
      elevation={DIALOG_ELEVATION}
      backgroundColor={color.background}
      style={dialogStyles.mainContainer}
    >
      {closeIcon}
      {title}
      {gapAboveMessage}
      {message}
      {button}
    </Box>
  )
}
