import { useEffect, useRef } from "react"

// Returns a boolean ref that indicates whether the component is still mounted.
// Useful if you want to call setState(...) after an asynchronous operation,
// where the component might become unmounted in the meantime.
export function useIsMounted() {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  return isMounted
}
