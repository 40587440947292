import { Big } from "big.js"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { ValueTransferFlagsInput } from "../api/graphql/types"
import { PaymentMethodId } from "./PaymentMethod"

export type DisplayValueTransfer = {
  paymentMethodId: PaymentMethodId
  methodName: string
  amount: Big
}

export type ValueTransfer = DisplayValueTransfer & {
  flags: ValueTransferFlagsInput
}

export const DisplayValueTransferEntity =
  createEntityFor<DisplayValueTransfer>()
    .withSchema(
      z.object({
        paymentMethodId: z.string(),
        methodName: z.string(),
        amount: z.string(),
      }),
    )
    .serialize(data => ({ ...data, amount: data.amount.toString() }))
    .deserialize(json => ({ ...json, amount: Big(json.amount) }))
