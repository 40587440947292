import { Ref, useCallback, useRef } from "react"
import { TextInput } from "react-native"

// A FocusChainLink represents one element in a FocusChain.
// Each TextInput element corresponds to one FocusChainLink.
// Pass these props to the TextInput element
// to automatically implement the focus transfer.
export type FocusChainLink = {
  ref: Ref<TextInput>
  onSubmitEditing: () => void
}

// A FocusChain links together multiple TextInput elements.
// When one element raises the `onSubmitEditing` event,
// the next link in the chain should get focused.
type FocusChain = {
  link: (index: number) => FocusChainLink
}

export function useFocusChain(): FocusChain {
  const indexedElementRecordRef = useRef(
    {} as Record<number, TextInput | null>,
  ).current

  const link = useCallback(
    (index: number) => {
      // Link the element with its focus chain index.
      const ref = (element: TextInput | null) => {
        indexedElementRecordRef[index] = element
      }

      let indexCounter = index
      const onSubmitEditing = () => {
        // Retrieve a possible next element within the focus chain.
        const nextElement = indexedElementRecordRef?.[indexCounter + 1]

        // Try to focus the element.
        nextElement?.focus()

        // In case the next element did not receive focus and
        // there is an after next element present,
        // do try to focus that one.
        if (
          (!nextElement || !nextElement.isFocused()) &&
          Object.keys(indexedElementRecordRef).length > indexCounter + 2
        ) {
          // The current next element did not receive focus,
          // therefore increase the counter by one...
          indexCounter++

          // ...and try to focus the after next element.
          onSubmitEditing()
        }
      }

      // Properties that should be added
      // to the corresponding TextInput element.
      return { ref, onSubmitEditing } as FocusChainLink
    },
    [indexedElementRecordRef],
  )

  return { link }
}
