import { findIndex, isEqual, some } from "lodash"

type Comparison<T> = (a: T, b: T) => boolean

// Converts a list of items to a record by selecting one property
// as key and another as value.
export function listToRecord<T, K extends string | number, V>(
  items: T[],
  keySelector: (item: T) => K,
  valueSelector: (item: T) => V,
): Record<K, V> {
  return Object.assign(
    {},
    ...items.map(item => ({
      [keySelector(item)]: valueSelector(item),
    })),
  )
}

// Returns a copy of the list where an item was added at the end.
// If the list already contained the item (via isEqual), it will be swapped out
// with the new item instance at the old index position.
export function addOrReplaceInList<T>(
  list: T[],
  item: T,
  isEqual?: Comparison<T>,
): T[] {
  const newList: T[] = []
  let found = false
  for (const itemInList of list) {
    if (isEqual ? isEqual(itemInList, item) : itemInList == item) {
      newList.push(item)
      found = true
    } else newList.push(itemInList)
  }

  if (!found) newList.push(item)

  return newList
}

// Returns a copy of the list where an item was added at the end.
// If the list already contained the item (via isEqual), it will be swapped out
// with the result of the merge function.
export function addOrMergeInList<T>(
  list: T[],
  item: T,
  merge: (itemInList: T, item: T) => T,
  isEqual?: Comparison<T>,
) {
  const newList: T[] = []
  let found = false
  for (const itemInList of list) {
    if (isEqual ? isEqual(itemInList, item) : itemInList == item) {
      newList.push(merge(itemInList, item))
      found = true
    } else newList.push(itemInList)
  }

  if (!found) newList.push(item)

  return newList
}

// Returns a copy of the list where
// every instance of the item (via isEqual) is removed.
export function removeFromList<T>(
  list: T[],
  item: T,
  isEqual?: Comparison<T>,
): T[] {
  const newList: T[] = []
  for (const itemInList of list) {
    if (isEqual ? isEqual(itemInList, item) : itemInList == item) {
      // omit
    } else newList.push(itemInList)
  }
  return newList
}

// Returns true if both arrays contain
// items that are equal by a custom equality checker.
export function arrayEquals<T>(
  a: T[] | undefined,
  b: T[] | undefined,
  itemEquals: Comparison<T>,
) {
  if (a == undefined && b == undefined) return true
  if (a == undefined || b == undefined) return false
  if (a.length != b.length) return false
  for (let i = 0; i < a.length; i++) if (!itemEquals(a[i], b[i])) return false
  return true
}

export function contains<T>(array: T[] | undefined, item: T) {
  return some(array, arrayItem => isEqual(arrayItem, item))
}

export function indexOf<T>(array: T[] | undefined, item: T) {
  return findIndex(array, arrayItem => isEqual(arrayItem, item))
}
