import React, { ReactNode, useCallback } from "react"
import { Keyboard, StyleSheet, useWindowDimensions } from "react-native"
import { atom, useRecoilState, useSetRecoilState } from "recoil"

import { Overlay } from "../../container/Overlay"
import { RowSeparator } from "../../display/Separator"
import { Box } from "../../layout/Box"
import { Column, Row } from "../../layout/FlexBox"
import { Gap } from "../../layout/Gap"
import {
  SharedDialogAction,
  SharedDialogConfig,
  ShowConfirmCancelDialog,
  ShowConfirmDialog,
  UseSharedDialog,
} from "../../types"
import { Label } from "../../typography/Label"
import { useKassenAppUI } from "../KassenAppUIProvider"

type DialogConfig = SharedDialogConfig & {
  // Action buttons / labels that should be displayed
  // bellow the content of the dialog.
  buttons: SharedDialogAction[]
}

type DialogResult = {
  // The index of the button that was pressed to close the dialog.
  // 'undefined' if the dialog was closed without a button press.
  pressedButtonWithIndex?: number
}

type ShowDialog = (config: DialogConfig) => Promise<DialogResult>
type ShowTaskRunnerDialog = <T>(
  config: SharedDialogConfig,
  task: () => Promise<T>,
) => Promise<T>

type UseAppDialog = UseSharedDialog & {
  // Shows a dialog with arbitrary buttons.
  showDialog: ShowDialog

  // Shows a dialog while a background task is running,
  // and closes it automatically once it finishes.
  showTaskRunnerDialog: ShowTaskRunnerDialog
}

type DialogConfigState = {
  config?: DialogConfig
  onClose?: (result: DialogResult) => void
}

export { AppDialogProvider, useAppDialog }

function AppDialogProvider({ children }: { children: ReactNode }) {
  return (
    <>
      {children}
      <DialogOverlay />
    </>
  )
}

function useAppDialog(): UseAppDialog {
  const { translations } = useKassenAppUI()
  const setDialogState = useSetRecoilState(appDialogConfigState)

  const showDialog: ShowDialog = useCallback(
    config => {
      hideKeyboard()

      return new Promise(resolve => {
        setDialogState({ config, onClose: resolve })
      })
    },
    [setDialogState],
  )

  const showConfirmDialog: ShowConfirmDialog = useCallback(
    ({ title, subTitle, label, message }) => {
      hideKeyboard()

      return new Promise(resolve => {
        setDialogState({
          config: {
            message,
            subTitle,
            title: title ?? translations.note,
            buttons: [{ label: label ?? translations.understood }],
          },
          onClose: () => requestAnimationFrame(() => resolve(true)),
        })
      })
    },
    [setDialogState, translations.note, translations.understood],
  )

  const showConfirmCancelDialog: ShowConfirmCancelDialog = useCallback(
    ({ title, subTitle, message, cancelLabel, confirmLabel }) => {
      hideKeyboard()

      return new Promise(resolve => {
        setDialogState({
          config: {
            message,
            subTitle,
            title: title ?? translations.note,
            buttons: [
              { label: cancelLabel ?? translations.cancel },
              { label: confirmLabel ?? translations.confirm },
            ],
          },
          onClose: result =>
            requestAnimationFrame(() =>
              resolve(result.pressedButtonWithIndex == 1),
            ),
        })
      })
    },
    [
      setDialogState,
      translations.cancel,
      translations.confirm,
      translations.note,
    ],
  )

  const showTaskRunnerDialog: ShowTaskRunnerDialog = useCallback(
    async (config, task) => {
      hideKeyboard()

      setDialogState({ config: { ...config, buttons: [] } })

      const result = await task()
      setDialogState({})

      return result
    },
    [setDialogState],
  )

  // Resets the dialog state, and calls any registered listener
  // to notify of the cancellation.
  const closeTopDialog: Function = useCallback(() => {
    let listener: DialogConfigState["onClose"]

    // Reset the dialog state.
    setDialogState(currentState => {
      listener = currentState.onClose
      return {}
    })

    // Then notify the listener, to avoid nested state updates.
    listener?.({ pressedButtonWithIndex: undefined })
  }, [setDialogState])

  return {
    showDialog,
    showConfirmDialog,
    showConfirmCancelDialog,
    showTaskRunnerDialog,
    closeTopDialog,
  }
}

function DialogOverlay() {
  const { width } = useWindowDimensions()
  const [{ config, onClose }, setDialogState] =
    useRecoilState(appDialogConfigState)

  if (!config) return null

  const title = config.title ? (
    <Label h4 padBottom="S" text={config.title} />
  ) : null

  const subTitle =
    typeof config.subTitle == "string" ? (
      <Label small padBottom="S" text={config.subTitle} />
    ) : config.subTitle ? (
      <>
        {config.subTitle}
        <Gap vertical="S" />
      </>
    ) : null

  const message =
    typeof config.message == "string" ? (
      <Label padBottom="S" text={config.message} />
    ) : config.message ? (
      <>
        {config.message}
        <Gap vertical="S" />
      </>
    ) : null

  const separator = <RowSeparator />

  const buttons = (
    <Row>
      {config.buttons.map(({ label, onPress }, index) => (
        <Box
          expand
          alignCenter
          key={index}
          padVertical="S"
          onPress={async () => {
            await onPress?.()
            onClose?.({ pressedButtonWithIndex: index })
            setDialogState({})
          }}
        >
          <Label h5 text={label} />
        </Box>
      ))}
    </Row>
  )

  return (
    <Overlay centerContent>
      <Column
        pad="S"
        padBottom="none"
        borderRadius="M"
        backgroundColor="white"
        style={[styles.mainContainer, { width: width - 20 }]}
      >
        {title}
        {subTitle}
        {message}
        {separator}
        {buttons}
      </Column>
    </Overlay>
  )
}

// Always hide an open keyboard
// when displaying a Dialog component.
function hideKeyboard() {
  Keyboard.dismiss()
}

const appDialogConfigState = atom({
  key: "appDialogConfig",
  default: {} as DialogConfigState,
})

const DIALOG_MAX_WIDTH = 400

const styles = StyleSheet.create({
  mainContainer: { alignSelf: "center", maxWidth: DIALOG_MAX_WIDTH },
})
