import { isEqual } from "lodash"
import React, { Ref, useMemo, useRef, useState } from "react"
import {
  ColorValue,
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  TextInput as RNTextInput,
  TextInputFocusEventData,
  TextInputProps as RNTextInputProps,
  TextStyle,
  ViewStyle,
} from "react-native"

import { FocusChainLink } from "@axtesys/hooks"
import { mergeRefs } from "@axtesys/react-tools"

import { Icon } from "../../display/Icon"
import { MCIcon } from "../../display/MCIcon"
import { Row } from "../../layout/FlexBox"
import { useTheme } from "../../theme"
import { Color, TextSize } from "../../types"

// This value regulates the height of the field container
// (the actual RNTextInput will expand to the size of the [parent] container).
const HEIGHT = 40

export const SPACING_CORRECTION = 8

export type IconConfig = {
  name: MCIcon
  color?: Color
  size?: "XS" | "S" | "M" | "L"
  onPress?: () => void
}
export type FocusBlurEvent = NativeSyntheticEvent<TextInputFocusEventData>

type ErrorIndicatorProps = { errorIndicator: boolean }
type AutoFocusHackConfig = { onFocus: () => void; autoFocus?: boolean }

type InheritedProps = Omit<
  RNTextInputProps,
  "ref" | "style" | "onChange" | "onSubmitEditing"
>
type OverwrittenProps = {
  // Style property which is directly applied
  // to the standard react native 'TextInput' component.
  style?: StyleProp<ViewStyle & TextStyle>

  onChange?: (value: string) => void
}
type CustomProps = {
  // States whether the input field and its icon(s) are disabled or not.
  // Setting this property to true sets the editable property to false.
  disabled?: boolean

  // Defines the font size of the entered input.
  // Defaults to 'default' (16pt)
  fontSize?: TextSize

  // Defines potentially clickable items
  // either on the left or right side of the input field.
  iconLeft?: IconConfig
  iconRight?: IconConfig

  // Pass in an individual focus chain link object
  // if there are multiple input fields in a form
  // and the focus should change from one field to another.
  focus?: FocusChainLink

  // States whether the input field
  // should have only a dynamically colored bottom line (flat) OR
  // is also encircled with a rounded corner box.
  // Defaults to 'flat'
  mode?: "flat" | "outlined"

  // For convenience reasons we pass a
  // possible error message to the input component.
  // It has no other use than displaying an
  // error state (color hint; message is not shown in here).
  errorMessage?: string

  // Changes the background color to a transparent background.
  transparent?: boolean

  // When this flag is set, the underline color will be displayed
  // as if there would not be a value present.
  // The color stays the same as long as the value is equal to the initial one.
  trackInitialValue?: boolean

  // If passed, overwrites the dynamic underline color determination process.
  underlineColor?: ColorValue

  // Can be used to pass a ref to the input field.
  textInputRef?: Ref<RNTextInput>

  // Style property which is applied to the (row) container of the InputField
  // (icons and the field itself are included in there).
  containerStyle?: StyleProp<ViewStyle>

  onPress?: () => void
  onSubmitEditing?: () => void
  onSubmitIfValid?: () => Promise<boolean>
  onBlur?: (event?: FocusBlurEvent) => void
  onFocus?: (event?: FocusBlurEvent) => void
}

export type TextInputProps = InheritedProps & OverwrittenProps & CustomProps

export function TextInput(props: TextInputProps) {
  const errorIndicator = props.errorMessage != undefined

  const iconProps = { errorIndicator, disabled: props.disabled }
  const leftIcon = props.iconLeft && (
    <InputIcon icon={props.iconLeft} {...iconProps} />
  )
  const rightIcon = props.iconRight && (
    <InputIcon icon={props.iconRight} {...iconProps} />
  )

  const generalProps = { ...props, value: undefined, onChange: undefined }
  const textInputProps = useTextInputProps({ ...props, errorIndicator })
  const field = <RNTextInput {...generalProps} {...textInputProps} />

  const containerProps = useTextInputContainerProps({
    ...props,
    underlineColor: textInputProps.underlineColor,
  })

  return (
    <Row
      alignCenter
      gap="XXXS"
      onPress={props.onPress}
      onLayout={props.onLayout}
      {...containerProps}
    >
      {leftIcon}
      {field}
      {rightIcon}
    </Row>
  )
}

function InputIcon(
  props: Pick<CustomProps, "disabled"> &
    ErrorIndicatorProps & { icon?: IconConfig },
) {
  const { icon, disabled, errorIndicator } = props

  if (!icon) return null

  return (
    <Icon
      {...icon}
      disabled={disabled}
      color={errorIndicator ? "error" : icon.color}
    />
  )
}

function useTextInputProps(props: TextInputProps & ErrorIndicatorProps) {
  const initialValueRef = useRef(props.value)
  const [isFocused, setIsFocused] = useState(false)
  const { fontSize, color, fontFamily } = useTheme()
  const { multiline, blurOnSubmit, spacingCorrectionStyle } =
    useIOSSelectionHackProps(props)

  // Determine the value of the field's underline (and selection) color
  // (constraint sequence is specifically chosen like that).
  let interactionColor
  if (props.underlineColor) interactionColor = props.underlineColor
  else if (props.errorIndicator) interactionColor = color.error
  else if (props.disabled) interactionColor = color.disabled
  else if (isFocused && props.editable != false)
    interactionColor = color.secondary2
  else if (
    props.trackInitialValue &&
    isEqual(props.value, initialValueRef.current)
  )
    interactionColor = color.base4
  else if (props.value) interactionColor = color.primary
  else interactionColor = color.text.light

  // Sets the selection color of the text on any platform except Android
  // (for the latter one colorAccent defined on native side is used).
  // https://github.com/facebook/react-native/issues/22762
  const selectionColor =
    Platform.OS != "android"
      ? props.selectionColor ?? interactionColor
      : undefined

  const style = useMemo(
    () => [
      spacingCorrectionStyle,
      {
        flex: 1,
        height: HEIGHT,

        // 'paddingHorizontal' is required to be set to zero,
        // as older Android version (<=7.1) add an unwanted custom padding.
        paddingHorizontal: 0,

        fontFamily: fontFamily.sourceSansPro.regular,
        color: props.errorIndicator ? color.error : color.text.default,
        fontSize: props.fontSize ? fontSize[props.fontSize] : fontSize.default,
      },
      props.style,
    ],
    [
      color.error,
      color.text.default,
      fontFamily.sourceSansPro.regular,
      fontSize,
      props.errorIndicator,
      props.fontSize,
      props.style,
      spacingCorrectionStyle,
    ],
  )

  // Fired when the input field loses focus.
  const onBlur = (event?: FocusBlurEvent) => {
    setIsFocused(false)
    if (event) props.onBlur?.(event)
    else props.onBlur?.()
  }

  // Fired when the input field gains focus.
  const onFocus = (event?: FocusBlurEvent) => {
    setIsFocused(true)
    if (event) props.onFocus?.(event)
    else props.onFocus?.()
  }

  // Fired when the input of the field changes
  // (only when real changes happen).
  const onChangeText = (text: string) => {
    // It does not make sense to fire a
    // change event in case the value has not changed.
    if (props.value == text) return

    props.onChange?.(text)
  }

  // Potentially try to focus a potential next input field of a form.
  const onFocusSubmit = async () => {
    // Submit on pressing enter if the form is valid
    // (in that case no additional focus changed needs to be triggered).
    if (await props.onSubmitIfValid?.()) return

    // No form submission took place,
    // therefore try to move the focus to the next field.
    props.focus?.onSubmitEditing()
  }

  // Handle a possible custom behavior first before continuing
  // with submitting and possible focus change.
  const onSubmitEditing = () => {
    props.onSubmitEditing?.()
    onFocusSubmit()
  }

  // Defines an aggregated ref consisting out of a possible external ref,
  // a ref used to change focus between input fields
  // and an autofocus related ref.
  const ref = mergeRefs(
    props.textInputRef,
    props.focus?.ref,
    useAutoFocusHackRef({ autoFocus: props.autoFocus, onFocus }),
  )

  return {
    ref,
    style,
    multiline,
    blurOnSubmit,
    selectionColor,

    autoFocus: false,
    value: props.value ?? "",
    placeholderTextColor: color.base4,
    editable: !props.disabled && props.editable,
    keyboardType: props.keyboardType ?? "default",
    underlineColor: interactionColor as ColorValue,
    returnKeyType: props.returnKeyType ? props.returnKeyType : "next",

    onBlur,
    onFocus,
    onChangeText,
    onSubmitEditing,
  }
}

function useTextInputContainerProps(
  props: Pick<
    TextInputProps,
    "mode" | "transparent" | "underlineColor" | "containerStyle"
  >,
) {
  const { color } = useTheme()

  const mode = props.mode ?? "flat"
  const style: StyleProp<ViewStyle> = [
    mode == "outlined" && {
      borderRadius: 5,
      borderColor: color.text.default,

      // Differentiate between the web and native usage,
      // because of cosmetic reasons.
      borderWidth: Platform.OS == "web" ? 2 : 1,
    },
    mode == "flat" && { borderBottomWidth: 2 },
    {
      // Always color the bottom borderline
      // with the evaluated underlineColor (independent of the set mode).
      borderBottomColor: props.underlineColor,

      backgroundColor: props.transparent ? "transparent" : color.background,
    },
    props.containerStyle,
  ]

  return { style }
}

function useAutoFocusHackRef({ autoFocus, onFocus }: AutoFocusHackConfig) {
  const focusedOnceRef = useRef(false)
  const focusHackInputRef = useRef<RNTextInput | null>(null)

  const focusNow = () => {
    if (!autoFocus) return
    if (focusedOnceRef.current) return

    const element = focusHackInputRef.current
    if (element == null) return

    element.focus()
    onFocus()

    focusedOnceRef.current = true
  }

  return (element: RNTextInput | null) => {
    focusHackInputRef.current = element

    // A timeout is required.
    // Otherwise, the selection behavior will break (in release mode)
    // and affect automatic formatting in a negative manner.
    setTimeout(focusNow, 100)
  }
}

function useIOSSelectionHackProps(
  props: Pick<
    TextInputProps,
    "autoFocus" | "multiline" | "blurOnSubmit" | "selectTextOnFocus"
  >,
) {
  const { multiline, autoFocus, selectTextOnFocus } = props

  // Last checked on 15th of February 2023
  // iOS-only: Properties corresponding to that flag
  // are part of the autoFocus + selectTextOnFocus hack.
  //
  // autoFocus + selectTextOnFocus bugs on Android
  // and iOS are still not fixed in used RNV.
  // https://github.com/facebook/react-native/issues/30585
  // https://github.com/facebook/react-native/issues/41988
  const isIOSSelectionHackEnabled =
    Platform.OS == "ios" && (selectTextOnFocus || autoFocus)

  // Also part of our selection hack.
  // Required, as otherwise blurOnSubmit could break behavior on Android.
  let blurOnSubmit
  if (props.blurOnSubmit == true || (isIOSSelectionHackEnabled && !multiline)) {
    blurOnSubmit = true
  } else if (props.blurOnSubmit == false) {
    blurOnSubmit = false
  } else blurOnSubmit = undefined

  return {
    blurOnSubmit,
    multiline: multiline || isIOSSelectionHackEnabled,

    // In case the selection hack is being used,
    // it is required to correct the inserted space of a multiline field.
    spacingCorrectionStyle: isIOSSelectionHackEnabled && {
      paddingTop: SPACING_CORRECTION,
    },
  }
}
