import React, { Fragment, ReactNode } from "react"

import { RowSeparator } from "../display/Separator"
import { Box } from "../layout/Box"
import { Column, Row } from "../layout/FlexBox"
import { Gap } from "../layout/Gap"
import { Spacing } from "../types"

export type TableColumn<T> = {
  flex?: number
  header: ReactNode
  renderCell: (row: T) => ReactNode
}

type TableProps<T> = {
  rows: T[]
  columns: TableColumn<T>[]

  gap?: Spacing
  gapBetweenRows?: Spacing
  gapHeaderAndData?: Spacing

  headerRowBold?: boolean
  showEndSeparator?: boolean
  showSeparatorsBetweenRows?: boolean
}

type DataRowsProps<T> = Pick<
  TableProps<T>,
  "gap" | "rows" | "columns" | "gapBetweenRows" | "showSeparatorsBetweenRows"
>
type RowProps<T> = Pick<TableProps<T>, "gap" | "columns">
type RowEntryProps<T> = RowProps<T> & { row: T }
type RowEntrySeparatorProps<T> = Pick<TableProps<T>, "gap">

// A simple table component with arbitrary ReactNodes as cells.
export function Table<T>({
  rows,
  columns,
  gap = "XXXXS",
  headerRowBold = false,
  showEndSeparator = false,
  showSeparatorsBetweenRows,
  gapBetweenRows = undefined,
  gapHeaderAndData = "XXXXS",
}: TableProps<T>) {
  return (
    <Column gap={gap}>
      <HeaderRow gap={gap} columns={columns} />
      <RowSeparator color={headerRowBold ? "default" : undefined} />
      <Gap vertical={gapHeaderAndData} />
      <DataRows
        gap={gap}
        rows={rows}
        columns={columns}
        gapBetweenRows={gapBetweenRows}
        showSeparatorsBetweenRows={showSeparatorsBetweenRows}
      />
      {showEndSeparator && <EndSeparator />}
    </Column>
  )
}

function HeaderRow<T>({ gap, columns }: RowProps<T>) {
  return (
    <Row alignCenter gap={gap}>
      {columns.map(({ flex, header }, index) => (
        <Box key={`headerRow_${index}`} flex={flex ?? 1}>
          {header}
        </Box>
      ))}
    </Row>
  )
}

function DataRows<T>({
  gap,
  rows,
  columns,
  gapBetweenRows,
  showSeparatorsBetweenRows,
}: DataRowsProps<T>) {
  return (
    <>
      {rows.map((row, index) => (
        <Fragment key={`dataRow_${index}`}>
          {showSeparatorsBetweenRows && index > 0 && <RowEntrySeparator />}
          <RowEntry row={row} gap={gap} columns={columns} />
          {!showSeparatorsBetweenRows && index != rows.length - 1 && (
            <Gap vertical={gapBetweenRows ?? gap} />
          )}
        </Fragment>
      ))}
    </>
  )
}

function RowEntrySeparator<T>({ gap }: RowEntrySeparatorProps<T>) {
  return (
    <>
      <RowSeparator />
      <Gap vertical={gap} />
    </>
  )
}

function RowEntry<T>({ row, gap, columns }: RowEntryProps<T>) {
  return (
    <Row gap={gap}>
      {columns.map(({ flex, renderCell }, index) => (
        <Box key={`rowEntry_${index}`} flex={flex ?? 1}>
          {renderCell(row)}
        </Box>
      ))}
    </Row>
  )
}

function EndSeparator() {
  return (
    <>
      <Gap vertical="XXXXS" />
      <RowSeparator />
    </>
  )
}
