import { isEqual } from "lodash"
import { useState } from "react"

import { useTransitionEffect } from "@axtesys/hooks"

import { DateRange } from "../types"

export function useDateTimeRangeEffect(range: DateRange) {
  const [endDate, setEndDate] = useState(range[1])
  const [startDate, setStartDate] = useState(range[0])

  // Update:
  // In case the range value changes, because of an update
  // outside/external to the managing logic of this component,
  // do update the controlled values inside here too.
  useTransitionEffect(range, (currentState, lastState) => {
    if (
      isEqual(lastState, currentState) ||
      isEqual([startDate, endDate], currentState)
    )
      return

    setStartDate(currentState[0])
    setEndDate(currentState[1])
  })

  return { endDate, startDate, setEndDate, setStartDate }
}
