import React, { ReactNode, useEffect } from "react"

import { useIsAppFrontend } from "../../Config/queries"
import { log } from "../lib"
import { useLogLevel } from "../queries"

export function LogProvider({ children }: { children: ReactNode }) {
  const logLevel = useLogLevel()
  const isAppMode = useIsAppFrontend()

  useEffect(() => {
    if (__DEV__ || isAppMode) log.enable()
    else log.disable()
  }, [isAppMode])

  useEffect(() => {
    log.setSeverity(logLevel)
  }, [logLevel])

  return <>{children}</>
}
