import { addDays, differenceInDays } from "date-fns"
import React, { useEffect, useState } from "react"
import { PixelRatio } from "react-native"

import {
  formatDate,
  formatIsoDate,
  formatIsoDateTime,
  formatTime,
  interpolateString,
  validateDate,
} from "@axtesys/react-tools"

import { AlternativePressableOpacity } from "../../button/AlternativePressableOpacity"
import { Icon } from "../../display/Icon"
import { MCIcon } from "../../display/MCIcon"
import { useDateTimePicker } from "../../hooks/useDateTimePicker"
import { useDateTimeRangeEffect } from "../../hooks/useDateTimeRangeEffect"
import { Column } from "../../layout/FlexBox"
import { useKassenAppUI } from "../../providers/KassenAppUIProvider"
import { DateTimeMode, DateTimeRange } from "../../types"
import { Label } from "../../typography/Label"
import { InputRow } from "./InputRow"

type DateTimeRangeRowProps = {
  mode: DateTimeMode
  value: DateTimeRange
  onChange: (dateRange: DateTimeRange) => void

  disableFuture?: boolean
  selectionLimit?: number
  onSelectionViolationChange?: (state: boolean) => void
}

export function DateTimeRangeRow(props: DateTimeRangeRowProps) {
  const { mode, value, selectionLimit, onChange, onSelectionViolationChange } =
    props

  const { translations } = useKassenAppUI()
  const { endDate, startDate, setEndDate, setStartDate } =
    useDateTimeRangeEffect(value.range)
  const [endDateViolation, setEndDateViolation] = useState(false)
  const [startDateViolation, setStartDateViolation] = useState(false)
  const [selectionViolation, setSelectionViolation] = useState(false)

  // Validation: Check if the entered timespan is valid.
  // 1. Check for general date format validity.
  // 2. Verify whether a possible selectionLimit constraint passes.
  // 3. Verify that the start is before the end date.
  useEffect(() => {
    const validStartDate = validateDate(mode, startDate)
    const validEndDate = validateDate(mode, endDate)

    setStartDateViolation(validStartDate)
    setEndDateViolation(validEndDate)

    if (!validStartDate || !validEndDate) return

    if (
      selectionLimit &&
      differenceInDays(addDays(endDate, 1), startDate) > selectionLimit
    ) {
      setSelectionViolation(true)
      onSelectionViolationChange?.(true)
      setStartDateViolation(true)
      setEndDateViolation(true)
      return
    } else {
      setSelectionViolation(false)
      onSelectionViolationChange?.(false)
      setStartDateViolation(false)
      setEndDateViolation(false)
    }

    if (startDate > endDate) {
      setEndDateViolation(true)
      return
    }

    const range: [Date, Date] =
      mode == "date"
        ? [new Date(formatIsoDate(startDate)), new Date(formatIsoDate(endDate))]
        : [
            new Date(formatIsoDateTime(startDate)),
            new Date(formatIsoDateTime(endDate)),
          ]

    onChange({ range })
  }, [
    endDate,
    mode,
    onChange,
    onSelectionViolationChange,
    selectionLimit,
    startDate,
  ])

  let icon: MCIcon
  switch (mode) {
    case "date":
      icon = "calendar"
      break
    case "datetime":
      icon = "calendar-clock"
      break
    default:
      icon = "progress-question"
  }
  const isMedium = PixelRatio.getFontScale() > 1.1
  const iconColor = selectionViolation ? "error" : undefined

  return (
    <InputRow
      icon={{
        name: icon,
        color: iconColor,
        tooltip: selectionLimit
          ? interpolateString(translations.selectionLimit, selectionLimit)
          : undefined,
      }}
    >
      <DateLabel
        {...props}
        value={startDate}
        isMedium={isMedium}
        error={startDateViolation || selectionViolation}
        onChange={setStartDate}
      />
      <Icon name="arrow-right" color={iconColor} />
      <DateLabel
        {...props}
        value={endDate}
        isMedium={isMedium}
        error={endDateViolation || selectionViolation}
        onChange={setEndDate}
      />
    </InputRow>
  )
}

type DateLabelProps = Omit<DateTimeRangeRowProps, "value" | "onChange"> & {
  value: Date
  error: boolean
  isMedium: boolean
  onChange: (date: Date) => void
}

function DateLabel({
  mode,
  value,
  error,
  isMedium,
  disableFuture = true,
  onChange,
}: DateLabelProps) {
  const { pickDateOrTime } = useDateTimePicker()

  const labelColor = error ? "error" : undefined

  const onPress = async () => {
    const pickedDate = await pickDateOrTime({ value, disableFuture })
    if (!pickedDate) return
    onChange(pickedDate)
  }

  return (
    <AlternativePressableOpacity style={{ flex: 1 }} onPress={onPress}>
      <Column expand alignCenter>
        <Label medium={isMedium} color={labelColor} text={formatDate(value)} />
        {mode != "date" && (
          <Label
            medium={isMedium}
            color={labelColor}
            text={formatTime(value)}
          />
        )}
      </Column>
    </AlternativePressableOpacity>
  )
}
