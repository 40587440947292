import React from "react"

import { Box } from "../../layout/Box"
import { Row } from "../../layout/FlexBox"
import { InputLabel } from "../../typography/InputLabel"
import { ErrorField } from "./ErrorField"
import { FormFieldProps } from "./FormField"

type SideFormFieldProps = Omit<FormFieldProps, "gap" | "onPress"> & {
  multiline?: boolean
}

// A right side labeled form input / field component.
export function SideFormField(props: SideFormFieldProps) {
  const { children, multiline, errorMessage, noErrorMessage, ...labelProps } =
    props

  const showErrorMessage = noErrorMessage != true

  const label = (
    <Box
      flex={10}
      style={{ maxWidth: 180 }}
      // Required to cope with the gap / label of
      // the ErrorField and its error message.
      padBottom={!multiline && showErrorMessage ? "XXS" : "none"}
    >
      <InputLabel {...labelProps} />
    </Box>
  )

  const input = (
    <Box flex={18}>
      {showErrorMessage ? (
        <ErrorField errorMessage={errorMessage}>{children}</ErrorField>
      ) : (
        children
      )}
    </Box>
  )

  return (
    <Row
      alignCenter={!multiline}
      style={multiline && { justifyContent: "flex-start" }}
    >
      {label}
      {input}
    </Row>
  )
}
