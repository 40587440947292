import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

type SetState<T> = Dispatch<SetStateAction<T>>

// Like useState(), but prevents setState calls
// once the component is unmounted, to avoid the following error:
// "Can't perform a React state update on an unmounted component".
export function useStateIfMounted<T>(initialState: T): [T, SetState<T>] {
  const mountedRef = useRef(false)
  const [state, setStateInternal] = useState(initialState)

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  const setState: SetState<T> = useCallback(state => {
    if (mountedRef.current) setStateInternal(state)
  }, [])

  return [state, setState]
}
