import { useWindowDimensions } from "react-native"

import { useDeviceInfo } from "@axtesys/hooks"

export type ScreenSizeType = "S" | "M" | "L"

export function useScreenSizeType(): ScreenSizeType {
  const { deviceType } = useDeviceInfo()
  const { width } = useWindowDimensions()

  switch (deviceType) {
    case "phone":
      return "S"
    case "tablet":
      return "M"
    case "desktop":
      // Typical desktop screens use L...
      if (width >= 1400) return "L"
      // ...smaller desktop screens use M...
      else if (width < 1400 && width > 600) return "M"
      // and even smaller ones use S (when cropped down).
      else return "S"
    default:
      throw Error()
  }
}
