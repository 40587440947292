import React, { ReactNode } from "react"
import { SafeAreaProvider } from "react-native-safe-area-context"
import { RecoilRoot } from "recoil"

import { DeviceInfoProvider, NetworkStatusProvider } from "@axtesys/hooks"
import {
  DateTimePickerProvider,
  DialogProvider,
  SimpleDialogProvider,
} from "@axtesys/kassen-app-ui"

import { GraphQLContextProvider } from "./api/graphql"
import { ReactQueryProvider } from "./api/graphql/components"
import { ContextStack } from "./components/ContextStack"
import { AbsoluteAccessorProvider } from "./components/providers/AbsoluteAccessorProvider"
import { JsonDbLoadingGateProvider } from "./components/providers/JsonDbLoadingGateProvider"
import { SelectionProvider } from "./components/providers/SelectionProvider"
import { SnackbarProvider } from "./components/providers/SnackbarProvider"
import { StyleProvider } from "./components/providers/StyleProvider"
import { SuspenseProvider } from "./components/providers/SuspenseProvider"
import { AuthenticationProvider } from "./feature/Authentication/providers/AuthenticationProvider"
import { InteractionPreventionProvider } from "./feature/InteractionPrevention/providers/InteractionPreventionProvider"
import { LogProvider } from "./feature/Logging/providers/LogProvider"
import { TranslationProvider } from "./feature/Translation/providers/TranslationProvider"

export function SharedContext({ children }: { children: ReactNode }) {
  return (
    <ContextStack
      containers={[
        // Provides recoil.js hooks
        RecoilRoot,

        // Provides functionality for `react-native-safe-area-context` components
        SafeAreaProvider,

        // Provides top-level suspense functionality for other contexts
        SuspenseProvider,

        // Preloads all JsonDb atoms
        JsonDbLoadingGateProvider,

        // Preloads and initialises application specific translations
        TranslationProvider,

        // Presets severity and enabled state of the logging logic
        LogProvider,

        // Provides `useDeviceInfo` hook
        DeviceInfoProvider,

        // Provides `useNetworkStatus` hook
        NetworkStatusProvider,

        // Provides functionality for the usage of react-query
        ReactQueryProvider,

        // Loads fonts and kassen-app-ui theme
        StyleProvider,

        // Provides date/time picking capabilities
        DateTimePickerProvider,

        // Provides `useSnackbar` hook
        SnackbarProvider,

        // Provides the possibility to block user input
        InteractionPreventionProvider,

        // Provides `useSelection` hook
        SelectionProvider,

        // Provides `useSimpleDialog` hook
        SimpleDialogProvider,

        // Provides functionality regarding the login state of the user
        AuthenticationProvider,

        // Provides `useSuspenseQuery` and `useGraphQL` hooks
        GraphQLContextProvider,

        // Provides `useDialog` hook
        DialogProvider,

        // Provides `useAbsoluteAccessor` hook
        AbsoluteAccessorProvider,
      ]}
    >
      {children}
    </ContextStack>
  )
}
