import React from "react"

import { Box } from "../layout/Box"
import { useTheme } from "../theme"
import { Label } from "../typography/Label"
import { Icon } from "./Icon"
import { MCIcon } from "./MCIcon"
import { ToolTip, ToolTipIconProps } from "./ToolTip"

export function ToolTipIcon(props: ToolTipIconProps) {
  const { color } = useTheme()

  const content = (
    <Box
      pad="XXXS"
      borderColor={color.border}
      backgroundColor={color.background}
    >
      {props.title && <Label h5 padBottom="XS" text={props.title} />}
      {"text" in props && <Label small text={props.text} />}
      {"children" in props && props.children}
    </Box>
  )

  return (
    <ToolTip text={content} widthOverwrite={props.widthOverwrite}>
      <Icon
        size={props.size ?? "S"}
        color={props.color ?? "primary"}
        name={(props.icon as MCIcon) ?? "information"}
      />
    </ToolTip>
  )
}
