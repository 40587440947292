import { InteractionManager } from "react-native"
import { consoleTransport, logger } from "react-native-logs"

import { createId, isEmptyOrBlank } from "@axtesys/react-tools"

import { logMessageSubject } from "./state"
import { LogFunc } from "./types"

export { logError, logWarn, logInfo, logDebug, logTrace }

export const log = logger.createLogger({
  severity: "error",
  dateFormat: "local",

  // Added for performance reasons,
  // see https://github.com/mowispace/react-native-logs?tab=readme-ov-file#improve-performance
  async: true,
  asyncFunc: InteractionManager.runAfterInteractions,

  levels: { trace: 0, debug: 1, info: 1, warn: 2, error: 3 },

  stringifyFunc,
  transport: props => {
    if (__DEV__) consoleTransport({ ...props, options: { colors: "ansi" } })

    logMessageSubject.next([
      { level: props.level.text, message: props.msg.toString() },
    ])
  },
})

// Adapted from original function
// https://github.com/onubo/react-native-logs/blob/d4501fde6c30df009e4e1551b2715dd14dec52bc/src/index.ts
function stringifyFunc(message: any) {
  let formattedOutput = ""

  if (typeof message === "string") {
    formattedOutput = addIdToString(message)
  } else if (typeof message === "function") {
    formattedOutput = "[function]"
  } else if (message && message.stack && message.message) {
    formattedOutput = addIdToString(message.message.toString())
  } else {
    try {
      formattedOutput = `\n${JSON.stringify(message, undefined, 2)}\n`
    } catch (error) {
      formattedOutput += "Undefined Message"
    }
  }

  return formattedOutput
}

// Add a short identifier to the message,
// so when log message duplication occurs
// (e.g. when app is getting closed just at the moment,
// when the success response from
// the log message transmission is received OR
// a transmission went through but because of a
// bad connection the response is never received).
function addIdToString(message: string) {
  return !isEmptyOrBlank(message)
    ? `${createId().substring(0, 7)} | ${message}`
    : ""
}

// Log emission functions

const logError: LogFunc = (message, ...data) => log.error(message, ...data)
const logWarn: LogFunc = (message, ...data) => log.warn(message, ...data)
const logInfo: LogFunc = (message, ...data) => log.info(message, ...data)
const logDebug: LogFunc = (message, ...data) => log.debug(message, ...data)
const logTrace: LogFunc = (message, ...data) => log.trace(message, ...data)
