import { useEffect } from "react"

// Like useEffect, but will only ever run once on mount.
export function useMountEffect(effect: () => any | Promise<any> | Function) {
  useEffect(() => {
    const result = effect()
    return typeof result == "function" ? result : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
