import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { DepartmentFragment } from "../api/graphql/types"
import { ManipulationMode } from "./ManipulationMode"

export type DepartmentId = string

export type Department = {
  departmentId: DepartmentId
  name: string
  isDefault: boolean
}

export type DepartmentInfo = Omit<Department, "isDefault">

export type DepartmentFormData = Omit<Department, "departmentId"> & {
  departmentId?: DepartmentId
}

export type GeneralDepartmentFormProps = {
  mode: ManipulationMode
  initialValue?: DepartmentFragment

  onSubmit(contact: DepartmentFormData): void
}

export const DepartmentEntity = createEntityFor<Department>()
  .withSchema(
    z.object({
      departmentId: z.string(),
      name: z.string(),
      isDefault: z.boolean(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)

export const DepartmentInfoEntity = createEntityFor<DepartmentInfo>()
  .withSchema(z.object({ departmentId: z.string(), name: z.string() }))
  .serialize(data => data)
  .deserialize(json => json)
