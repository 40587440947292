import React, { Children, ReactNode } from "react"
import { StyleProp, ViewStyle } from "react-native"

import { Box } from "../layout/Box"
import { Row } from "../layout/FlexBox"

type TableRowProps = {
  flex: number[]
  children: ReactNode

  // Grants access to the style property of each column.
  styles?: StyleProp<ViewStyle>[]

  // Grants access to the style of the row container.
  containerStyle?: StyleProp<ViewStyle>
}

export function TableRow({
  flex,
  styles,
  children,
  containerStyle,
}: TableRowProps) {
  return (
    <Row alignCenter style={containerStyle}>
      {Children.toArray(children).map((child, index) => (
        <Box
          key={child.toString() + flex[index]}
          style={[{ flex: flex[index] }, styles && styles[index]]}
        >
          {child}
        </Box>
      ))}
    </Row>
  )
}
