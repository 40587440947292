import { deAT } from "date-fns/locale"

// In case the received language from either the OS,
// native or browser side (or because of some other reason)
// is currently not supported, the translation implementation will
// fall back to the default language set via this constant.
export const DEFAULT_LANGUAGE_CODE = "de"

// When any translation function (via i18next or react-i18next) is called
// without specifying a specific namespace,
// translations are tried to be picked from the default (general) namespace.
export const DEFAULT_NAMESPACE = "general"

// Add language codes that should be supported.
// Specify the language code (e.g. en, de, es, fr, etc.) only
// without adding the regional info (e.g. en_US, de_AT, etc.).
export const SUPPORTED_LANGUAGE_CODES = [DEFAULT_LANGUAGE_CODE]

// Add `languageCode: locale` combinations that should be supported
// (e.g. de: deAT, en: enUS, etc.).
export const SUPPORTED_LOCALES: Record<string, Locale> = { de: deAT }
