import Big from "big.js"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { ArticleGroupId } from "./ArticleGroup"
import { MainGroupId } from "./MainGroup"

export type ArticleId = string

type SharedArticle = {
  mainGroupId: MainGroupId
  articleGroupId: ArticleGroupId
  name: string
  price: Big
}

type SharedArticleFlags = {
  zeroPriceAllowed: boolean
  negativePrice: boolean
  discountBlocked: boolean
  detailsDirectAccess: boolean
}

export type Article = SharedArticle & {
  articleId: ArticleId
  taxRate: number
  flags: SharedArticleFlags & { variablePrice: boolean }

  number?: string
  description?: string
  barcodeNumbers?: string[]
}

export type ArticleFormData = SharedArticle &
  SharedArticleFlags & {
    taxRate: string

    description?: string
    articleNumber?: string
  }

export type InvoiceItemArticle = Omit<Article, "flags" | "description">

export type ArticleData = Omit<Article, "articleId">

export const ArticleEntity = createEntityFor<Article>()
  .withSchema(
    z.object({
      articleId: z.string(),
      mainGroupId: z.string(),
      articleGroupId: z.string(),
      name: z.string(),
      price: z.string(),
      taxRate: z.number(),
      flags: z.object({
        zeroPriceAllowed: z.boolean(),
        negativePrice: z.boolean(),
        variablePrice: z.boolean(),
        discountBlocked: z.boolean(),
        detailsDirectAccess: z.boolean().default(false),
      }),
      number: z.ostring(),
      description: z.ostring(),
      barcodeNumbers: z.optional(z.array(z.string())),
    }),
  )
  .serialize(data => ({ ...data, price: data.price?.toString() }))
  .deserialize(json => ({ ...json, price: Big(json.price) }))
