import { useEffect } from "react"
import {
  Keyboard,
  KeyboardEventListener,
  KeyboardEventName,
} from "react-native"

// Executes a callback when a KeyboardEvent is raised.
export function useKeyboardEffect(
  eventName: KeyboardEventName,
  listener: KeyboardEventListener,
) {
  useEffect(() => {
    const subscription = Keyboard.addListener(eventName, listener)
    return () => subscription.remove()
  }, [eventName, listener])
}
