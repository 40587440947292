import { DependencyList, useEffect, useState } from "react"

// Executes an asynchronous function to create some value.
// The hook initially returns undefined, then updates the component
// with the value once it is available. The value will get re-fetched
// if the dependencyList changes.
export function useAsyncValue<T>(
  producer: () => Promise<T>,
  dependencyList: DependencyList,
): T | undefined {
  const [state, setState] = useState<T | undefined>()

  useEffect(() => {
    producer().then(setState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList)

  return state
}
