import React, {
  memo,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  FlatList,
  FlatListProps,
  LayoutChangeEvent,
  ListRenderItemInfo,
} from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import { useScreenOrientation } from "@axtesys/hooks"
import { createId } from "@axtesys/react-tools"

import { QueryNames, useInvalidateQueries } from "../api/graphql"

export type PerformanceProps<T> = {
  items: T[]

  // Specifies the height (vertical list) or
  // width (horizontal list) of an item
  itemDimension: number

  renderItem: (item: T, flex: number) => ReactElement

  itemKey?: keyof T

  // This property is required to calculate the correct offset value in getItemLayout
  // and is related to the following FlatList implementation description:
  // Remember to include separator length (height or width) in your
  // offset calculation if you specify ItemSeparatorComponent.
  additionalOffset?: number

  // A minor padding on the bottom of the list is added,
  // in order to compensate the virtual home button
  // (solely cosmetic flag)
  homeButtonPadding?: boolean

  forwardRef?: RefObject<FlatList<T>>

  // Shortcut for onRefresh and refreshing properties
  refreshInvalidation?: QueryNames | Array<QueryNames>

  renderListEmptyComponent?: ReactElement | (() => ReactElement)
} & Pick<
  FlatListProps<T>,
  | "horizontal"
  | "refreshing"
  | "numColumns"
  | "showsVerticalScrollIndicator"
  | "showsHorizontalScrollIndicator"
  | "onRefresh"
>

export function usePerformanceConfig<T>(props: PerformanceProps<T>) {
  const {
    items,
    itemKey,
    forwardRef,
    numColumns,
    itemDimension,
    homeButtonPadding,
    additionalOffset = 0,
    renderItem,
    renderListEmptyComponent,
    ...flatListProps
  } = props

  const { bottom } = useSafeAreaInsets()
  const key = useMemo(createId, [numColumns])
  const invalidateReactQueries = useInvalidateQueries()
  const spaceCorrection = useHomeButtonCorrection(homeButtonPadding ?? false)

  const [size, setSize] = useState({ width: 0, height: 0 })
  const itemFlex = useMemo(
    () => 1.0 / (numColumns && numColumns > 0 ? numColumns : 1.0),
    [numColumns],
  )
  const [numItemsPerViewport, setNumItemsPerViewport] = useState<
    number | undefined
  >(undefined)

  useEffect(() => {
    if (!(size.width > 0 && size.height > 0)) return

    setNumItemsPerViewport(
      Math.ceil((props.horizontal ? size.width : size.height) / itemDimension),
    )
  }, [itemDimension, props.horizontal, size])

  const keyExtractor = useCallback(
    (item: any) => (typeof item == "object" ? item[itemKey] : item.toString()),
    [itemKey],
  )

  const ListItemComponent = useMemo(
    () => memo(({ item }: { item: T }) => renderItem(item, itemFlex)),
    [itemFlex, renderItem],
  )
  const renderItemComponent = useCallback(
    ({ item }: ListRenderItemInfo<T>) => (
      <ListItemComponent item={item} key={keyExtractor(item)} />
    ),
    [ListItemComponent, keyExtractor],
  )

  const getItemLayout = useCallback(
    (_: ArrayLike<T> | null | undefined, index: number) => ({
      length: itemDimension,
      offset: (itemDimension + additionalOffset) * index,
      index,
    }),
    [additionalOffset, itemDimension],
  )

  const shortcutRefreshProps = useMemo(
    () =>
      props.refreshInvalidation
        ? {
            refreshing: false,
            onRefresh: async () =>
              invalidateReactQueries(props.refreshInvalidation),
          }
        : undefined,
    [invalidateReactQueries, props.refreshInvalidation],
  )

  const generalProps = {
    key,
    numColumns,
    data: items,
    keyExtractor,
    getItemLayout,
    windowSize: 10,
    ref: forwardRef,
    style: { flex: 1 },
    removeClippedSubviews: false,
    updateCellsBatchingPeriod: 50,
    renderItem: renderItemComponent,
    contentContainerStyle: { flexGrow: 1 },
    initialNumToRender: numItemsPerViewport,
    maxToRenderPerBatch: numItemsPerViewport,
    ListEmptyComponent: renderListEmptyComponent,
  }

  const onLayout = useCallback(
    ({ nativeEvent }: LayoutChangeEvent) => setSize(nativeEvent.layout),
    [],
  )

  return {
    generalProps,
    flatListProps,
    shortcutRefreshProps,
    spaceCorrection: spaceCorrection ? bottom : undefined,
    onLayout,
  }
}

function useHomeButtonCorrection(enabled: boolean) {
  const orientation = useScreenOrientation()

  return useMemo(
    () => enabled && orientation == "landscape",
    [enabled, orientation],
  )
}
