import {
  isEmailValid,
  isEmptyOrBlank,
  isPhoneNumberValid,
  isTaxNumberValid,
  isZipCodeValid,
} from "@axtesys/react-tools"

import { useForm } from "../../hooks/useForm"
import { useTranslations } from "../Translation/hooks"
import {
  useLoginAdmin,
  useLoginManager,
  useRegisterCompanyWithManager,
} from "./commands"
import { LoginFormData, LoginFormDataValue, LoginType } from "./types"

export function useRegistrationForm(
  navigateToLogin: (managerEmail: string) => void,
) {
  const { tScreen, tGeneral, tValidations } =
    useTranslations("RegistrationScreen")
  const registerCompanyWithManager =
    useRegisterCompanyWithManager(navigateToLogin)

  return useForm({
    data: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      repeatedPassword: "",
      companyName: "",
      taxId: "",
      street: "",
      streetNumber: "",
      zipCode: "",
      city: "",
      tosAccepted: false as boolean,
    },
    label: {
      firstName: tGeneral("firstName"),
      lastName: tGeneral("lastName"),
      email: tGeneral("email"),
      phoneNumber: tGeneral("phoneNumber"),
      password: tGeneral("password"),
      repeatedPassword: tGeneral("repeatPassword"),
      companyName: tGeneral("company"),
      taxId: tGeneral("uidNumber"),
      street: tGeneral("street"),
      streetNumber: tGeneral("streetNumber"),
      zipCode: tGeneral("zipCode"),
      city: tGeneral("city"),
    },
    placeholder: {
      email: tScreen("emailPlaceholder"),
      phoneNumber: tScreen("phoneNumberPlaceholder"),
      taxId: tScreen("uidNumberPlaceholder"),
    },
    mandatory: {
      firstName: true,
      lastName: true,
      email: true,
      password: true,
      repeatedPassword: true,
      companyName: true,
      taxId: true,
      street: true,
      streetNumber: true,
      zipCode: true,
      city: true,
      phoneNumber: true,
    },
    validate: {
      email: email => isEmailValid(email) || tValidations("validationEmail"),
      phoneNumber: phoneNumber =>
        isPhoneNumberValid(phoneNumber) ||
        tValidations("validationPhoneNumber"),
      taxId: taxId =>
        isTaxNumberValid(taxId) || tValidations("validationTaxNumber"),
      zipCode: zipCode =>
        isZipCodeValid(zipCode) || tValidations("validationZipCode"),
      repeatedPassword: (repeatedPassword, { password }) =>
        repeatedPassword == password ||
        tValidations("validationPasswordsNotMatching"),
      tosAccepted: tosAccepted =>
        tosAccepted == true || tValidations("validationTosNotAccepted"),
    },
    submit: async (
      {
        email: managerEmail,
        firstName: managerFirstName,
        lastName: managerLastName,
        password: managerPassword,
        phoneNumber: managerTelephone,
        ...data
      },
      form,
    ) => {
      await registerCompanyWithManager(
        {
          ...data,
          managerEmail,
          managerFirstName,
          managerLastName,
          managerPassword,
          managerTelephone,
        },
        form,
      )
    },
  })
}

export function useLoginUserForm(loginUserType: LoginType) {
  const login = useLoginUser(loginUserType)
  const { tGeneral, tValidations } = useTranslations()

  return useForm({
    data: { email: "", password: "" },
    label: { email: tGeneral("email"), password: tGeneral("password") },
    mandatory: { email: true, password: true },
    validate: {
      email: email =>
        isEmailValid(email) || tValidations("validationCompanyEmail"),
      password: password =>
        !isEmptyOrBlank(password) || tValidations("validationCompanyPassword"),
    },
    submit: ({ email, password }, form) => login({ email, password }, form),
  })
}

function useLoginUser(loginUserType: LoginType) {
  const loginAdmin = useLoginAdmin()
  const loginManager = useLoginManager()

  return async (variables: LoginFormData, form: LoginFormDataValue) => {
    if (loginUserType == "admin") await loginAdmin(variables, form)
    else await loginManager(variables, form)
  }
}
