import { concat, initial } from "lodash"
import { ReactNode, useCallback, useRef, useState } from "react"

import { createCustomContext } from "@axtesys/react-tools"

import { ShowComponentDialog } from "../../types"

export const [InternalDialogContextProvider, useInternalDialogContext] =
  createCustomContext(() => {
    // The callbacks to be executed
    // when the currently active dialogs need to be closed.
    const closureResolversRef = useRef<Function[]>([])

    // All currently displayed dialogs (if any).
    const [activeDialogs, setActiveDialogs] = useState<ReactNode[]>([])

    // Shows a new dialog on the screen
    // (adds a new dialog component to the active dialog stack).
    const showDialog: ShowComponentDialog = useCallback(
      dialogToShow =>
        new Promise(resolve => {
          // A brief timeout is required here
          // so that dialogs function and render correctly.
          setTimeout(() => {
            closureResolversRef.current.push(resolve)
            setActiveDialogs(renderedDialogs =>
              concat(renderedDialogs, dialogToShow),
            )
          })
        }),
      [],
    )

    // Closes the most recent / top dialog on the screen.
    const closeTopDialog: Function = useCallback(() => {
      closureResolversRef.current.pop()?.()
      setActiveDialogs(renderedDialogs => initial(renderedDialogs))
    }, [])

    return { activeDialogs, showDialog, closeTopDialog }
  })
