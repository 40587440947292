import { DependencyList, useEffect } from "react"

// Executes an effect after a given number of milliseconds.
// The timeout restarts (whether the action was executed or not)
// whenever the dependencyList changes.
//
// In case the hook is unmounted,
// before the effect could be carried out,
// the timeout effect is removed too.
export function useTimeoutEffect(
  executeAfter: number,
  effect: () => void,
  dependencyList: DependencyList,
) {
  useEffect(() => {
    const timeout = setTimeout(effect, executeAfter)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList)
}
