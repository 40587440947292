import { RefObject, useRef, useState } from "react"

import { OnOffFunction } from "./useOnOff"

export function useReliableOnOff(): [
  boolean,
  OnOffFunction,
  OnOffFunction,
  RefObject<boolean>,
] {
  const stateRef = useRef(false)
  const [state, setState] = useState(false)

  const on = () => {
    stateRef.current = true
    setState(true)
  }
  const off = () => {
    stateRef.current = false
    setState(false)
  }

  return [state, on, off, stateRef]
}
