import React from "react"
import MaterialCommunityIcon from "react-native-vector-icons/MaterialCommunityIcons"

import { Grid, GridProps } from "../../container/Grid"
import { Box } from "../../layout/Box"
import { TextInputProps } from "./TextInput"

type ColorPaletteInputProps = Pick<TextInputProps, "value" | "onChange"> &
  Omit<GridProps, "children"> & {
    numCols: number
    colors: string[]
    colorItemSize?: number
  }

export function ColorPaletteInput({
  gap,
  value,
  colors,
  numCols,
  verticalGap,
  horizontalGap,
  colorItemSize,
  onChange,
}: ColorPaletteInputProps) {
  return (
    <Grid
      alignRowsCenter
      gap={gap}
      numCols={numCols}
      verticalGap={verticalGap}
      horizontalGap={horizontalGap}
    >
      {colors.map(color => (
        <PaletteItem
          key={color}
          color={color}
          size={colorItemSize}
          isSelected={color == value}
          onChange={onChange}
        />
      ))}
    </Grid>
  )
}

type PaletteItemProps = Pick<ColorPaletteInputProps, "onChange"> & {
  color: string
  size?: number
  isSelected?: boolean
}

function PaletteItem({ size, color, isSelected, onChange }: PaletteItemProps) {
  const itemSize = size ?? 36

  const onPress = () => onChange?.(color)

  return (
    <Box style={{ width: itemSize, height: itemSize }} onPress={onPress}>
      <MaterialCommunityIcon
        color={color}
        size={itemSize}
        name={isSelected ? "check-circle" : "circle"}
      />
    </Box>
  )
}
