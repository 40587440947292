import React, { ReactNode, Ref, useCallback, useRef, useState } from "react"
import { Platform, StyleProp, ViewStyle } from "react-native"
import { Animation, View as AnimatableView } from "react-native-animatable"

import { useAsyncEffect, useMountEffect } from "@axtesys/hooks"
import { isIOSLowerEqual16, setRefValue } from "@axtesys/react-tools"

type AnimationController = {
  animateIn(duration?: number): Promise<void>
  animateOut(duration?: number): Promise<void>
}

type AnimationContainerProps = {
  // The content of the view that will be animated.
  children: ReactNode

  // Pass in a value from useAnimationController() to start/stop animations.
  controllerRef: Ref<AnimationController | undefined>

  // See https://github.com/oblador/react-native-animatable#animations-2
  // for the available animations.
  exitAnimation?: Animation
  entryAnimation?: Animation

  // Style that is applied to
  // the wrapping 'View' component of 'react-native-animatable'.
  style?: StyleProp<ViewStyle>

  // Number that states how long
  // the animation should take in milliseconds.
  exitAnimationDuration?: number
  entryAnimationDuration?: number

  // What the component should do on first mount. It can either start
  // an entry/exit animation, or start fully visible/hidden.
  // Defaults to: 'visible'
  mountState?: "entry" | "exit" | "visible" | "hidden"
}

export function AnimationContainer({
  style,
  children,
  mountState,
  controllerRef,
  exitAnimation,
  entryAnimation,
  exitAnimationDuration,
  entryAnimationDuration,
}: AnimationContainerProps) {
  const viewRef = useRef<any>(null)
  const [contentVisible, setContentVisible] = useState<boolean>(false)

  const showContent = () => setContentVisible(true)

  const animate = useCallback(
    (animation: Animation, duration?: number): Promise<void> => {
      const viewElement = viewRef.current

      if (!viewElement) return Promise.resolve()

      // All animations are exposed as functions
      // on animatable elements. They take an optional duration argument.
      // They return a promise that is resolved
      // when the animation completes successfully or is cancelled.
      return viewElement[animation](duration)
    },
    [],
  )

  useMountEffect(async () =>
    setRefValue(controllerRef, {
      animateIn: duration => {
        showContent()

        return (
          entryAnimation &&
          animate(entryAnimation, duration ?? entryAnimationDuration)
        )
      },
      animateOut: duration =>
        exitAnimation &&
        animate(exitAnimation, duration ?? exitAnimationDuration),
    }),
  )

  useAsyncEffect(async () => {
    switch (mountState) {
      case "entry":
        showContent()
        if (!entryAnimation) return
        await animate(entryAnimation, entryAnimationDuration)
        break
      case "exit":
        if (!exitAnimation) return
        await animate(exitAnimation, exitAnimationDuration)
        break
      case "hidden":
        if (!exitAnimation) return
        await animate(exitAnimation, 1)
        break
      case "visible":
      default:
        showContent()
        if (!entryAnimation) return
        await animate(entryAnimation, 1)
        break
    }
  }, [
    animate,
    entryAnimationDuration,
    exitAnimationDuration,
    entryAnimation,
    exitAnimation,
    mountState,
  ])

  return (
    <AnimatableView
      ref={viewRef}
      style={style}
      useNativeDriver={Platform.OS != "web" && !isIOSLowerEqual16()}
    >
      {contentVisible && children}
    </AnimatableView>
  )
}

export function useAnimationController() {
  return useRef<AnimationController>()
}
