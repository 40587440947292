import { atom, useRecoilValue } from "recoil"

import { DateTimePickerConfig } from "../../types"

// States

export const dateTimePickerConfigState = atom({
  key: "dateTimePickerConfig",
  default: undefined as DateTimePickerConfig | undefined,
})

export const dateTimePickerVisibility = atom({
  key: "dateTimePickerVisibility",
  default: false,
})

// Queries

export function useDateTimePickerConfig() {
  return useRecoilValue(dateTimePickerConfigState)
}
