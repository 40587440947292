import React from "react"
import { Platform } from "react-native"

import { isEmptyOrBlank } from "@axtesys/react-tools"

import { PressableOpacity } from "../button/PressableOpacity"
import { useOpenInAppBrowser } from "../hooks/useOpenInAppBrowser"
import { Label, LabelProps } from "./Label"

type LinkLabelProps = LabelProps & {
  url?: string
  onPress?: () => void
}

export function LinkLabel({ url, onPress, ...labelProps }: LinkLabelProps) {
  const openInAppBrowser = useOpenInAppBrowser()

  const onPressLink = async () => {
    onPress?.()

    if (isEmptyOrBlank(url)) return

    if (Platform.OS == "web") window.open(url, "_blank")
    else await openInAppBrowser(url!)
  }

  return (
    <PressableOpacity onPress={onPressLink}>
      <Label color="primary" {...labelProps} />
    </PressableOpacity>
  )
}
