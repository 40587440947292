import { useEffect } from "react"
import { BackHandler, Platform } from "react-native"

// Android ONLY:
// Registers a listener for hardware back button presses and
// executes a callback when the button is pressed.
// If the callback returns true,
// the press event is consumed and no default action happens.
export function useBackButtonEffect(effect: () => boolean | null | undefined) {
  useEffect(() => {
    if (Platform.OS != "android") return undefined

    const subscription = BackHandler.addEventListener(
      "hardwareBackPress",
      effect,
    )

    return () => subscription.remove()
  }, [effect])
}
