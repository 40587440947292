import { MutableRefObject, Ref } from "react"

// Utility function to convert multiple Ref<T> objects
// into a single one that can be passed to a component.
export function mergeRefs<T = any>(
  ...refs: Array<Ref<T> | null | undefined>
): Ref<T> {
  return (instance: T | null) => {
    for (const ref of refs) {
      if (!ref) continue

      if (typeof ref == "function") ref(instance)
      else {
        const refObject = ref as MutableRefObject<T | null>
        refObject.current = instance
      }
    }
  }
}

// Utility function to manipulate a Ref<T> object.
export function setRefValue<T>(
  ref: Ref<T> | null | undefined,
  value: T | null | undefined,
) {
  if (!ref) return

  if (typeof ref == "function") ref(value ?? null)

  const refObject = ref as MutableRefObject<T | null>
  refObject.current = value ?? null
}
