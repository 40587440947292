import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { ReducedArticleGroupInfo } from "../api/graphql/types"
import { MainGroupId } from "./MainGroup"

export type ArticleGroupId = string

export type ArticleGroup = {
  articleGroupId: ArticleGroupId
  mainGroupId: MainGroupId
  name: string
  taxRate: number
  orderIndex: number

  description?: string | null
}

export type ArticleGroupData = Omit<ReducedArticleGroupInfo, "mainGroup"> & {
  mainGroupId: MainGroupId
}

export const ArticleGroupEntity = createEntityFor<ArticleGroup>()
  .withSchema(
    z.object({
      articleGroupId: z.string(),
      mainGroupId: z.string(),
      name: z.string(),
      taxRate: z.number(),
      orderIndex: z.number(),
      description: z.string().optional().nullable(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
