import { useRecoilCallback } from "recoil"

import { isEmptyOrBlank } from "@axtesys/react-tools"

import VersionInfo from "~src/versionInfo.json"

import {
  useRefreshAccessToken,
  useRetrieveBearerToken,
} from "../../feature/Authentication/hooks"
import { useBackendUrl } from "../../feature/Config/queries"
import { lastServerInteractionState } from "../../feature/FraudProtection/state"
import { logInfo, logTrace } from "../../feature/Logging/lib"
import { NETWORK_ERROR_TIMEOUT, transformError } from "../../lib/Errors"

// Calls an HTTP endpoint on the backend.
// Automatically includes authentication and other metadata.
//
// Deals with token refresh and automatically logs out the user
// if we get an 'Unauthenticated' error response.
export function useHttp() {
  const baseUrl = useBackendUrl()
  const refreshAccessToken = useRefreshAccessToken()
  const retrieveBearerToken = useRetrieveBearerToken()

  return useRecoilCallback(
    ({ set }) =>
      async ({
        path,
        body,
        method,
        timeout,
        ...args
      }: {
        path: string
        method: "GET" | "POST" | "DELETE"

        log?: boolean
        timeout?: number
        body?: FormData | string
        headers?: Record<string, string | undefined | null>
      }) => {
        // Combine the base API URL
        // with the destined API location.
        const url = baseUrl + path

        // Initialise headers record with metadata.
        const headers: Record<string, string> = {
          "X-App-Version": VersionInfo.version,
        }

        // In case there is a bearerToken present,
        // add it as an Authorization header.
        const bearerToken = retrieveBearerToken()
        if (bearerToken) headers.Authorization = `Bearer ${bearerToken}`

        // Extract non-null headers.
        if (args.headers) {
          for (const [key, value] of Object.entries(args.headers)) {
            if (value == null) continue
            headers[key] = value
          }
        }

        // Construct a shared request configuration object.
        const requestConfig = { method, headers, body }

        try {
          const response =
            timeout == undefined
              ? await fetch(url, requestConfig)
              : await fetchWithTimeout(url, timeout, requestConfig)

          // Update access token if we received one (via refresh token usage).
          const newAccessToken = response.headers.get("Set-AccessToken")
          if (newAccessToken) {
            refreshAccessToken(newAccessToken)
            logInfo("Current authentication extended via refresh token usage")
          }

          if (args.log != false) {
            logTrace(`HTTP request:`, {
              url,
              body,
              method,
              headers,
              responseStatus: response.status,
            })
          }

          const serverTimestamp = response.headers.get("X-Server-Timestamp")
          if (!isEmptyOrBlank(serverTimestamp)) {
            set(lastServerInteractionState, Date.parse(serverTimestamp!))
          }

          return response
        } catch (error: any) {
          if (args.log != false) {
            logTrace(`HTTP request failed:`, {
              url,
              body,
              method,
              headers,
              error: transformError(error).error,
            })
          }

          if (error?.name == "AbortError") {
            const name = NETWORK_ERROR_TIMEOUT
            const message = `Request with a timeout of ${timeout}ms could not be completed in time.`

            try {
              error.name = name
              error.message = message
            } catch {
              throw Error(`${NETWORK_ERROR_TIMEOUT}: ${message}`)
            }
          }

          throw error
        }
      },
    [baseUrl, retrieveBearerToken, refreshAccessToken],
  )
}

async function fetchWithTimeout(
  url: RequestInfo,
  timeout: number,
  requestConfig?: RequestInit,
): Promise<Response> {
  const abortController = new AbortController()
  const timeoutId = setTimeout(() => abortController.abort(), timeout)

  const response = await fetch(url, {
    ...requestConfig,
    signal: abortController.signal,
  })

  clearTimeout(timeoutId)

  return response
}
