import { assign } from "lodash"
import {
  atom,
  AtomEffect,
  AtomOptions,
  DefaultValue,
  RecoilState,
} from "recoil"
import z from "zod"

import { createJsonDbEntry } from "./JsonDbEntry"

// Marries Recoil.js and JsonDbEntry to add persistence to a Recoil atom.

// Overload 1: Simple schema so no custom serialization / deserialization
//             must be implemented.
export function jsonDbAtom<T, TSchema extends z.ZodType<T>>(
  options: AtomOptions<T> & { schema: TSchema },
): RecoilState<T>

// Overload 2: Schema is more complex and requires
//             a custom serialization / deserialization implementation.
export function jsonDbAtom<T, TSchema extends z.ZodType>(
  options: AtomOptions<T> & {
    schema: TSchema
    serialize: (data: T) => z.infer<TSchema>
    deserialize: (json: z.infer<TSchema>) => T
  },
): RecoilState<T>

// Implementation
export function jsonDbAtom<T, TSchema extends z.ZodType>(
  options: AtomOptions<T> & {
    schema: TSchema
    serialize?: (data: T) => z.infer<TSchema>
    deserialize?: (json: z.infer<TSchema>) => T
  },
): RecoilState<T> {
  const { key, schema, serialize, deserialize } = options

  const entity = {
    schema,
    serialize: serialize ?? (data => data),
    deserialize: deserialize ?? (json => json),
  }
  const seedData = "default" in options ? options.default : undefined

  const jsonDbEntry = createJsonDbEntry({ key, entity, seedData })

  const persistenceEffect: AtomEffect<any> = ({ trigger, onSet, setSelf }) => {
    if (trigger == "get") {
      setSelf(async () => {
        const storedData = await jsonDbEntry.load()
        return storedData ?? new DefaultValue()
      })
    }

    onSet(data => {
      if (data instanceof DefaultValue) jsonDbEntry.reset()
      else jsonDbEntry.store(data)
    })
  }

  return assign(
    atom({ key, default: seedData, effects: [persistenceEffect] }),

    // Add the default state to the atom object,
    // so we can reset it later in `RecoilDebuggingOverlay`.
    { jsonDbDefaultState: seedData },
  )
}
