import React from "react"

import { useToggle } from "@axtesys/hooks"

import { IconConfig, TextInput, TextInputProps } from "./TextInput"

type PasswordInputProps = TextInputProps & { forceHidden?: boolean }

export function PasswordInput({ forceHidden, ...props }: PasswordInputProps) {
  const [passwordHidden, togglePasswordHidden] = useToggle(true)

  const icon = !forceHidden
    ? ({
        name: passwordHidden ? "eye-outline" : "eye-off-outline",
        onPress: togglePasswordHidden,
      } as IconConfig)
    : undefined

  return (
    <TextInput {...props} iconRight={icon} secureTextEntry={passwordHidden} />
  )
}
