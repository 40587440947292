import Big from "big.js"
import { reduce } from "lodash"
import { atom } from "recoil"

import { selector } from "~shared/lib/recoil/lib"
import {
  PaymentMethod,
  transformPaymentMethodToValueTransferFlags,
  ValueTransfer,
} from "~shared/types"

import { cardDeviceState } from "../CashRegister/state"
import { gpTomConnectionState } from "../GPtom/state"
import { hobexConnectionState } from "../Hobex/state"
import { paymentMethodsState } from "../OfflineData/state"
import {
  cartItemsWithAllDiscountsTotalSelector,
  currentInvoiceContactTextSelector,
} from "../ShoppingCart/state"

// Determines the currently available payment methods
export const paymentMethodsSelector = selector({
  key: "PaymentMethodsSelector",
  get: ({ get }) => {
    const cardDevice = get(cardDeviceState)

    return get(paymentMethodsState).filter(({ properties }) => {
      // The payment method must be actually usable as a payment method
      if (!properties.usableForPayment) return false

      // In case of the payment method being specified
      // as a card payment method and there is no card device at all,
      // do not show the card payment method in general
      // (payment buttons in shopping cart, payout buttons in payment screen, etc.)
      return !(properties.isCardPayment && cardDevice == "None")
    })
  },
})

// Determines the currently available payout methods
// (in this context payout means either overpay,
// a refund or the generic zero line entry on the
// receipt indicating that there is no return money/change)
export const payoutMethodsSelector = selector({
  key: "payoutMethodsSelector",
  get: ({ get }) => {
    const isPayout = get(isPayoutSelector)
    const cardDevice = get(cardDeviceState)
    const isOverpay = get(isOverpaySelector)
    const allPaymentMethods = get(paymentMethodsState)

    // Only Hobex ViA (TecsClient), Hobex ZVT (ZVTClient) or
    // a possible external device without a specific integration
    // in our app, should be allowed to execute card payouts.
    // (GP tom does not support refunds/payouts within the App2App-API)
    const isCardDeviceNotPayoutCapable = !(
      cardDevice == "Hobex" ||
      cardDevice == "ZVT" ||
      cardDevice == "External"
    )

    const payoutMethods = allPaymentMethods.filter(({ properties }) => {
      // The payment method must be actually usable as a payout method.
      //
      // Also, we do not want to allow card payout transactions,
      // when we are in overpay mode without an actual payout,
      // because we cannot simply return money that way,
      // and it would not make much sense.
      if (
        !properties.usableForPayout ||
        (properties.isCardPayment && isOverpay && !isPayout)
      )
        return false

      // In case of the payment method being specified
      // as a card payment method and there is no USABLE/CAPABLE card device at all,
      // do not show the card payment method in general
      // (payment buttons in shopping cart, payout buttons in payment screen, etc.)
      return !(properties.isCardPayment && isCardDeviceNotPayoutCapable)
    })

    // Show all beforehand determined payoutMethods,
    // when we are in payout mode (not overpay mode!) OR
    // the only payment method available
    // is the cash (Bar) payment method.
    //
    // Otherwise,
    // do not provide any payout method
    // for a possible overpay procedure,
    // as it would not make any sense
    // (other than in the context of cash transactions).
    return isPayout ||
      !(
        allPaymentMethods.length == 1 &&
        allPaymentMethods[0].methodName != "Bar"
      )
      ? payoutMethods
      : []
  },
})

// Payments used in the active invoice.
export const paymentsState = atom({
  key: "paymentsState",
  default: [] as ValueTransfer[],
})

// The total sum of all card payments
// (all payments marked as card payment methods)
export const cardPaymentsTotalSelector = selector({
  key: "cardPaymentAmount",
  get: ({ get }) => {
    let amountTotal = Big(0)
    const paymentMethods = get(paymentMethodsState)

    // Find all card payments and sum up their totals
    for (const { paymentMethodId, amount } of get(paymentsState)) {
      const paymentMethod = paymentMethods.find(
        paymentMethod => paymentMethod.paymentMethodId == paymentMethodId,
      )

      if (!paymentMethod) continue
      if (!paymentMethod.properties.isCardPayment) continue

      amountTotal = amountTotal.plus(amount)
    }

    return amountTotal
  },
})

// The amount of money we will ask the Hobex payment API for
export const hobexPaymentAmountSelector = selector({
  key: "hobexPaymentAmount",
  get: ({ get }) =>
    get(hobexConnectionState) == "not used"
      ? Big(0)
      : get(cardPaymentsTotalSelector),
})

// The amount of money we will ask the GP tom API for
export const gpTomPaymentAmountSelector = selector({
  key: "gpTomPaymentAmount",
  get: ({ get }) =>
    get(gpTomConnectionState) == "not used"
      ? Big(0)
      : get(cardPaymentsTotalSelector),
})

// Total amount paid for the active invoice.
export const moneyAmountPaidSelector = selector({
  key: "moneyAmountPaid",
  get: ({ get }) =>
    reduce(
      get(paymentsState),
      (acc, payment) => acc.plus(payment.amount),
      Big(0),
    ),
})

// Money amount that the customer needs to pay us
// (could be negative, in which case we're in payout mode)
export const moneyAmountDueSelector = selector({
  key: "moneyAmountDue",
  get: ({ get }) => {
    const paid = get(moneyAmountPaidSelector)

    return Big(
      get(cartItemsWithAllDiscountsTotalSelector)
        .minus(paid.lt(0) ? Big(0) : paid)
        .round(2)
        .toFixed(2),
    )
  },
})

// Determines the destined payout amount
export const payoutAmountSelector = selector({
  key: "payoutAmountSelector",
  get: ({ get }) => {
    const due = get(moneyAmountDueSelector)
    const hobexPayoutAmount = get(hobexPayoutAmountSelector)

    if (due.lt(0)) return due.times(-1)
    if (hobexPayoutAmount.gt(0)) return hobexPayoutAmount
    return Big(0)
  },
})

// Trys to safely access the first usable
// payout method of all available payment methods
// by using optional chaining.
// In case there is no payout method available,
// it will result in undefined.
const initialPayoutMethodSelector = selector<PaymentMethod | undefined>({
  key: "initialPayoutMethodSelector",
  get: ({ get }) => get(payoutMethodsSelector)?.[0],
})

// As the payout method can be changed
// by the user within the payment screen,
// a state initialised with the first
// usable payout method is required.
export const payoutMethodState = atom<PaymentMethod | undefined>({
  key: "payoutMethodState",
  default: initialPayoutMethodSelector,
})

// Sanitized payouts array selector
// (currently only used for receiptSelector -
// extracted for cleaner code)
export const payoutsSelector = selector<ValueTransfer[]>({
  key: "payoutsSelector",
  get: ({ get }) => {
    const payoutMethod = get(payoutMethodState)

    // In case that there is no actual payout method available,
    // make sure that we do not try to include
    // a specific payout method as
    // this would lead to a graphql validation error
    // and to the fact that the payout amount
    // is not assignable to any payment method.
    //
    // Also, do not include a payout method
    // if the payout amount is not greater than 0.
    return payoutMethod && get(payoutAmountSelector).gt(0)
      ? [
          {
            amount: get(payoutAmountSelector),
            methodName: payoutMethod.methodName,
            paymentMethodId: payoutMethod.paymentMethodId,
            flags: transformPaymentMethodToValueTransferFlags(payoutMethod),
          },
        ]
      : []
  },
})

// The amount of money we will refund
// to the customer using the Hobex payment API.
// This is always a positive (or zero) value.
export const hobexPayoutAmountSelector = selector({
  key: "hobexPayoutAmount",
  get: ({ get }) => {
    const due = get(moneyAmountDueSelector)
    const payoutMethod = get(payoutMethodState)

    if (
      // If we are not configured to use Hobex API
      get(hobexConnectionState) == "not used" ||
      // If we are not paying out anything to the customer
      due.gt(0) ||
      // If there is no payout method available at all
      !payoutMethod ||
      // If the payout method is not a card payment
      !payoutMethod.properties.isCardPayment
    ) {
      // Then do not refund anything via Hobex
      return Big(0)
    }

    // The full payout amount must be refunded via Hobex
    return due.mul(-1)
  },
})

// Determines whether the payment should
// take place as a Hobex, GP tom or general value transaction
export const cardPaymentModeSelector = selector({
  key: "cardPaymentModeSelector",
  get: ({ get }) => {
    if (get(gpTomPaymentAmountSelector).gt(0))
      return "waiting for gp tom payment"
    if (get(hobexPaymentAmountSelector).gt(0))
      return "waiting for hobex payment"
    if (get(hobexPayoutAmountSelector).gt(0)) return "waiting for hobex payout"
    return undefined
  },
})

// Payment mode (is the customer paying or are we paying?)
export const paymentModeSelector = selector({
  key: "paymentMode",
  get: ({ get }) => {
    const due = get(moneyAmountDueSelector)

    if (due.gt(0)) return "waiting for payment"
    if (due.lt(0)) return "waiting for payout"
    return "payment finished"
  },
})

export const isPayoutSelector = selector({
  key: "isPayoutModeSelector",
  get: ({ get }) => get(cartItemsWithAllDiscountsTotalSelector).lt(0),
})

const isOverpaySelector = selector({
  key: "isOverpaySelector",
  get: ({ get }) => get(moneyAmountDueSelector).lt(0),
})

// Determines whether there is currently a payout method available or not
const isPayoutMethodAvailableSelector = selector({
  key: "isPayoutMethodAvailable",
  get: ({ get }) => get(payoutMethodState) != undefined,
})

// Payment methods that do not have `overpayAllowed` enabled
// cannot sum up to more than the
// invoice price total (only in case of payouts).
// If they do, return true here.
export const overpayConstraintViolatedSelector = selector({
  key: "overpayConstraintViolatedSelector",
  get: ({ get }) => {
    // If there is no actual payout method available,
    // to which a potential overpay can be paid out to AND
    // there is an actual overpay active,
    // report an overpay violation.
    if (!get(isPayoutMethodAvailableSelector) && get(isOverpaySelector))
      return true

    const cartTotalWithAllDiscounts = get(
      cartItemsWithAllDiscountsTotalSelector,
    )

    // In case of a payout,
    // we can shortcut the calculation by simply returning false
    if (cartTotalWithAllDiscounts.lt(0)) return false

    const payments = get(paymentsState)
    const paymentMethods = get(paymentMethodsState)
    let totalRemaining = cartTotalWithAllDiscounts

    for (const valueTransfer of payments) {
      const paymentMethod = paymentMethods.find(
        paymentMethod =>
          paymentMethod.paymentMethodId == valueTransfer.paymentMethodId,
      )

      // Skip calculation when...
      if (
        // 1. ...the current method is not defined / cannot
        // be found in the general paymentMethods array OR
        !paymentMethod ||
        // 2. ...the current payment method is allowed to be used for overpay
        paymentMethod.properties.overpayAllowed
      )
        continue

      totalRemaining = totalRemaining.minus(valueTransfer.amount)
    }

    return totalRemaining.lt(0)
  },
})

// Contact related receipt information
export const receiptTextState = atom({
  key: "receiptText",
  default: currentInvoiceContactTextSelector,
})

// Determines whether we can submit the receipt/invoice or not
export const isReceiptReadyToSubmit = selector({
  key: "isReceiptReadyToSubmit",
  get: ({ get }) => {
    // The receipt/invoice is ready to submit when:

    // 1. The full price is accounted for AND
    if (get(moneyAmountDueSelector).gt(0)) return false

    // 2. We do not violate the overpay constraint
    return !get(overpayConstraintViolatedSelector)
  },
})
